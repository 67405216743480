import React from "react";
import { Box, Text } from "@chakra-ui/react";
import BGImage from "../../assets/BG-2.png";
import { motion } from "framer-motion";

const Innovation = () => {
  const MotionBox = motion(Box);
  return (
    <Box bg="#f6f6f6">
      <MotionBox
        w="100%"
        h="580px"
        bgGradient="linear(to-b, blue.800, blue.500)"
        color="white"
        textAlign="center"
        p={8}
        backgroundImage={BGImage}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        backgroundPosition="center"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        <Box mt={{ base: "150px", md: "150px", lg: "200px" }} textAlign={{ base: "center", md: "right" }}>
                 <Text
                   fontSize={{ base: "32px", md: "48px", lg: "64px" }}
                   fontWeight="400"
                   fontFamily="Akkurat-bold"
                   mb={{ base: "-20px", md: "-30px", lg: "-50px" }}
                   pr={{ base: "0", md: "15%", lg: "20%" }}
                   textAlign={{ base: "center", md: "right" }}
                 >
                   SUSTAINABILITY
                 </Text>
               </Box>
      </MotionBox>
      <Box p="50px">
        <Text
          fontSize="30px"
          fontWeight="400"
          fontFamily="Akkurat-Bold"
          lineHeight="39.9px"
          mt={8}
          mb={4}
          color="black"
        >
          ON THE AGENDA
        </Text>
        <Text fontSize="22px" fontWeight="400" textAlign="justify" mb={4}>
          Sustainability as a value is at the core of India Chamber, As we strive to facilitate sustainable business models for the transformation of Economy.
        </Text>

        <Text fontSize="22px" fontWeight="400" textAlign="justify" mb={4}>
          Our commitment towards building green economy examples our focus on promoting circular economy There by integrating sustainability at every level of economy.
        </Text>
        <Text fontSize="22px" fontWeight="400" textAlign="justify" mb={4}>
          We are helping business create value for sustainability as well as helping sustainability create value for business – reframing how business approaches sustainability and putting it at the center of how value is created.
        </Text>

        <Text fontSize="22px" fontWeight="400" textAlign="justify" mb={4}>
          We call it "Sustainable- centric business" – an integrated approach for businesses impacting environment, Society people's and animal's and the world as a whole. And that makes it everybody’s business.
        </Text>
      </Box>
    </Box>
  );
};

export default Innovation;
