import React from "react";
import { Box, Image, Text, Flex } from "@chakra-ui/react";
import BGImage from "../../assets/BG-2.png";
import Mission from "../../assets/overview-gaushala/cow.png";
import { motion } from "framer-motion";
import Cow from "../../assets/overview-gaushala/cow_vector.png";
import House from "../../assets/overview-gaushala/house.png";
import Growth from "../../assets/overview-gaushala/growth.png";
import Benefits from "../../assets/overview-gaushala/benefits.png";
import Vision from "../../assets/overview-gaushala/vision.png";
import Solution from "../../assets/overview-gaushala/solution.png";
import Team from "../../assets/overview-gaushala/team.png";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "./index.css";

const Gaushala = () => {
  const MotionBox = motion(Box);
  return (
    <Box bg="#f6f6f6">
      <MotionBox
        w="100%"
        h={{ base: "400px", md: "580px" }}
        bgGradient="linear(to-b, blue.800, blue.500)"
        color="white"
        textAlign="center"
        p={8}
        backgroundImage={BGImage}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        backgroundPosition="center"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        <Box
          mt={{ base: "150px", md: "150px", lg: "200px" }}
          textAlign={{ base: "center", md: "left" }}
          ml={{ base: "0px", md: "200px", lg: "700px" }}
          px={{ base: "20px", md: "0px" }} // Adds padding on small screens to prevent content from touching edges
        >
          <Text
            fontSize={{ base: "32px", md: "48px", lg: "64px" }}
            fontWeight="400"
            fontFamily="Akkurat-bold"
            mb={{ base: "-10px", md: "-30px", lg: "-50px" }}
          >
            Smart Sustainable
          </Text>

          <Box mt={{ base: "10px", md: "15px", lg: "20px" }}>
            <Text
              fontSize={{ base: "32px", md: "48px", lg: "64px" }}
              fontWeight="400"
              fontFamily="Akkurat-bold"
            >
              Gaushala
              <Text
                as="span"
                fontSize={{ base: "24px", md: "32px", lg: "40px" }}
                fontWeight="400"
                color="#001F5C"
                ml="10px"
              >
                (SSG)
              </Text>
            </Text>
          </Box>
        </Box>
      </MotionBox>
      <Flex
        direction="column"
        textAlign="left"
        p={{ base: "25px", md: "50px 50px" }}
        bg="white"
        width="100%"
        m={{ base: "20px 0", md: "50px 0" }}
        fontFamily={"Akkurat"}
      >
        <Text
          fontSize={{ base: "24px", md: "35px" }}
          fontWeight="bold"
          mb={4}
          fontFamily={"Akkurat-bold"}
        >
          Overview
        </Text>
        <Text
          fontSize={{ base: "16px", md: "22px" }}
          textAlign="left"
          color="#515151"
        >
          India Chamber of Business and Commerce is excited to announce our new
          initiative: the establishment of a sustainable gaushala (cow
          sanctuary). This initiative reflects our commitment to promoting
          sustainability, animal welfare, and ethical practices in agriculture.
          We believe that by creating a harmonious ecosystem where cows are
          treated with love and respect, we can contribute to a healthier planet
          and a more compassionate society.
        </Text>
      </Flex>
      <Flex
        direction={{ base: "column", md: "row" }}
        textAlign="left"
        p={{ base: "0px 50px", md: "20px 50px" }}
        width="100%"
        align="center"
        gap={{ base: 2, md: 4 }}
      >
        <Flex
          flexDir={{ base: "column", md: "row" }}
          alignItems="center"
          gap={{ base: "50px", md: "100px" }}
        >
          <Flex flex="1" justifyContent="center" alignItems="center">
            <Image
              src={Mission}
              alt="Our Mission"
              width={{ base: "80%", md: "100%" }}
            />
          </Flex>
          <Flex direction="column" flex="1" pb="120px">
            <Text
              fontWeight="600"
              fontSize={{ base: "24px", md: "40px" }}
              fontFamily="Akkurat-bold"
              mb="30px"
            >
              Our Mission
            </Text>
            <Text fontSize={{ base: "16px", md: "22px" }} color="#515151">
              Our mission for the sustainable gaushala is to create a model of
              holistic farming that prioritizes the well-being of cows, respects
              the environment, and promotes community engagement.
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        m="50px"
        direction={{ base: "column", md: "row" }}
        gap={{ base: 5, md: 20 }}
        justifyContent="center"
        alignItems="center"
      >
        {[
          { img: Cow, text: "To Rehabilitate destitute and stray cattle." },
          { img: House, text: "To Create SSG's as a nucleus of Rural Economy" },
          { img: Growth, text: "To Develop SSG's as hub of green economy" },
        ].map((item, index) => (
          <Box
            key={index}
            width={{ base: "100%", md: "380px" }}
            height="auto"
            bg="white"
            boxShadow="md"
            p={6}
            borderRadius="lg"
          >
            <Flex alignItems="center" mb={4}>
              <Image src={item.img} height="54px" width="82px" mr={2} />
              <Text
                fontFamily={"Akkurat-bold"}
                color="#343434"
                fontSize={{ base: "16px", md: "20px" }}
              >
                {item.text}
              </Text>
            </Flex>
          </Box>
        ))}
      </Flex>

      <Box bg="#f6f6f6" p="30px 0" mb={3} width="100%">
        <Flex
          justifyContent="flex-start"
          mb={8}
          width="100%"
          px={{ base: "16px", md: "32px" }}
        >
          <Text
            fontSize={{ base: "28px", md: "48px" }}
            fontWeight="600"
            textAlign="left"
          >
            Agenda
          </Text>
        </Flex>
        <Swiper
          modules={[Navigation]}
          navigation
          spaceBetween={0}
          slidesPerView={1}
          style={{ width: "100%" }}
        >
          {[Benefits, Vision, Solution, Team].map((img, index) => (
            <SwiperSlide key={index}>
              <Image
                src={img}
                alt={`Slide ${index + 1}`}
                w="100%"
                h={{ base: "300px", md: "600px" }}
                objectFit="cover"
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>
  );
};
export default Gaushala;
