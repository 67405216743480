import React from "react";
import {
  Box,
  Image,
  Button,
  Flex,
  Grid,
  Heading,
  Text,
} from "@chakra-ui/react";
import REFimg from "../../assets/home/REF_img.png";
import press from "../../assets/home/press_release.png";
import partnership from "../../assets/home/partnerships.png";
import "./section2.css";
import { useNavigate } from "react-router-dom";

const Section2 = () => {
  const navigate = useNavigate();
  return (
    <Box className="platform-section">
      <Flex className="platform-top" alignItems="stretch">
        <a
          as="a"
          href="/REF SUMMIT_REPORT-1.pdf"
          target="_blank"
          rel="noopener noreferrer">

          <Image
            src={REFimg}
            alt="Rural Economic Forum"
            className="platform-image"
            flex="1"
          />
        </a>
        <Box
          className="platform-content"
          flex="1"
          h="100%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          gap="30px"
        >
          <Heading as="h2" className="platform-heading">
            Platform for building unique Indian Global Partnership
          </Heading>
          <Text className="platform-text">
            India Chamber facilitates and establishes world-class integration
            for its global members to create economic and development impact
            delivered through both public and private sector operations,
            advisory services, and knowledge support.
          </Text>
          <Button
            className="platform-button"
            onClick={() => navigate("/overview")}
            alignSelf="flex-start"
          >
            Learn more →
          </Button>
        </Box>
      </Flex>

      <Grid className="platform-bottom">
        <Box className="platform-left">
          <Box className="platform-card">
            <Image
              src={press}
              alt="Press Release"
              cursor="pointer"
              onClick={() => navigate("/press-release")}
              className="platform-card-image"
            />
            {/* <Box className="platform-card-overlay">Press Release</Box> */}
          </Box>

          <Box className="platform-card">
            <Image
              src={partnership}
              alt="Partnerships"
              cursor="pointer"
              onClick={() => navigate("/partnerships")}
              className="platform-card-image"
            />
            {/* <Box className="platform-card-overlay">Partnerships</Box> */}
          </Box>
        </Box>

        <Box className="platform-glance">
          <Heading as="h3" className="glance-heading">
            India Chamber at a Glance
          </Heading>
          <Flex className="glance-items">
            <Text style={{ textAlign: "left" }}>
              Market
              <br /> Integration
            </Text>
            <Box className="glance-divider"></Box>
            <Text style={{ textAlign: "left" }}>
              Business
              <br /> Facilitation
            </Text>
            <Box className="glance-divider"></Box>
            <Text style={{ textAlign: "left" }}>
              Knowledge
              <br /> Creation
            </Text>
          </Flex>
        </Box>
      </Grid>
    </Box>
  );
};

export default Section2;
