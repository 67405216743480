import React, { useState } from "react";
import "./slider.css";

const MultiImageSlider = ({ sectors }) => {
    const [activeIndex, setActiveIndex] = useState(2);
    const handleImageClick = (index) => {
        setActiveIndex(index);
    };

    return (
        <div className="carousel-container">
            <div className="carousel">
                {sectors.map((sector, index) => {
                    const relativeIndex = (index - activeIndex + sectors.length) % sectors.length;
                    let positionClass = `position-${relativeIndex}`;
                    return (
                        <div
                            className={`carousel-item ${positionClass}`}
                            key={sector.id}
                            onClick={() => handleImageClick(index)}
                        >
                            <img src={sector.image} alt={sector.title} />
                            <div className="overlay"></div>
                            <div className="carousel-caption">{sector.title}</div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default MultiImageSlider;
