import React from "react";
import { Box, Text } from "@chakra-ui/react";
import BGImage from "../../assets/BG-2.png";
import { motion } from "framer-motion";

const Privacy = () => {
  const MotionBox = motion(Box);
  return (
    <Box
      backgroundColor=" #F6F6F6"
      color="#515151"
      fontFamily="Akkurat"
      lineHeight="35.86px"
    >
      <MotionBox
        w="100%"
        h="580px"
        bgGradient="linear(to-b, blue.800, blue.500)"
        color="white"
        textAlign="center"
        p={8}
        backgroundImage={BGImage}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        backgroundPosition="center"
        display="flex"
        flexDirection="column"
        alignItems={{ base: "center", md: "flex-start" }} // Left-align on desktop, center on mobile
        justifyContent="center"
        pl={{ base: "0px", md: "10%" }} // Ensures left start on desktop
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        <Box textAlign={"left"} ml={{ md: "40rem", base: "15px" }} fontFamily="Akkurat-bold">
          <Text
            fontSize={{ base: "25px", md: "60px" }}
            fontWeight="60"
            mb={{ base: "10px", md: "30px" }}
          >
            PRIVACY
          </Text>
          <Text fontSize={{ base: "25px", md: "40px" }} fontWeight="600">
            <Text
              as="span"
              fontWeight="600"
              color="#001F5C"
              fontSize={{ base: "25px", md: "40px" }}
            >
              POLICY
            </Text>
            {/* APPRECIATIONS */}
          </Text>
        </Box>
      </MotionBox>

      <Box p="50px">
        <Text
          fontSize="30px"
          fontWeight="400"
          fontFamily="Akkurat-Bold"
          lineHeight="39.9px"
          mt={8}
          mb={4}
          color="black"
        >
          INTRODUCTION
        </Text>
        <Text fontSize="22px" fontWeight="400" textAlign="justify" mb={4}>
          Our customers’ privacy is extremely important to us. You can depend on
          us to act responsibly and maintain confidentiality of the information
          that you choose to provide us with. The information that you provide
          will help us to better understand your needs and enable us to serve
          you better.
          <Text>
            This privacy policy may change from time to time. You are requested
            to check for updates to the policy periodically.
          </Text>
        </Text>
        <Text
          fontSize="30px"
          fontWeight="400"
          fontFamily="Akkurat-Bold"
          lineHeight="39.9px"
          mt={8}
          mb={4}
          color="black"
        >
          INFORMATION COLLECTED FROM YOU
        </Text>
        <Text fontSize="22px" fontWeight="400" textAlign="justify" mb={4}>
          To be able to obtain information about our products and services, you
          are required to provide us with your details. This enables us to
          provide you the relevant information, whether by sending a
          representative or otherwise, without the botheration of asking you for
          your details repeatedly. We may ask you for further information from
          time to time in order to cater to your needs. The information that you
          provide will not be shared with anybody without your prior explicit
          consent. This information will help us in providing you with even
          better service levels than you have come to expect from us.
        </Text>
        <Text fontSize="22px" fontWeight="400" textAlign="justify" mb={4}>
          We may track usage through cookies or session variables. A cookie is a
          small data file written to a user’s hard drive. A session variable is
          like a temporary cookie and it is destroyed when you close your
          browser. Cookies may be used to, among other things, allow automated
          log-in and may contain information about preferences or Internet use.
          Advertisers and affiliate partners on our site may also send cookies
          to your browser. We may also collect information about your
          preferences. We may possibly keep on record information relating to
          customer service requests as well as other correspondence, such as
          emails or letters, sent to us. Your responses to online surveys,
          questionnaires, etc. may also be kept on record for us to better
          understand your needs and requirements.
        </Text>
        <Text
          fontSize="30px"
          fontWeight="400"
          fontFamily="Akkurat-Bold"
          lineHeight="39.9px"
          mt={8}
          mb={4}
          color="black"
        >
          STORAGE AND MAINTENANCE OF INFORMATION COLLECTED
        </Text>
        <Text fontSize="22px" fontWeight="400" textAlign="justify" mb={4}>
          Although we may store or track information about visitors, we shall
          not be obligated to do so and may delete any information and records,
          in whole or in part, solely at its discretion.
        </Text>
        <Text
          fontSize="30px"
          fontWeight="400"
          fontFamily="Akkurat-Bold"
          lineHeight="39.9px"
          mt={8}
          mb={4}
          color="black"
        >
          USAGE OF THE INFORMATION COLLECTED
        </Text>
        <Text fontSize="22px" fontWeight="400" textAlign="justify" mb={4}>
          The information collected from our users / visitors is used mainly to
          enhance the end-user experience. The email and other contact
          information provided may be used to send promotional offers,
          newsletters and contact users in case of any emergency. No
          information, regarding personal details of its users / visitors, is
          rented or sold to any third parties whatsoever. However, statistical
          analysis details may be shared with our affiliates, partners or third
          party associates to gain a better understanding of user / visitor
          behavior. The information shared would be aggregate form and no
          personal information would be shared with any party whatsoever. Please
          note that we may sell, share or transfer personally identifiable
          information about its customers with any successor in interest (e.g.
          if the Company is sold to a third party). Should we be legally
          required to disclose any personal information about a user / visitor
          in case it is demanded by law enforcement authorities in connection
          with any investigation, or civil or administrative authorities in
          connection with a case of civil or administrative investigation, this
          information will be shared with the concerned authorities.
        </Text>

        <Text
          fontSize="30px"
          fontWeight="400"
          fontFamily="Akkurat-Bold"
          lineHeight="39.9px"
          mt={8}
          mb={4}
          color="black"
        >
          SITE SECURITY
        </Text>
        <Text fontSize="22px" fontWeight="400" textAlign="justify" mb={4}>
          We have implemented adequate security measures to protect against any
          misuse, theft or alteration of information stored on our web site. The
          site is verified by us as virus-free. However, the risk of hacking
          remains. In case of any computer or hack attacks on the site, we
          cannot be held liable for loss, theft or corruption of data maintained
          on the site.
        </Text>

        <Text
          fontSize="30px"
          fontWeight="400"
          fontFamily="Akkurat-Bold"
          lineHeight="39.9px"
          mt={8}
          mb={4}
          color="black"
        >
          OPTIONS FOR PROMOTIONAL INFORMATION AND COMMUNICATION
        </Text>
        <Text fontSize="22px" fontWeight="400" textAlign="justify" mb={4}>
          Users reserve the right to opt-out of receiving any promotional or
          other communication from us or any of its affiliates. Should you
          choose to opt out, please do so by visiting the site, signing in and
          then unchecking the receive option.
        </Text>
        <Text
          fontSize="30px"
          fontWeight="400"
          fontFamily="Akkurat-Bold"
          lineHeight="39.9px"
          mt={8}
          mb={4}
          color="black"
        >
          GENERAL LIMITATION OF LIABILITY
        </Text>
        <Text fontSize="22px" fontWeight="400" textAlign="justify" mb={4}>
          Please be advised that we try our best to collect data from authentic
          sources. However, we cannot be held liable for any loss, monetary or
          otherwise, resulting from the usage and display of this data.
        </Text>
      </Box>
    </Box>
  );
};

export default Privacy;
