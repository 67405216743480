import React, { useRef, useState } from "react";
import { Box, Button, Flex, Heading, Text, Image, Modal, ModalOverlay, ModalContent, ModalCloseButton } from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

import generalMeets from "../../assets/home/General-Meets.png";
import boardMeets from "../../assets/home/board-meets.png";
import annualMeets from "../../assets/home/annual-meets.png";


import StackHolder1 from "../../assets/eventImages/stackholder/img1.jpg"
import StackHolder2 from "../../assets/eventImages/stackholder/img2.jpg"
import StackHolder3 from "../../assets/eventImages/stackholder/img3.jpg"
import StackHolder4 from "../../assets/eventImages/stackholder/img4.jpg"
import StackHolder5 from "../../assets/eventImages/stackholder/img5.jpg"
import StackHolder6 from "../../assets/eventImages/stackholder/img6.jpg"


import Rural from "../../assets/eventImages/REF/img.jpg"
import Rural1 from "../../assets/eventImages/REF/img1.jpg"
import Rural2 from "../../assets/eventImages/REF/img2.jpg"
import Rural3 from "../../assets/eventImages/REF/img3.jpg"
import Rural4 from "../../assets/eventImages/REF/img4.jpg"
import Rural5 from "../../assets/eventImages/REF/img5.jpg"
import Rural6 from "../../assets/eventImages/REF/img6.jpg"
import Rural7 from "../../assets/eventImages/REF/img7.jpg"
import Rural8 from "../../assets/eventImages/REF/img8.jpg"
import Rural9 from "../../assets/eventImages/REF/img9.jpg"
import Rural10 from "../../assets/eventImages/REF/img10.jpg"
import Rural11 from "../../assets/eventImages/REF/img11.jpg"
import Rural12 from "../../assets/eventImages/REF/img12.jpg"
import Rural13 from "../../assets/eventImages/REF/img13.jpg"
import Rural14 from "../../assets/eventImages/REF/img14.jpg"

import CEO1 from "../../assets/eventImages/ceo-roundtable/img1.jpg"
import CEO2 from "../../assets/eventImages/ceo-roundtable/img2.jpg"
import CEO3 from "../../assets/eventImages/ceo-roundtable/img3.jpg"
import CEO4 from "../../assets/eventImages/ceo-roundtable/img4.jpg"
import CEO5 from "../../assets/eventImages/ceo-roundtable/img5.jpg"

import moaICMR1 from "../../assets/eventImages/moa-icmr/img.jpg"

import ihdSummit1 from "../../assets/eventImages/IHD/img.jpg"

import moaIMA1 from "../../assets/eventImages/mou-IMA/img.jpg"

import Dialogue1 from "../../assets/eventImages/manufactruing-dialogue/img1.jpg"
import Dialogue2 from "../../assets/eventImages/manufactruing-dialogue/img2.jpg"

import roundtable1 from "../../assets/eventImages/Gov-MH/img.jpg"

import foodProcessing1 from "../../assets/eventImages/food-processing/img.jpg"

const OurEvents = () => {



  const swiperRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(1);

  const openModal = (event) => {
    setSelectedEvent(event);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelectedEvent(null);
    setCurrentSlide(1)
  };

  const Data = [
    {
      id: 1,
      title: "Stakeholder Meeting on Smart and Sustainable Gaushalas",
      images: [StackHolder5, StackHolder2, StackHolder3, StackHolder4, StackHolder1, StackHolder6],
    },
    {
      id: 2,
      title: "Rural Economic Forum",
      images: [Rural, Rural1, Rural2, Rural3, Rural4, Rural5, Rural6, Rural7, Rural8, Rural9, Rural10, Rural11, Rural12, Rural13, Rural14],
    },
    {
      id: 3,
      title: "CEO Roundtable",
      images: [CEO4, CEO2, CEO3, CEO1, CEO5],
    },
    {
      id: 4,
      title: "MOA Signing between India Chamber and ICMR",
      images: [moaICMR1],
    },
    {
      id: 5,
      title: "IHD Summit",
      images: [ihdSummit1],
    },
    {
      id: 6,
      title: "MOU with IMA",
      images: [moaIMA1],
    },
    {
      id: 7,
      title: "Launch of India Manufacturing Dialogue",
      images: [Dialogue1, Dialogue2],
    },
    {
      id: 8,
      title: "Roundtable with Maharashtra Government",
      images: [roundtable1],
    },
    {
      id: 9,
      title: "Dialogue with Sh. Paras Paswan UM for Food Processing, GOI",
      images: [foodProcessing1]
    },
  ];

  return (
    <Box className="S5-container">
      <Flex className="S5-eventsSection">
        <Box className="S5-eventsText">
          <Heading as="h2" className="S5-heading">
            Gallery
          </Heading>
          <Text className="S5-subtext">
            These are some of our events which were organized by Our India
            Chamber.
          </Text>
          <Flex align="center" className="S5-eventArrows">
            <Button
              className="S5-eventButton-pre"
              width="40px"
              height="45px"
              onClick={() => swiperRef.current?.slidePrev()}
            >
              ←
            </Button>
            <Button
              className="S5-eventButton-nxt"
              width="40px"
              height="45px"
              onClick={() => swiperRef.current?.slideNext()}
            >
              →
            </Button>
          </Flex>
        </Box>

        <Swiper
          spaceBetween={20}
          slidesPerView={1}
          breakpoints={{
            640: { slidesPerView: 1 },
            768: { slidesPerView: 2 },
            1024: { slidesPerView: 3 },
          }}
          onSwiper={(swiper) => (swiperRef.current = swiper)}
          navigation={false} // Navigation handled manually
          modules={[Navigation]}
          className="S5-eventsGrid"
          style={{ width: "100%" }}
          loop={true}
        // onSlideChange={(swiper) => setCurrentSlide(swiper.realIndex + 1)} // Track slide change
        >
          {Data.map((event) => (
            <SwiperSlide
              key={event.id}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Box
                className="S5-eventCard"
                width="100%"
                maxWidth={{ base: "100%", md: "300px" }}
                onClick={() => openModal(event)}
              >
                <Image
                  src={event?.images[0]}
                  alt={event?.title}
                  width="100%"
                  objectFit="cover"
                  className="S5-eventImage"
                />
                <Box className="S5-eventText">{event?.title}</Box>
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      </Flex>

      {selectedEvent && selectedEvent.images?.length > 0 && (
        <Modal isOpen={isOpen} onClose={closeModal} maxWidth="90vh" isCentered>
          <ModalOverlay />
          <ModalContent
            bg="blackAlpha.900"
            borderRadius="md"
            boxShadow="lg"
            height={{ base: "100vh", md: "90vh", lg: "620px" }}
            maxWidth="90%"
            maxHeight="720px"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            padding="20px"
          >
            <ModalCloseButton color="white" size="lg" />
            <Text
              fontSize={{ base: "lg", md: "xl" }}
              fontWeight="bold"
              color="white"
              textAlign="center"
              mb={4}
              px={6}
              mt={2}
              fontFamily={"Akkurat-bold"}
            >
              {selectedEvent.title}
            </Text>
            <Swiper
              spaceBetween={10}
              centeredSlides={true}
              slidesPerView={1.2}
              navigation
              modules={[Navigation]}
              className="S5-modalSlider"
              style={{
                width: "100%",
                maxWidth: "100%",
                maxHeight: "80%",
                textAlign: "center",
              }}
              onSlideChange={(swiper) => setCurrentSlide(swiper.realIndex + 1)}
            >

              {selectedEvent.images.map((img, index) => (
                <SwiperSlide
                  key={index}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Image
                    src={img}
                    alt={`${selectedEvent.title} - Image ${index + 1}`}
                    objectFit="contain"
                    width="80%"
                    maxWidth="100%"
                    maxHeight="600px"
                    borderRadius="md"
                    className="S5-eventModalImage"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <Text color="white" mt={2}>
              {currentSlide} / {selectedEvent.images.length}
            </Text>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
};

export default OurEvents;











