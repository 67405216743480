import React, { useRef } from "react";
import { Box, Button, Flex, Heading, Text, Image } from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "./refEvents.css";

const OurEvents = ({ data }) => {
  const swiperRef = useRef(null);

  return (
    <Box className="S5-container">
      <Flex className="S5-eventsSection">
        <Box className="S5-eventsText" bgColor="white">
          <Heading as="h2" className="S5-heading" color="black">
            Things to Know About REF 2025
          </Heading>
          <Text className="S5-subtext" color="black">
            These are some of our events which were organized by Our India Chamber.
          </Text>
          <Flex align="center" className="S5-eventArrows">
            <Button
              className="S5-eventButton-pre"
              color="white !important"
              bgColor="black !important"
              onClick={() => swiperRef.current?.slidePrev()}
              _hover={{ color: "black", bgColor: "white" }}
            >
              ←
            </Button>
            <Button
              className="S5-eventButton-nxt"
              color="black !important"
              bgColor="white !important"
              onClick={() => swiperRef.current?.slideNext()}
              _hover={{ color: "white", bgColor: "black" }}
            >
              →
            </Button>
          </Flex>
        </Box>

        {/* Swiper Slider Implementation */}
        <Swiper
          spaceBetween={20} // Adjust spacing between slides
          slidesPerView={3.5} // Show 3 full slides and half of the fourth
          breakpoints={{
            640: { slidesPerView: 1.5, spaceBetween: 10 }, // 1 full + half on small screens
            768: { slidesPerView: 2.5, spaceBetween: 15 }, // 2 full + half on tablets
            1024: { slidesPerView: 3.5, spaceBetween: 20 }, // 3 full + half on desktops
          }}
          onSwiper={(swiper) => (swiperRef.current = swiper)}
          navigation={false} // Manual navigation with buttons
          modules={[Navigation]}
          className="S5-eventsGrid"
          style={{ width: "100%" }} // Ensure full width
        >
          {data?.map((event) => (
            <SwiperSlide key={event.id} style={{ display: "flex", justifyContent: "center" }}>
              <Box className="S5-eventCard" style={{ width: "100%", maxWidth: "300px" }}>
                <Image
                  src={event.image}
                  alt={event.title}
                  width="100%"
                  height="300px"
                  objectFit="cover" // Ensures images are consistent
                  className="S5-eventImage"
                />
                <Box className="S5-eventText">{event.title}</Box>
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      </Flex>
    </Box>
  );
};

export default OurEvents;
