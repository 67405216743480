import React from "react";
import {
  Box,
  Button,
  Heading,
  Text,
  Image,
  Flex,
  Grid,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import BGImage from "../../assets/home/BG.png";

import video from "../../assets/media/videos/video_1.mp4";
import REFimg2 from "../../assets/media/img2.png";
import REFimg3 from "../../assets/media/img3.png";

import { ArrowForwardIcon } from "@chakra-ui/icons";
import Section2 from "./section2";
import Section3 from "./section3";
import Section4 from "./section4";
import Section5 from "./section5";
import "./Home.css";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
  const MotionBox = motion(Box);
  const MotionImage = motion(Image);

  return (
    <>
      <Box>
        <MotionBox
          w="100%"
          h="auto"
          bgGradient="linear(to-b, blue.800, blue.500)"
          color="white"
          textAlign="left"
          p={8}
          pt={{ base: "150px", md: "200px" }}
          backgroundImage={BGImage}
          loading="lazy"
          backgroundSize="cover"
          backgroundPosition="center"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <Flex direction="column" align="flex-start" maxW="1200px" mx="auto">
            <Heading className="REF-heading-1">REF 2025</Heading>
            <Text className="REF-heading-2">
              Rural Economic Forum is a Global flagship
              <br />
              initiative for building global pathways for rural economy.
            </Text>
            <Button
              size="lg"
              colorScheme="whiteAlpha"
              borderRadius="full"
              px={8}
              rightIcon={<ArrowForwardIcon />}
              onClick={() => navigate("/rural-economic-forum-2025")}>
              Explore the Full Program
            </Button>
          </Flex>

          <Grid
            templateColumns={{ base: "1fr", md: "50% 25% 25%" }}
            gap={6}
            maxW="1250px"
            mx="auto"
            mt={8}>
            <MotionBox
              initial={{ opacity: 0, x: 200 }}
              whileInView={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 200 }}
              transition={{ duration: 0.6 }}
              viewport={{ once: true }}
            >
              <video
                src={video}
                controls
                muted
                autoPlay
                style={{
                  border: "none",
                  display: "block",
                  borderRadius: "12px",
                  objectFit: "cover",
                  width: "100%",
                  height: "100%",
                }}>
                Your browser does not support the video tag.
              </video>
            </MotionBox>

            <MotionImage
              className="REF-img"
              src={REFimg2}
              alt="Image 2"
              w="100%"
              initial={{ opacity: 0, x: 200 }}
              whileInView={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 200 }}
              transition={{ duration: 0.6 }}
              viewport={{ once: true }}
              whileHover={{ scale: 1.1 }}
            />
            <MotionImage
              className="REF-img"
              src={REFimg3}
              alt="Image 3"
              w="100%"
              initial={{ opacity: 0, x: 200 }}
              whileInView={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 200 }}
              transition={{ duration: 0.6 }}
              viewport={{ once: true }}
              whileHover={{ scale: 1.1 }}
            />
          </Grid>
        </MotionBox>

        <Section2 />

        <Section3 />

        <Section4 />

        <Section5 />
      </Box>
    </>
  );
};

export default Home;
