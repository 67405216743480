import React from 'react';
import { Box, Image, Text, Flex } from '@chakra-ui/react';
import Chakra from "../../assets/collab/chakra.png";
import Ox from "../../assets/collab/Ox.png";
import BGImage from '../../assets/BG-2.png';
import { motion } from 'framer-motion';

const Collab = () => {

  const MotionBox = motion(Box);
  return (
    <Box>
      <MotionBox
        w="100%"
        h="580px"
        bgGradient="linear(to-b, blue.800, blue.500)"
        color="white"
        textAlign="center"
        p={8}
        backgroundImage={BGImage}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        backgroundPosition="center"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        <Box mt={{ base: "200px", md: "200px" }}>
          <Text
            fontSize={{ base: "28px", md: "64px" }}
            fontWeight="400"
            fontFamily="Akkurat-bold"
            mb={{ base: "10px", md: "-50px" }}
            textAlign={{ base: "center", md: "right" }}
            paddingRight={{ base: "0", md: "20%" }}
          >
            COLLABORATIONS
          </Text>
          <Box mt={{ base: "10px", md: "20px" }}>
            <Text
              fontSize={{ base: "28px", md: "64px" }}
              fontWeight="400"
              fontFamily="Akkurat-bold"
              textAlign={{ base: "center", md: "right" }}
              paddingRight={{ base: "0", md: "17%" }}
            >
              <Text
                as="span"
                fontSize={{ base: "20px", md: "40px" }}
                fontWeight="400"
                color="#001F5C"
                mr="10px"
                ml={{ base: "0", md: "120px" }}
                display="inline-block"
              >
                AND
              </Text>
              APPRECIATIONS
            </Text>
          </Box>
        </Box>
      </MotionBox>
      <Box bg="gray.50" p={8} pt="30px" minH="80vh" display="flex" alignItems="center" justifyContent="center">
        <Flex
          direction={{ base: "column", md: "row" }}
          gap={{ base: 8, md: 12 }}
          maxW="1200px"
          w="full"
          align="center"
          justify="center"
        >
          <Flex direction="column" align="center" w={{ base: "100%", md: "50%" }} gap="10px"
              as="a"
              href="/Adobe-Scan-30-May-2024-2.pdf"
              target="_blank"
              rel="noopener noreferrer">
            <Box
              bg="white"
              boxShadow="lg"
              borderRadius="lg"
              display="flex"
              alignItems="center"
              justifyContent="center"
              h="283px"
              w="100%"
            >
              <Image src={Chakra} alt="Chakra" width="193px" height="228px" objectFit="contain" />
            </Box>

            {/* First Text Box */}
            <Box bg="blue.100" w="100%" p={4} textAlign="center" borderRadius="lg">
              <Text fontSize="md" fontWeight="medium" color="gray.700" lineHeight="1.4">
                Letter of Appreciation from Ministry of MSME
              </Text>
            </Box>
          </Flex>

          {/* Second Column */}
          <Flex direction="column" align="center" w={{ base: "100%", md: "50%" }} gap="10px"
              as="a"
              href="/Adobe-Scan-30-May-2024-3.pdf"
              target="_blank"
              rel="noopener noreferrer">
            <Box
              bg="white"
              boxShadow="lg"
              borderRadius="lg"
              display="flex"
              alignItems="center"
              justifyContent="center"
              h="283px"
              w="100%"
            >
              <Image src={Ox} alt="Ox" width="193px" height="228px" objectFit="contain" />
            </Box>

            {/* Second Text Box */}
            <Box bg="blue.100" w="100%" p={4} textAlign="center" borderRadius="lg">
              <Text fontSize="md" fontWeight="medium" color="gray.700" lineHeight="1.4">
                Partnership with Ministry of MSME GOI, for Project One and SSG's
              </Text>
            </Box>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

export default Collab;
