import Navbar from '../src/components/Navbar/Navbar';
import { ChakraProvider } from "@chakra-ui/react";
import { Routes, Route } from 'react-router-dom';
import './App.css';
import Footer from './components/Footer/Footer';
import Home from './pages/Home/Home';
import Collab from './pages/Collab/index';
import Terms from './pages/Terms/Terms';
import Privacy from './pages/Policy/Policy';
import ContactPage from './pages/Contact/Contact';
import BrandIndia from './pages/BrandIndia';
import Innovation from './pages/Innvoation';
import Entrepreneurship from './pages/Enterpreneurship';
import Trade from './pages/Trade';
import Investment from './pages/Investment';
import Sustain from './pages/Sustain';
import Gaushala from './pages/Gaushala';
import ScrollToTop from './pages/scroller';
import Overview from './pages/Overview';
import PressRelease from './pages/Press';
import Partnership from './pages/Partnership';
import REF from './pages/REF/REF';
import REF2 from './pages/REF2/REF2';
import Governance from './pages/governance';
import Join from './pages/Join';
import Health from './pages/indiaHealth/health';
import ProjectOneFuture from './pages/POF/POF';
import InvestorHub from "../src/pages/InvestorHub"

function App() {
  return (
    <div className="app-container">
      <ChakraProvider>
        <Navbar />
        <div className="content">
        <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/collaberation-and-appreciation" element={<Collab />}/>
            <Route path="/terms-and-conditions" element={<Terms />}/>
            <Route path="/privacy-policy" element={<Privacy />}/>
            <Route path="/contact-us" element={<ContactPage />}/>
            <Route path="/building-brand-india" element={<BrandIndia />}/>
            <Route path="/innovation" element={<Innovation />}/>
            <Route path="/entrepreneurship" element={<Entrepreneurship />}/>
            <Route path="/global-trade-and-commerce" element={<Trade />}/>
            <Route path="/investment" element={<Investment />}/>
            <Route path="/sustainability" element={<Sustain />}/>
            <Route path="/smart-sustainable-gaushala" element={<Gaushala />}/>
            <Route path="/governace-and-leadership" element={<Governance />}/>
            <Route path="/press-release" element={<PressRelease />}/>
            <Route path="/partnerships" element={<Partnership />}/>
            <Route path="/overview" element={<Overview />}/>
            <Route path="/join" element={<Join />}/>
            <Route path="/rural-economic-forum" element={<REF />} />
            <Route path="/rural-economic-forum-2025" element={<REF2 />} />
            <Route path="/health" element={<Health />}/>
            <Route path="/project-one-future" element={<ProjectOneFuture />}/>
            <Route path="/india-investor-hub" element={<InvestorHub />}/>
          </Routes>
        </div>
        <Footer />
      </ChakraProvider>
    </div>
  );
}

export default App;
