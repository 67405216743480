import React from "react";
import { Box, Text } from "@chakra-ui/react";
import BGImage from "../../assets/BG-2.png";
import { motion } from "framer-motion";

const Innovation = () => {
  const MotionBox = motion(Box);
  return (
    <Box bg="#f6f6f6">
      <MotionBox
        w="100%"
        h="580px"
        bgGradient="linear(to-b, blue.800, blue.500)"
        color="white"
        textAlign="center"
        p={8}
        backgroundImage={BGImage}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        backgroundPosition="center"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        <Box
          mt={{ base: "200px", md: "200px", lg: "200px" }}
          textAlign={{ base: "center", md: "left" }}
          ml={{ base: "0px", md: "400px", lg: "700px" }}
        >
          <Text
            fontSize={{ base: "32px", md: "48px", lg: "64px" }}
            fontWeight="400"
            fontFamily="Akkurat-bold"
            mb={{ base: "-20px", md: "-30px", lg: "-50px" }}
          >
            INNOVATION
          </Text>
          <Box mt={{ base: "10px", md: "15px", lg: "20px" }}></Box>
        </Box>
      </MotionBox>
      <Box p="50px">
        <Text
          fontSize="30px"
          fontWeight="400"
          fontFamily="Akkurat-Bold"
          lineHeight="39.9px"
          mt={8}
          mb={4}
          color="black"
        >
          ON THE AGENDA
        </Text>
        <Text fontSize="22px" fontWeight="400" textAlign="justify" mb={4}>
          To build a robust innovation eco-system for nurturing innovation and
          facilitate Start-ups in the country that will drive sustainable
          economic growth and generate large-scale employment opportunities.
          empower Start-ups, Corporates, MSME’s, Communities and Governments.
        </Text>

        <Text fontSize="22px" fontWeight="400" textAlign="justify" mb={4}>
          To build an Innovation partnership platform by engaging with Indian
          Research Institutions, Accelerators, Incubation centers, Universities,
          and funds and supporting new models of social innovation that
          transforms and build India-led global Indian innovation ecosystem.
        </Text>
        <Text fontSize="22px" fontWeight="400" textAlign="justify" mb={4}>
          To create a global platform that serves as a launchpad for
          Innovations, Technology, Start-ups, and entrepreneurs
        </Text>
        <Text fontSize="22px" fontWeight="400" textAlign="justify" mb={4}>
          To provide a platform that inspires public-private cooperation and
          partnership on issues related to the technological and innovation
          revolution
        </Text>
        <Text fontSize="22px" fontWeight="400" textAlign="justify" mb={4}>
          To create a framework for thinking about the new disruptions that
          outline the core issues and highlight possible responses.
        </Text>
        <Text fontSize="22px" fontWeight="400" textAlign="justify" mb={4}>
          To support innovation through identifying new ideas at the local level
          and by handholding and facilitating seed funds and Venture Capitalist
          financing.
        </Text>
        <Text fontSize="22px" fontWeight="400" textAlign="justify" mb={4}>
          Develop policy measures based on a broader notion of innovation – far
          beyond pure research and development – that reflects the strong link
          with entrepreneurship
        </Text>
        <Text fontSize="22px" fontWeight="400" textAlign="justify" mb={4}>
          To develop thought leadership in the domain of innovation .
        </Text>
      </Box>
    </Box>
  );
};

export default Innovation;
