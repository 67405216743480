import React from "react";
import {
  Box,
  Text,
  Image,
  Flex,
  useBreakpointValue,
  UnorderedList,
  ListItem,
  Stack,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import BGImage from "../../assets/POF/POF_BG.png";
import { useNavigate } from "react-router-dom";
import POFlogo from "../../assets/POF/POF_logo.png";
import Mission from "../../assets/POF/mission.png";
import Cow from "../../assets/POF/cow.png";
import House from "../../assets/POF/house.png";
import Chain from "../../assets/POF/chain.png";
import Circle from "../../assets/POF/circle.png";
import Globe from "../../assets/POF/globe.png";
import Gaushala from "../../assets/POF/gaushala.png";
import Farm from "../../assets/POF/farm.png";
import Leaves from "../../assets/POF/leaves.png";
import Hand from "../../assets/POF/hand.png";
import Growth from "../../assets/POF/growth.png";
import "./POF.css";

const ProjectOneFuture = () => {
  const navigate = useNavigate();
  const MotionBox = motion(Box);
  const boxWidth = useBreakpointValue({ base: "100%", sm: "48%", md: "23%" });

  return (
    <Box bg="#f6f6f6">
      <MotionBox
        w="100%"
        h="580px"
        bgGradient="linear(to-b, blue.800, blue.500)"
        color="white"
        textAlign="center"
        pt={8}
        backgroundImage={BGImage}
        backgroundColor="#024B62"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        backgroundPosition="center"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      />
      {/* section 1  */}
      <Box className="POF-s1-container">
        <Box className="POF-logo-section">
          <Image src={POFlogo} className="POF-logo-image" />
          <Box className="POF-title-section">
            <Text className="POF-title2-text">PROJECT</Text>
            <Text className="POF-subtitle-text">ONE FUTURE</Text>
          </Box>
        </Box>

        <Flex
          direction="column"
          textAlign="left"
          p={{ base: "25px", md: "50px 50px" }}
          bg="white"
          width="100%"
          m={{ base: "20px 0", md: "100px 0 20px 0px" }}
          fontFamily={"Akkurat"}>
          <Text
            fontSize={{ base: "24px", md: "35px" }}
            fontWeight="bold"
            mb={4}
            fontFamily={"Akkurat-bold"}
          >
            Vision
          </Text>
          <Text
            fontSize={{ base: "16px", md: "22px" }}
            textAlign="left"
            color="#515151"
          >
            To revolutionize rural economies while building Sustainable and
            innovative business models in rural economy and there by addressing
            critical environmental, social, and economic challenges, paving the
            way for a resilient and inclusive future.
          </Text>
        </Flex>

        <Flex
          direction={{ base: "column", md: "row" }}
          textAlign="left"
          p={{ base: "0px 50px", md: "20px 50px" }}
          width="100%"
          align="center"
          gap={{ base: 2, md: 4 }}
          alignItems={"center"}
        >
          <Flex
            flexDir={{ base: "column", md: "row" }}
            alignItems="center"
            gap={{ base: "30px", md: "80px" }}
          >
            <Flex
              justifyContent="flex-end"
              alignItems="center"
              flexDir={"column"}
              gap="30px"
            >
              <Image
                src={Mission}
                alt="Our Mission"
                width={"434px"}
                height={"100%"}
              />
              <Text fontSize="20px" fontFamily="Akkurat-bold">
                GREEN BUSINESS ECOSYSTEM FOR SUSTAINABLE TOMORROW
                <Text
                  fontSize="20px"
                  fontFamily="Akkurat-bold"
                  textAlign={"center"}
                >
                  SUSTAINABLE TOMORROW
                </Text>
              </Text>
            </Flex>
            <Flex
              direction="column"
              flex="1.5"
              alignContent={"center"}
              justifyContent={"center"}>
              <Text
                fontWeight="600"
                fontSize={{ base: "24px", md: "40px" }}
                fontFamily="Akkurat-bold"
                mb="30px">
                Mission
              </Text>
              <Text fontSize={{ base: "16px", md: "22px" }} color="#515151">
                To create a sustainable global framework that integrates
                environmental protection, animal welfare, and human well-being
                by leveraging the potential of Smart and Sustainable Gaushalas
                (SSG) as the cornerstone of rural transformation.
              </Text>
              <Text fontSize={{ base: "16px", md: "22px" }} color="#515151">
                This initiative aims to:
              </Text>
              <br />
              <Text
                fontSize={{ base: "16px", md: "22px" }}
                color="#515151"
                mb="10px"
              >
                1. Provide holistic care and rehabilitation for destitute cows,
                creating a circular economy around their welfare.
              </Text>
              <Text
                fontSize={{ base: "16px", md: "22px" }}
                color="#515151"
                mb="10px"
              >
                2. Promote soil conservation and green energy production while
                addressing climate change.
              </Text>
              <Text
                fontSize={{ base: "16px", md: "22px" }}
                color="#515151"
                mb="10px"
              >
                3. Build a global supply chain for nutritional security while
                generating mass employment opportunities and empowering rural
                communities.
              </Text>
            </Flex>
          </Flex>
        </Flex>

        <Flex
          direction="column"
          textAlign="left"
          p={{ base: "25px", md: "50px 50px" }}
          bg="white"
          width="100%"
          m={{ base: "20px 0", md: "100px 0 20px 0px" }}
          fontFamily={"Akkurat"}
        >
          <Text
            fontSize={{ base: "24px", md: "35px" }}
            fontWeight="bold"
            mb={4}
            fontFamily={"Akkurat-bold"}
          >
            Conclusion
          </Text>
          <Text
            fontSize={{ base: "16px", md: "22px" }}
            textAlign="left"
            color="#515151"
          >
            Project ONE FUTURE is a transformative initiative that combines the
            power of sustainability, innovation, and inclusivity to address some
            of the world’s most pressing challenges. By focusing on
            environmental protection, animal welfare, and human well-being, this
            project not only drives rural economic transformation but also
            builds a global framework for nutritional security. With Smart and
            Sustainable Gaushalas (SSG) at its core, Project ONE FUTURE
            envisions a world where rural economies thrive, ecosystems flourish,
            and every individual has access to healthy, sustainable food.
          </Text>
        </Flex>

        <Box padding={{ base: "20px", md: "50px" }}>
          <Text fontSize={{ base: "24px", md: "30px" }} fontWeight="bold" pb="20px">
            Key Features of Project ONE FUTURE
          </Text>
          <Flex
            overflowX={{ base: "visible", md: "auto" }}
            flexDirection={{ base: "column", md: "row" }}
            alignItems={{ base: "center", md: "start" }}
            gap={{ base: "20px", md: "30px" }}
            pb="20px"
            sx={{
              "&::-webkit-scrollbar": {
                height: "6px",
              },
              "&::-webkit-scrollbar-track": {
                background: "#f1f1f1",
                borderRadius: "10px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#888",
                borderRadius: "10px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#555",
              },
            }}>
            {[
              {
                title: "Smart And Sustainable Gaushalas (SSG)",
                image: Cow,
                points: [
                  "Designed as hubs of innovation, these advanced cow sanctuaries integrate animal care with sustainable agricultural practices.",
                  "Promote waste-to-value practices, including the production of biogas, compost, and organic fertilizers, to boost rural incomes and sustainability.",
                ],
              },
              {
                title: "Organic Clusters for Rural Economies",
                image: House,
                points: [
                  "Building organic farming clusters around SSGs to rejuvenate soil health and enhance agricultural productivity.",
                  "Supporting the transition from chemical-intensive farming to organic and regenerative practices, ensuring long-term environmental sustainability.",
                ],
              },
              {
                title: "Global Supply Chains for Nutritional Security",
                image: Chain,
                points: [
                  "Establishing a decentralized, low-carbon food supply chain that promotes equitable access to sustainable and nutrient-rich food products.",
                  "Leveraging partnerships with industry, academia, and governments to scale impact globally.",
                ],
              },
              {
                title: "Circular Economy Approach",
                image: Circle,
                points: [
                  "Transforming agricultural and livestock waste into eco-friendly products that cater to domestic and global markets.",
                  "Encouraging local entrepreneurship and innovation to maximize resource efficiency and minimize waste.",
                ],
              },
            ].map((item, index) => (
              <Box
                key={index}
                width={{ base: "100%", md: "403px" }}
                height="auto"
                bg="white"
                p={{ base: 4, md: 8 }}
                borderRadius="xl"
                boxShadow="lg"
                flex="0 0 auto"
              >
                <Flex gap="20px" align="center" mb="15px">
                  <Image src={item.image} boxSize={{ base: "10", md: "12" }} mb={4} />
                  <Text fontSize={{ base: "18px", md: "22px" }} fontWeight="bold">
                    {item.title}
                  </Text>
                </Flex>
                <UnorderedList spacing={2}>
                  {item.points.map((point, i) => (
                    <ListItem
                      key={i}
                      fontSize={{ base: "14px", md: "18px" }}
                      color="#515151"
                      textAlign="justify"
                    >
                      {point}
                    </ListItem>
                  ))}
                </UnorderedList>
              </Box>
            ))}
          </Flex>
        </Box>

        {/* Box 1 */}
        <Box w="100%" mb="50px">
          <Flex direction={{ base: "column", md: "row" }} align="center">
            <Box width={{ base: "100%", md: "43%" }} ml={0} textAlign="center">
              <Image
                src={Globe}
                alt="Environmental Protection"
                width={{ base: "100%", md: "640px" }}
                height={{ base: "auto", md: "738px" }}
                maxWidth="100%"
              />
            </Box>
            <Box
              flex="1"
              bg="white"
              width={{ base: "100%", md: "50%" }}
              height={{ base: "auto", md: "738px" }}
              p={{ base: 4, md: 5 }}
              fontFamily="Akkurat"
              display="flex"
              flexDirection="column"
              justifyContent="space-between">
              <Text
                fontSize={{ base: "20px", md: "25px" }}
                fontFamily="Akkurat-bold"
                fontWeight="bold"
                mb={{ base: 2, md: 3 }}
                textAlign={{ base: "center", md: "left" }}>
                Environmental Protection and Ecosystem Promotion
              </Text>
              <Text fontSize={{ base: "18px", md: "22px" }} color="#515151" textAlign="justify">
                Project ONE FUTURE is a pioneering green initiative that emphasizes the following:
              </Text>
              {[
                {
                  title: "Soil Conservation:",
                  description:
                    "Adoption of regenerative agriculture practices to restore soil health, enhance fertility, and increase carbon sequestration.",
                },
                {
                  title: "Green Energy Production:",
                  description:
                    "Utilizing cow by-products and agricultural waste to generate renewable energy through biogas and other sustainable technologies.",
                },
                {
                  title: "Waste-to-Energy Conversion:",
                  description:
                    "Transforming waste into value-added products, such as organic fertilizers and biofuels, thereby promoting a rural circular economy.",
                },
                {
                  title: "Climate Change Mitigation:",
                  description:
                    "Reducing greenhouse gas emissions by encouraging eco-friendly farming and production practices, supporting global climate action goals.",
                },
              ].map((item, index) => (
                <Box key={index} mt={{ base: 3, md: 3 }}>
                  <Text fontFamily="Akkurat-bold" fontSize={{ base: "20px", md: "22px" }}>
                    • {item.title}
                  </Text>
                  <Text fontSize={{ base: "18px", md: "22px" }} ml={4} color="#515151" textAlign="justify">
                    {item.description}
                  </Text>
                </Box>
              ))}
            </Box>
          </Flex>
        </Box>

        {/* Box 2 */}
        <Box w="100%" mb="50px">
          <Flex
            direction={{ base: "column", md: "row" }}
            align="stretch">
            <Box
              flex="1"
              bg="white"
              fontFamily="Akkurat"
              display="flex"
              flexDirection="column"
              p={{ base: "20px", md: "30px 20px 0px 80px" }}
              mr={{ base: "0px", md: "15px" }}
              minH="100%">
              <Text
                fontSize={{ base: "22px", md: "25px" }}
                fontFamily="Akkurat-bold"
                fontWeight="bold"
                mb={{ base: "15px", md: "25px" }}
                textAlign={{ base: "center", md: "left" }}
              >
                Animal Welfare
              </Text>
              <Text fontSize={{ base: "18px", md: "22px" }} color="#515151" mb="50px" textAlign="justify">
                At the heart of the initiative is the commitment to rehabilitate
                destitute cows and ensure their wellbeing through:
              </Text>
              {[
                {
                  title: "Smart and Sustainable Gaushalas (SSG):",
                  description:
                    "Establishing advanced cow sanctuaries that provide state-of-the-art care, including veterinary support, nutritional management, and spacious living environments.",
                },
                {
                  title: "Holistic Utilization of Cow By-products:",
                  description:
                    "Using cow dung, urine, and other by-products to create globally relevant, eco-friendly products such as organic manure, natural pesticides, biogas, and handmade goods. This approach integrates animal welfare into sustainable rural economies.",
                },
              ].map((item, index) => (
                <Box key={index} mb="25px">
                  <Text fontFamily="Akkurat-bold" fontSize={{ base: "20px", md: "22px" }}>
                    • {item.title}
                  </Text>
                  <Text fontSize={{ base: "18px", md: "22px" }} ml={4} color="#515151" textAlign="justify">
                    {item.description}
                  </Text>
                </Box>
              ))}
            </Box>
            <Box flex="1" display="flex" overflow="hidden" minH="100%">
              <Image
                src={Gaushala}
                alt="Environmental Protection"
                width="100%"
                height="100%"
                objectFit="cover"
              />
            </Box>
          </Flex>
        </Box>

        {/* Box 3*/}
        <Box w="100%">
          <Flex direction={{ base: "column", md: "row" }} align="center">
            <Box width={{ base: "100%", md: "43%" }} ml={0} display="flex">
              <Image
                src={Farm}
                alt="Environmental Protection"
                width="100%"
                height="100%"
                objectFit="cover"
              />
            </Box>
            <Box
              flex="1"
              bg="white"
              width={{ base: "100%", md: "50%" }}
              minH="100%"
              p={{ base: 4, md: 6 }}
              fontFamily="Akkurat"
              display="flex"
              flexDirection="column"
              justifyContent="center">
              <Text
                fontSize={{ base: "22px", md: "25px" }}
                fontFamily="Akkurat-bold"
                fontWeight="bold"
                mb={3}
                textAlign={{ base: "center", md: "left" }}
              >
                Human Welfare and Rural Economic Transformation
              </Text>
              <Text fontSize={{ base: "18px", md: "22px" }} color="#515151" textAlign="justify">
                Project ONE FUTURE focuses on creating inclusive growth opportunities
                by addressing nutritional security and driving socio-economic development
                through:
              </Text>
              {[
                {
                  title: "Nutritional Security:",
                  description:
                    "Supporting the production and distribution of nutrient-rich, sustainably grown food to combat malnutrition and promote public health.",
                },
                {
                  title: "Employment Generation:",
                  description:
                    "Developing SSGs as sustainable business models, creating mass employment opportunities for rural youth, women, and entrepreneurs.",
                },
                {
                  title: "Value Creation:",
                  description:
                    "Enabling rural communities to participate in global supply chains, fostering innovation, and creating long-term economic benefits for underserved populations.",
                },
              ].map((item, index) => (
                <Box key={index} mt={3}>
                  <Text fontFamily="Akkurat-bold" fontSize={{ base: "20px", md: "22px" }}>
                    • {item.title}
                  </Text>
                  <Text fontSize={{ base: "18px", md: "22px" }} ml={4} color="#515151" textAlign="justify">
                    {item.description}
                  </Text>
                </Box>
              ))}
            </Box>
          </Flex>
        </Box>

        <Box p={{ base: "20px", md: "50px" }} bg="#F7F9FC">
          <Text
            fontSize={{ base: "24px", md: "28px" }}
            fontWeight="bold"
            mb="20px"
          >
            Strategic Objectives
          </Text>

          <Flex
            justify="center"
            flexDir={{ base: "column", md: "row" }}
            gap={{ base: 4, md: 8, lg: 12 }}>
            {/* Card 1 */}
            <Box
              p="20px"
              borderRadius="12px"
              bg="white"
              boxShadow="md"
              width={{ base: "100%", md: "316px" }}
              Height="350px"
            >
              <Stack>
                <Flex align={"center"} justifyContent={"center"}>
                  <Text
                    fontFamily="Akkurat-bold"
                    color="blue.500"
                    fontSize="50px"
                    ml="40px"
                  >
                    1
                  </Text>
                  <Text fontSize="22px" fontWeight="bold" textAlign={"center"}>
                    Environmental Sustainability
                  </Text>
                </Flex>
                <UnorderedList>
                  <ListItem
                    fontSize="18px"
                    textAlign={"justify"}
                    color="#515151"
                  >
                    Enhance biodiversity, restore ecosystems, and combat climate
                    change through sustainable farming and renewable energy
                    solutions.
                  </ListItem>
                  <ListItem
                    fontSize="18px"
                    textAlign={"justify"}
                    color="#515151"
                  >
                    Achieve measurable reductions in greenhouse gas emissions
                    across the rural economy.
                  </ListItem>
                </UnorderedList>
              </Stack>
            </Box>

            {/* Card 2 */}
            <Box
              p="20px"
              borderRadius="12px"
              bg="white"
              boxShadow="md"
              width={{ base: "100%", md: "316px" }}
              Height="350px"
            >
              <Stack>
                <Flex align={"center"} justifyContent={"center"} gap="30px">
                  <Text
                    fontFamily="Akkurat-bold"
                    color="blue.500"
                    fontSize="50px"
                  >
                    2
                  </Text>
                  <Text fontSize="22px" fontWeight="bold" textAlign={"center"}>
                    Animal Welfare
                  </Text>
                </Flex>
                <UnorderedList>
                  <ListItem
                    fontSize="18px"
                    color="#515151"
                    textAlign={"justify"}
                  >
                    Provide dignified care and rehabilitation for destitute cows
                    while integrating their welfare into the rural economy.
                  </ListItem>
                  <ListItem
                    fontSize="18px"
                    color="#515151"
                    textAlign={"justify"}
                  >
                    Promote ethical and sustainable utilization of cow
                    by-products.
                  </ListItem>
                </UnorderedList>
              </Stack>
            </Box>

            {/* Card 3 */}
            <Box
              p="20px"
              borderRadius="12px"
              bg="white"
              boxShadow="md"
              width={{ base: "100%", md: "316px" }}
              Height="350px"
            >
              <Stack>
                <Flex align={"center"} justifyContent={"center"} gap="20px">
                  <Text
                    fontFamily="Akkurat-bold"
                    color="blue.500"
                    fontSize="50px"
                  >
                    3
                  </Text>
                  <Text fontSize="22px" fontWeight="bold" textAlign={"center"}>
                    Nutritional Security
                  </Text>
                </Flex>
                <UnorderedList>
                  <ListItem
                    fontSize="18px"
                    color="#515151"
                    textAlign={"justify"}
                  >
                    Address global hunger and malnutrition by ensuring access to
                    affordable, nutrient rich food.
                  </ListItem>
                  <ListItem
                    fontSize="18px"
                    color="#515151"
                    textAlign={"justify"}
                  >
                    Enhance public health outcomes through sustainable and
                    environmentally friendly food production practices.
                  </ListItem>
                </UnorderedList>
              </Stack>
            </Box>

            {/* Card 4 */}
            <Box
              p="20px"
              borderRadius="12px"
              bg="white"
              boxShadow="md"
              width={{ base: "100%", md: "316px" }}
              Height="350px"
            >
              <Stack>
                <Flex align={"center"} justifyContent={"center"}>
                  <Text
                    fontFamily="Akkurat-bold"
                    color="blue.500"
                    fontSize="50px"
                    ml="40px"
                  >
                    4
                  </Text>
                  <Text fontSize="22px" fontWeight="bold" textAlign={"center"}>
                    Rural Economic Empowerment
                  </Text>
                </Flex>
                <UnorderedList>
                  <ListItem
                    fontSize="18px"
                    color="#515151"
                    textAlign={"justify"}
                  >
                    Create large-scale employment opportunities in rural areas,
                    particularly for women and youth.
                  </ListItem>
                  <ListItem
                    fontSize="18px"
                    color="#515151"
                    textAlign={"justify"}
                  >
                    Empower rural communities to participate in global markets
                    and value chains, fostering inclusive growth.
                  </ListItem>
                </UnorderedList>
              </Stack>
            </Box>
          </Flex>
        </Box>
      </Box>

      <Box p={{ base: "20px", md: "50px" }} bg="#F7F9FC" fontFamily="Akkurat">
        <Text fontSize={{ base: "24px", md: "28px" }} fontFamily="Akkurat-bold" mb="20px">
          Anticipated Impact
        </Text>

        <Flex
          justify="center"
          gap={{ base: 4, md: 6 }}
          flexDirection={{ base: "column", md: "row" }}
          align="stretch">
          <Box
            flex="1"
            p="20px"
            borderRadius="12px"
            bg="white"
            boxShadow="md"
            textAlign="center"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            width={{ base: "100%", md: "403px" }}>
            <Flex gap="20px" justifyContent="center" alignItems="center">
              <Image src={Leaves} alt="Environmental Impact" width="50px" height="50px" />
              <Text fontSize="20px" fontFamily="Akkurat-bold">Environmental Impact</Text>
            </Flex>
            <Stack spacing={2} textAlign="left" mt="10px" flex="1">
              <UnorderedList>
                <ListItem fontSize="16px" color="#515151" textAlign="justify">
                  Improved soil fertility and increased carbon sequestration through organic farming.
                </ListItem>
                <ListItem fontSize="16px" color="#515151" textAlign="justify">
                  Reduced reliance on chemical inputs and fossil fuels, contributing to a greener environment.
                </ListItem>
              </UnorderedList>
            </Stack>
          </Box>
          <Box
            flex="1"
            p="20px"
            borderRadius="12px"
            bg="white"
            boxShadow="md"
            textAlign="center"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            width={{ base: "100%", md: "403px" }}
          >
            <Flex gap="20px" justifyContent="center" alignItems="center">
              <Image src={Hand} alt="Social Impact" width="50px" height="50px" />
              <Text fontSize="20px" fontFamily="Akkurat-bold">Social Impact</Text>
            </Flex>
            <Stack spacing={2} textAlign="left" mt="10px" flex="1">
              <UnorderedList>
                <ListItem fontSize="16px" color="#515151" textAlign="justify">
                  Enhanced livelihoods for rural communities through employment and entrepreneurship.
                </ListItem>
                <ListItem fontSize="16px" color="#515151" textAlign="justify">
                  Strengthened food security and reduced malnutrition.
                </ListItem>
              </UnorderedList>
            </Stack>
          </Box>
          <Box
            flex="1"
            p="20px"
            borderRadius="12px"
            bg="white"
            boxShadow="md"
            textAlign="center"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            width={{ base: "100%", md: "403px" }}
          >
            <Flex gap="20px" justifyContent="center" alignItems="center">
              <Image src={Growth} alt="Economic Impact" width="50px" height="50px" />
              <Text fontSize="20px" fontFamily="Akkurat-bold">Economic Impact</Text>
            </Flex>
            <Stack spacing={2} textAlign="left" mt="10px" flex="1">
              <UnorderedList>
                <ListItem fontSize="16px" color="#515151" textAlign="justify">
                  Establishment of India as a global leader in green initiatives.
                </ListItem>
                <ListItem fontSize="16px" color="#515151" textAlign="justify">
                  Creation of new revenue streams for rural entrepreneurs.
                </ListItem>
              </UnorderedList>
            </Stack>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default ProjectOneFuture;
