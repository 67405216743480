import React, { useState } from "react";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  Text,
  VStack,
  Image,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import PhoneIcon from "../../assets/contact/Call-1.png";
import MailIcon from "../../assets/contact/mail.png";
import BGImage from "../../assets/BG-2.png";
import FB from "../../assets/contact/fb.png";
import Insta from "../../assets/contact/Insta.png";
import Linkedin from "../../assets/contact/Ldn.png";
import Twitter from "../../assets/contact/X.png";
import { motion } from "framer-motion";

const ContactPage = () => {
  const MotionBox = motion(Box);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
    phone: ""
  });

  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.post("https://ichamber.org/api/v1/submit-contactus", formData,
        {
          headers: {
            "Api-Key": "XOHOU5GSQ6AJiD997rFIQ4Pz+LLIjqCg1Lrf8Up5",
            "Content-Type": "application/json"
          }
        }
      );
      toast({
        title: "Success!",
        description: "Your message has been sent.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setFormData({ name: "", email: "", subject: "", message: "" });
    } catch (error) {
      toast({
        title: "Error!",
        description: "There was an error sending your message.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box bg="gray.50" minH="100vh">
      <MotionBox
        w="100%"
        h={{ base: "300px", md: "580px" }}
        bgGradient="linear(to-b, blue.800, blue.500)"
        color="white"
        textAlign="center"
        p={8}
        backgroundImage={BGImage}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        backgroundPosition="center"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
       <Box
  textAlign={{ base: "center", md: "left" }} // Centered on mobile, left-aligned on larger screens
  ml={{ base: "15px", md: "20rem" }} // Adjusts margin for different screen sizes
  fontFamily="Akkurat-bold"
  mt={{ base: "30px", md: "50px" }} // Adds spacing at the top for better positioning
>
  <Text
    fontSize={{ base: "25px", md: "60px" }}
    fontWeight="600"
    mb={{ base: "-10px", md: "-30px" }} // Adjusts margin for consistency
  >
    CONTACT
  </Text>
  <Text
    as="span"
    fontWeight="600"
    color="#001F5C"
    fontSize={{ base: "25px", md: "60px" }}
  >
    US
  </Text>
</Box>

      </MotionBox>
      <Flex
        direction={{ base: "column", md: "row" }}
        p={8}
        justifyContent="center"
        alignItems="flex-start"
        gap={8}
      >
        {/* Left Section - Contact Info */}
        <Box w={{ base: "100%", md: "40%" }}>
          <Text fontSize="30px" fontWeight="bold" mb={4}>
            Connect with us
          </Text>
          <Text mb={6} color="#515151" fontSize="22px">
            Feel free to fill in the form for any further information or contact
            us at the below-given options:
          </Text>
          <VStack align="start" spacing={4}>
            <Flex align="center" gap={2} mb="52px">
              <Image src={MailIcon} alt="Mail" boxSize="24px" width={"22px"} height={"17px"} />
              <Text fontSize="22px" color="#515151">contact@ichamber.org</Text>
            </Flex>
            <Flex gap="20px">
              <Image src={FB} width={"40px"} height={"40px"} />
              <Image src={Insta} width={"40px"} height={"40px"} />
              <Image src={Linkedin} width={"40px"} height={"40px"} />
              <Image src={Twitter} width={"40px"} height={"40px"} />
            </Flex>
          </VStack>
        </Box>

        {/* Right Section - Contact Form */}
        <Box
          w={{ base: "100%", md: "40%" }}
          bg="#ffffff" // Form background color
          pb={8} // Padding for the bottom to prevent touching
          borderRadius="lg"
          shadow="md"
          display={"flex"}
          alignContent={"center"}
          justifyContent={"center"}
          flexDir={"column"}
        >
          {/* Header Section */}
          <Flex
            justifyContent={"center"}
            alignItems={"center"}
            bg="#dbdbdb"
            px={8}
            py={4}
            borderTopRadius="lg"
          >
            <Text fontSize="30px" fontWeight="400" mb={0} color="#515151">
              Let’s Get In Touch
            </Text>
          </Flex>

          {/* Form Section */}
          <Box px={8} pt={8} width={"100%"}>
            <form onSubmit={handleSubmit}>
              <FormControl isRequired mb={4}>
                <FormLabel color="#515151">Your Name</FormLabel>
                <Input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  bg="#f2f2f2"
                  borderRadius="0px"
                />
              </FormControl>

              <FormControl isRequired mb={4}>
                <FormLabel color="#515151">Your E-Mail</FormLabel>
                <Input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  bg="#f2f2f2"
                  borderRadius="0px"
                />
              </FormControl>

              <FormControl isRequired mb={4}>
                <FormLabel color="#515151">Subject</FormLabel>
                <Input
                  type="text"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  bg="#f2f2f2"
                  borderRadius="0px"
                />
              </FormControl>

              <FormControl isRequired mb={4}>
                <FormLabel color="#515151">Your Message</FormLabel>
                <Textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  rows={5}
                  bg="#f2f2f2"
                  borderRadius="0px"
                />
              </FormControl>

              <Button
                type="submit"
                colorScheme="blue"
                isFullWidth
                isLoading={loading}
                width={"235px"}
                borderRadius={"42px"}
              >
                SUBMIT
              </Button>
            </form>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default ContactPage;
