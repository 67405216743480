import React, { useState, useEffect, useRef } from 'react';
import { Box, Image, Text, Flex, GridItem, Grid } from '@chakra-ui/react';
import BGImage from '../../assets/BG-2.png';
import missionImg from "../../assets/health/mission_image.png";
import leadershipIcon from "../../assets/REF/leadership_img.png";
import multistakeIcon from "../../assets/REF/multiStake.png";
import announcementIcon from "../../assets/REF/announcement.png";
import agenda1 from "../../assets/health/agenda_1.png";
import agenda2 from "../../assets/health/agenda_2.png";
import agenda3 from "../../assets/health/agenda_3.png";
import { motion } from 'framer-motion';
import PlatformPartners from '../REF/platformPartners';
import RefSector3 from '../REF/refSection3';
import SingleSideSlider from '../singleSideSlider';
import healthImg1 from "../../assets/health/health_Img_1.png";
import healthImg2 from "../../assets/health/health_Img_2.png";
import healthImg3 from "../../assets/health/health_Img_3.png";
import healthImg4 from "../../assets/health/health_Img_4.png";
import healthImg5 from "../../assets/health/health_Img_5.png";
import healthImg6 from "../../assets/health/health_Img_6.png";
import "../REF/REF.css";

const Health = () => {
    const [imageInView, setImageInView] = useState([]);
    const ref = useRef(null);
    const MotionBox = motion(Box);
    const driveImpact = [
        {
            id: 1,
            icon: leadershipIcon,
            title: "Leadership Opportunities",
            description: "Bringing stakeholders together and collaborating on finding ways to tackle complex global issues.",
        },
        {
            id: 2,
            icon: multistakeIcon,
            title: "Multistakeholder Initiatives",
            description: "Inspiring and enabling new cooperation and partnerships through community action.",
        },
        {
            id: 3,
            icon: announcementIcon,
            title:  
            (
                <>
                    Raising Awareness
                </>
            ),
            description: "Expanding understanding of current health issues and identifying strategies to address them through public engagement.",
        },
    ];

    const agenda = [
        {
            id: 1,
            image: agenda1,
            label: "Digital & Smart Healthcare Delivery",
        },
        {
            id: 2,
            image: agenda2,
            label: (
                <>
                    Advance Manufacturing of Medical & <br /> Healthcare Products
                </>
            ),
        },
        {
            id: 3,
            image: agenda3,
            label: "New Innovations and Technologies",
        },
    ];


    const Data = [
        { id: 1, title: "Building ecosystem for Future of Health and Healthcare.", src: healthImg1 },
        { id: 2, title: "Policy Advocacy and knowledge creation to transform India's Healthcare Landscape", src: healthImg2 },
        { id: 3, title: "Industry Academia Partnership Forum", src: healthImg3 },
        { id: 4, title: "Global Platform for Investment and Value Creation", src: healthImg4 },
        { id: 5, title: "Advancement of Future of Skilling and Entrepreneurship", src: healthImg5 },
        { id: 6, title: "Building Health Infrastructure for Future", src: healthImg6 },
    ];

    const handleScroll = () => {
        const elements = document.querySelectorAll('.scroll-animate');
        const inViewArray = [...imageInView];

        elements.forEach((element, index) => {
            const rect = element.getBoundingClientRect();
            if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
                if (!inViewArray.includes(index)) {
                    inViewArray.push(index);
                    setImageInView([...inViewArray]);
                }
            }
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [imageInView]);

    return (
        <Box>
            <MotionBox
                w="100%"
                h="580px"
                bgGradient="linear(to-b, blue.800, blue.500)"
                color="white"
                textAlign="center"
                p={8}
                backgroundImage={BGImage}
                backgroundRepeat="no-repeat"
                backgroundSize="cover"
                backgroundPosition="center"
                display="flex"
                flexDirection="column"
                alignItems={{ base: "center", md: "flex-start" }}
                justifyContent="center"
                pl={{ base: "0px", md: "10%" }}
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
            >
                <Box textAlign={"left"} ml={{ md: "40rem", base: "15px" }} fontFamily="Akkurat-bold">
                    <Text
                        fontSize={{ base: "25px", md: "60px" }}
                        fontWeight="600"
                    >
                        INDIA HEALTH
                    </Text>
                    <Text fontSize={{ base: "25px", md: "40px" }} mt="-10px" fontWeight="600">
                        <Text
                            as="span"
                            fontWeight="600"
                            color="#001F5C"
                            fontSize={{ base: "25px", md: "40px" }}
                        >
                            DIALOGUE
                        </Text>
                        {/* APPRECIATIONS */}
                    </Text>
                </Box>
            </MotionBox>

            {/* section 1  */}
            <Box className="ref-s1-container">
                <Box className="s1-mission-section" pt="50px" ref={ref}>
                    <Box className="s1-mission-image-container">
                        <Image
                            src={missionImg}
                            alt="Green hand and robot hand"
                            className={`s1-mission-image ${imageInView.length > -10 ? 'in-view' : ''}`}
                        />
                    </Box>
                    <Box className="s1-mission-text-container">
                        <Text className="s1-mission-title">Our Mission</Text>
                        <Text className="s1-mission-description">
                        India Health Dialogue, a platform dedicated to fostering meaningful conversations and initiatives aimed at advancing healthcare in India. With a focus on collaboration, innovation, and community engagement, India Health Dialogue brings together healthcare professionals, policymakers, industry leaders, and stakeholders to address key challenges and opportunities in the Indian healthcare landscape.
                        </Text>
                    </Box>
                </Box>
            </Box>

            {/* section 2 */}
            <Box className="ref-S2-container">
                <Box mb={10} px={4}>
                    <Text className="ref-S2-sectionHeader">How We Drive Impact</Text>
                    <Grid
                        className="ref-S2-gridContainer ref-S2-gridContainerDesktop"
                        templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }}
                    >
                        {driveImpact.map((item, index) => (
                            <GridItem key={item.id} className="ref-S2-gridItem">
                                <Flex className="ref-S2-flexContainer">
                                    <motion.div
                                        className="scroll-animate"
                                        initial={{ opacity: 0, x: -100 }}
                                        animate={imageInView.includes(index) ? { opacity: 1, x: 0 } : {}}
                                        transition={{ duration: 0.8, delay: index * 0.3 }}
                                    >
                                        <Image src={item.icon} alt={item.title} className="ref-S2-image" />
                                    </motion.div>
                                    {/* <Spacer/> */}
                                    <Text className="ref-S2-title">{item.title}</Text>
                                </Flex>
                                <Text className="ref-S2-description">{item.description}</Text>
                            </GridItem>
                        ))}
                    </Grid>
                </Box>

                <Box px={4}>
                    <Text className="ref-S2-sectionHeader">Agenda</Text>
                    <Grid
                        className="ref-S2-gridContainer ref-S2-gridContainerDesktop"
                        templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }}
                    >
                        {agenda.map((item, index) => (
                            <GridItem key={item.id} textAlign="center">
                                <motion.div
                                    className="scroll-animate"
                                    initial={{ opacity: 0, y: 100 }}
                                    animate={imageInView.includes(index) ? { opacity: 1, y: 0 } : {}}
                                    transition={{ duration: 0.8, delay: index * 0.3 }}
                                >
                                    <Image
                                        src={item.image}
                                        alt={item.label}
                                        className={`agenda-image ${imageInView.includes(index) ? 'in-view' : ''}`}
                                    />
                                    <Box className="ref-S2-agendaLabel" mt="10px" p="10px">
                                        <Text fontFamily={"Akkurat"} fontSize='22px'>{item.label}</Text>
                                    </Box>
                                </motion.div>
                            </GridItem>
                        ))}
                    </Grid>
                </Box>
            </Box>

            {/* Section 3 */}
            <RefSector3 />

            {/* Our Goals */}
            <SingleSideSlider
                data={Data}
                title="Our Goals"
                description="India Health Dialogue provides a platform for stakeholders to connect, collaborate, and co-create innovative solutions to address pressing healthcare issues. Whether it’s exploring emerging trends in healthcare delivery."
            />

            {/* Platform Partners */}
            <PlatformPartners />
        </Box>
    );
};

export default Health;
