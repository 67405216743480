import React from 'react';
import { Box, Flex, Image, Text, Grid, GridItem } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import BGImage from '../../assets/BG-2.png';
const images = [
  require('../../assets/governance/pic1.png'),
  require('../../assets/governance/pic2.png'),
  require('../../assets/governance/pic3.png'),
  require('../../assets/governance/pic4.png'),
  require('../../assets/governance/pic5.png'),
  require('../../assets/governance/pic6.png'),
  require('../../assets/governance/pic7.png'),
  require('../../assets/governance/pic8.png'),
  require('../../assets/governance/pic9.png'),
  require('../../assets/governance/pic10.png'),
];

const Governance = () => {
  const MotionBox = motion(Box);

  const cards = [
    {
      name: "NITIN PANGROTRA",
      title: "President & CEO India Chamber",
      organization: "India Chamber",
    },
    {
      name: "CHANDEEP SINGH",
      title: "Vice President & Chief Investment Officer",
      organization: "Corporat",
    },
    {
      name: "PARAS ARORA",
      title: "Board Member, India Chamber",
      organization: "Tech Solutions",
    },
    {
      name: "DR. SAHAJANAND PRASHAD",
      title: "Member- Strategic Board, India Chamber & Past President-IMA",
      organization: "Global Outreach",
    },
    {
      name: "ATUL PAWAR",
      title: "Member- Strategic Board, Rural Economic Forum & OSD - Union Minister of MSME, GOI",
      organization: "Innovative Minds",
    },
    {
      name: "DR. RAVINDER SINGH",
      title: " Member- Strategic Board, India Chamber & Sr. Scientist, India Council of Medical Research (ICMR) ",
      organization: "Creative Ventures",
    },
    {
      name: "GOPINATH SHARMA",
      title: "Member- Strategic Board, Rural Economic Forum, India Chamber",
      organization: "Bright Future Pvt. Ltd.",
    },
    {
      name: "GAURAV JADHAV",
      title: "Assistant Director, India Chamber",
      organization: "Visionary Leaders",
    },
    {
      name: "ATUL DAYAL",
      title: "Member- Strategic Board, Rural Economic Forum, India Chamber",
      organization: "NextGen Solutions",
    },
    {
      name: "MILIND JADHAV",
      title: "Assistant Director, India Chamber",
      organization: "Empower Women Foundation",
    },
  ];

  return (
    <Box pb={10} backgroundColor={"#f6f6f6"}>
      <MotionBox
        w="100%"
        h={{ base: "400px", md: "500px", lg: "580px" }}
        bgGradient="linear(to-b, blue.800, blue.500)"
        color="white"
        textAlign="center"
        p={{ base: 4, md: 6, lg: 8 }}
        backgroundImage={BGImage}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        backgroundPosition="center"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        <Box
          mt={{ base: "150px", md: "200px", lg: "200px" }} // Adjusts top margin for responsiveness
          textAlign={{ base: "center", md: "left" }} // Centers on mobile, left-aligns on larger screens
          ml={{ base: "0px", md: "400px", lg: "700px" }} // Adjusts left margin for different screen sizes
          mr={{ base: "auto", md: "0px" }} // Ensures centering on mobile
        >
          <Text
            fontSize={{ base: "32px", md: "48px", lg: "64px" }}
            fontWeight="400"
            fontFamily="Akkurat-bold"
            mb={{ base: "-20px", md: "-30px", lg: "-50px" }} // Adjusts bottom margin dynamically
          >
            GOVERNANCE
          </Text>
          <Box mt={{ base: "10px", md: "15px", lg: "20px" }}>
            <Text
              fontSize={{ base: "32px", md: "48px", lg: "64px" }}
              fontWeight="400"
              fontFamily="Akkurat-bold"
              textAlign={{ base: "center", md: "left" }} // Ensures proper alignment
            >
              <Text
                as="span"
                fontSize={{ base: "24px", md: "32px", lg: "40px" }}
                fontWeight="400"
                color="#001F5C"
                mr="10px"
              >
                AND
              </Text>
              LEADERSHIP
            </Text>
          </Box>
        </Box>

      </MotionBox>

      <Box mt="8" p={4} backgroundColor={"#f6f6f6"} m={{base:"5px", md: "50px"}}>
        <Grid
          templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }}
          gap={{ base: "20px", md: "50px"}}
          justifyContent="center"
        >
          {cards.map((card, index) => (
            <GridItem
              key={index}
              bg="#ffffff"
              borderRadius="10px"
              boxShadow="md"
              width={{ base: "100%", sm: "80%", md: "530px" }} // Full width on mobile, shrinks on tablets, fixed on desktop
              height="auto" // Allows dynamic height
              mx="auto"
              p={{ base: 3, md: 0 }} // Adds padding for better mobile spacing
            >
              <Flex
                direction={{ base: "column", md: "row" }} // Stacks on mobile, row layout on larger screens
                alignItems="center"
                justifyContent="space-around"
                gap={{ base: "10px", md: "10px" }}
                width="100%"
                height="100%"
              >
                <Image
                  src={images[index]}
                  alt={`Card Image ${index + 1}`}
                  borderRadius={{ base: "10px 10px 0 0", md: "10px 0 0 10px" }} // Adjusts border radius for mobile stacking
                  objectFit="cover"
                  width={{ base: "100%", md: "180px" }} // Full width on mobile, fixed size on desktop
                  height={{ base: "180px", md: "100%" }} // Adjusts height dynamically
                />

                {/* Vertical Border (Hidden on Mobile) */}
                <Box
                  display={{ base: "none", md: "block" }} // Hide on mobile, show on desktop
                  borderLeft="3px solid #f6f6f6"
                  backgroundColor="#f6f6f6"
                  h="150px"
                  m="0px 20px"
                />

                {/* Text Content */}
                <Box textAlign={{ base: "center", md: "left" }} py={4} flex="1" px={{ base: 3, md: 0 }}>
                  <Text fontSize={{ base: "16px", md: "21px" }} fontWeight="bold">
                    {card.name}
                  </Text>
                  <Text fontSize={{ base: "14px", md: "18px" }} fontWeight="medium" color="gray.600">
                    {card.title}
                  </Text>
                </Box>
              </Flex>
            </GridItem>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default Governance;
