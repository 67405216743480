import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  IconButton,
  Image,
  Img,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useOutsideClick,
  VStack,
} from "@chakra-ui/react";
import searchIcon from "../../assets/home/searchIcon.png";
import searchBlackIcon from "../../assets/home/searchIcon_black.png";
import Logo from "../../assets/home/logo.png";
import LogoBlack from "../../assets/home/logo_black.png";
import { ChevronDownIcon, CloseIcon, HamburgerIcon, SearchIcon } from "@chakra-ui/icons";
import facebookIcon from "../../assets/home/Facebook.png";
import instagramIcon from "../../assets/home/Instagram.png";
import linkedinIcon from "../../assets/home/Linkedin.png";
import xIcon from "../../assets/home/Twitter.png";
import { useNavigate } from "react-router-dom";
import "./Navbar.css";

const Navbar = () => {
  const navigate = useNavigate();
  const [scrollOpacity, setScrollOpacity] = useState(0);
  const [isHamburgerOpen, setHamburgerOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const opacity = Math.min(scrollY / 120, 1);
      setScrollOpacity(opacity);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const dropdownRef = React.useRef();
  useOutsideClick({
    ref: dropdownRef,
    handler: () => { },
  });

  const toggleHamburgerMenu = () => {
    setHamburgerOpen(!isHamburgerOpen);
  };

  return (
    <nav
      className={`navbar ${scrollOpacity === 1 ? "scrolled" : "dynamic"}`}
      style={{
        "--scroll-opacity": scrollOpacity,
        "--text-color": scrollOpacity === 1 ? "black" : "white",
      }}
    >
      <Flex className="navbar-container">
        <Flex align="center" justify="center" width="100%" position="relative" mt="20px" display={{ base: "flex", md: "none" }}>
          <Box width="40px" />
          <Box position="absolute" left="50%" transform="translateX(-50%)">
            <Img
              onClick={() => navigate('/')}
              src={scrollOpacity === 1 ? LogoBlack : Logo}
              alt="Logo"
              cursor="pointer"
              width="120px"
            />
          </Box>
          <IconButton
            icon={<HamburgerIcon boxSize={6} color={scrollOpacity === 1 ? "black" : "white"} />}
            variant="ghost"
            aria-label="Open Menu"
            onClick={toggleHamburgerMenu}
            position="absolute"
            left="100%"
          />
        </Flex>


        {/* web view  */}
        <Box className="logo" display={{ base: "none", md: "flex" }}>
          <Img onClick={() => navigate('/')} src={scrollOpacity === 1 ? LogoBlack : Logo} alt="Logo" cursor='pointer' />
        </Box>

        <HStack spacing='50px' display={{ base: "none", md: "flex" }}>
          <Button variant="link" color="inherit"
            className="menu__text"
            onClick={() => navigate('/')}>
            Home
          </Button>

          <Menu>
            <MenuButton
              as={Button}
              variant="link"
              color="inherit"
              className="menu__text"
              rightIcon={<ChevronDownIcon />}
            >
              Platforms
            </MenuButton>
            <MenuList bg="white" color="black">
              <MenuItem
                // className="option-divider"
                onClick={() => navigate('/india-investor-hub')}
              >India Investor Hub</MenuItem>
              <MenuItem
                // className="option-divider"
                onClick={() => navigate('/health')}>India Health Dialogue</MenuItem>
              <MenuItem onClick={() => navigate('/rural-economic-forum')}>Rural Economic Forum</MenuItem>
            </MenuList>
          </Menu>

          <Menu>
            <MenuButton
              as={Button}
              variant="link"
              color="inherit"
              className="menu__text"
              rightIcon={<ChevronDownIcon />}
            >
              Program
            </MenuButton>
            <MenuList bg="white" color="black">
              <MenuItem
                onClick={() => navigate('/project-one-future')}
              >Project One Future</MenuItem>

              <MenuItem
                onClick={() => navigate('/smart-sustainable-gaushala')}
              >Smart Sustainable Gaushala (SSG)</MenuItem>
            </MenuList>
          </Menu>

          <Button variant="link" color="inherit"
            className="menu__text" onClick={() => navigate('/contact-us')}>
            Contact Us
          </Button>
        </HStack>

        <HStack gap='40px' display={{ base: "none", md: "flex" }}>
          <Button onClick={() => navigate('/join')} className="btn btn-primary">
            Join Us
          </Button>
          <Img
            src={scrollOpacity === 1 ? searchBlackIcon : searchIcon}
            alt="Search Icon"
            width='35px'
            onClick={toggleHamburgerMenu}
            style={{ cursor: "pointer" }}
          />
        </HStack>
      </Flex>

      <Drawer isOpen={isHamburgerOpen} placement="right" onClose={toggleHamburgerMenu}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>
            <Flex justify="space-between" align="center" width='100%'>
              <Flex
                align="center"
                bg="gray.100"
                borderRadius="70px"
                width="100%"
                px={2}
                py={1}
              >
                <SearchIcon color="gray.500" mr={2} />
                <input
                  type="text"
                  placeholder="Search"
                  style={{
                    border: "none",
                    outline: "none",
                    background: "transparent",
                    width: "100%",
                  }}
                />
              </Flex>
              <IconButton
                aria-label="Close Menu"
                icon={<CloseIcon />}
                bg='transparent'
                ml='20px'
                onClick={toggleHamburgerMenu}
              />
            </Flex>
          </DrawerHeader>
          <DrawerBody>
            <VStack align="flex-start" spacing={3}>
              <Button variant="link"
                className="drawer-options" onClick={() => {
                  toggleHamburgerMenu();
                  navigate('/overview');
                }}>
                Overview
              </Button>
              <Divider />
              <Button variant="link"
                className="drawer-options" onClick={() => {
                  toggleHamburgerMenu();
                  navigate('/india-investor-hub');
                }}>
                India Investor Hub
              </Button>
              <Divider />
              <Button variant="link"
                className="drawer-options" onClick={() => {
                  toggleHamburgerMenu();
                  navigate('/health');
                }}>
                India Health Dialogue
              </Button>
              <Divider />
              <Button variant="link"
                className="drawer-options" onClick={() => {
                  toggleHamburgerMenu();
                  navigate('/rural-economic-forum');
                }}>
                Rural Economic Forum
              </Button>
              <Divider />
              <Button variant="link"
                className="drawer-options" onClick={() => {
                  toggleHamburgerMenu();
                  navigate('/project-one-future');
                }}>
                Project One Future
              </Button>
              <Divider />
              <Button variant="link"
                className="drawer-options" onClick={() => {
                  toggleHamburgerMenu();
                  navigate('/governace-and-leadership');
                }}>
                Governance and Leadership
              </Button>
              <Divider />
              <Button variant="link"
                className="drawer-options" onClick={() => {
                  toggleHamburgerMenu();
                  navigate('/join');
                }}>
                Membership Benefits
              </Button>
              <Divider />
              <Button variant="link"
                className="drawer-options" onClick={() => {
                  toggleHamburgerMenu();
                  navigate('/collaberation-and-appreciation');
                }}>
                Collaborations and Appreciations
              </Button>
              <Divider />
              <Button variant="link"
                className="drawer-options" onClick={() => {
                  toggleHamburgerMenu();
                  navigate('/terms-and-conditions');
                }}>
                Terms and Conditions
              </Button>
              <Divider />
              <Button variant="link"
                className="drawer-options" onClick={() => {
                  toggleHamburgerMenu();
                  navigate('/privacy-policy');
                }}>
                Privacy Policy
              </Button>
              <Flex mt={4} gap={1} justify="flex-start">
                <Button as="a" href="https://www.facebook.com" target="_blank" variant="ghost">
                  <Image src={facebookIcon} size={10} />
                </Button>
                <Button as="a" href="https://www.instagram.com" target="_blank" variant="ghost">
                  <Image src={instagramIcon} size={20} />
                </Button>
                <Button as="a" href="https://www.linkedin.com" target="_blank" variant="ghost">
                  <Image src={linkedinIcon} size={20} />
                </Button>
                <Button as="a" href="https://x.com/IChamberOrg" target="_blank" variant="ghost">
                  <Image src={xIcon} size={20} />
                </Button>
              </Flex>
              <Button
                as="a"
                href="/brochure.pdf"
                target="_blank"
                rel="noopener noreferrer"
                width="100%"
                mt={4}
                className="download-btn"
              >
                Download Brochure
              </Button>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </nav >
  );
};

export default Navbar;
