import React from 'react';
import { Box, Image, Text, Flex, GridItem, Grid } from '@chakra-ui/react';
import BGImage from '../../assets/home/REF_bg_img.png';
import REFlogo from "../../assets/REF/REF_logo.png";
import missionImg from "../../assets/REF/mission_img.png";
import leadershipIcon from "../../assets/REF/leadership_img.png";
import multistakeIcon from "../../assets/REF/multiStake.png";
import announcementIcon from "../../assets/REF/announcement.png";
import agenda1 from "../../assets/REF/agenda_1.png";
import agenda2 from "../../assets/REF/agenda_2.png";
import agenda3 from "../../assets/REF/agenda_3.png";
import goalImg1 from "../../assets/REF/goal_img1.png";
import goalImg2 from "../../assets/REF/goal_img2.png";
import goalImg3 from "../../assets/REF/goal_img3.png";
import goalImg4 from "../../assets/REF/goal_img4.png";
import goalImg5 from "../../assets/REF/goal_img5.png";
import goalImg6 from "../../assets/REF/goal_img6.png";
import { motion } from 'framer-motion';
import RefSector3 from './refSection3';
import OurEvents from './refEvents';
import PlatformPartners from './platformPartners';
import SingleSideSlider from '../singleSideSlider';
import "./REF.css"
import { useNavigate } from 'react-router-dom';

const REF = () => {
    const navigate = useNavigate();
    const MotionBox = motion(Box);

    const handleTabClick = (tab) => {
        if (tab === "Overview") {
            // navigate("#");
            window.open("/REF-PDF-23-1-24-2.pdf", "_blank", "noopener,noreferrer");
        } else if (tab === "SSG") {
            navigate("/smart-sustainable-gaushala");
        } else if (tab === "REF 2025") {
            navigate("/rural-economic-forum-2025");
        }
    };

    const driveImpact = [
        {
            id: 1,
            icon: leadershipIcon,
            title: "Leadership Opportunities",
            description:
                "Bringing stakeholders together and collaborating on finding ways to tackle complex global issues.",
        },
        {
            id: 2,
            icon: multistakeIcon,
            title: "Multistakeholder Initiatives",
            description:
                "Inspiring and enabling new cooperation and partnerships through community action.",
        },
        {
            id: 3,
            icon: announcementIcon,
            title: (
                <>
                    Raising <br /> Awareness
                </>
            ),
            description:
                "Expanding understanding of current health issues and identifying strategies to address them through public engagement.",
        },
    ];

    const agenda = [
        {
            id: 1,
            image: agenda1,
            label: "Rural Entrepreneurial Hubs",
        },
        {
            id: 2,
            image: agenda2,
            label: "Rural Industrial Hubs",
        },
        {
            id: 3,
            image: agenda3,
            label: "Rural Innovation And Digital Hubs",
        },
    ];

    const Data = [
        { id: 1, title: "Global Alliance on Rural Entrepreneurship", src: goalImg1 },
        { id: 2, title: "Promoting investment and business opportunities in rural areas", src: goalImg2 },
        { id: 3, title: "Building Indian- global Partnerships", src: goalImg3 },
        { id: 4, title: "Scaling business and value creation through value chain integration", src: goalImg4 },
        { id: 5, title: "Nurturing innovations and technologies", src: goalImg5 },
        { id: 6, title: "Building India- led global supply chain networks", src: goalImg6 },
    ];

    return (
        <Box>
            <MotionBox
                w="100%"
                h="580px"
                bgGradient="linear(to-b, blue.800, blue.500)"
                color="white"
                textAlign="center"
                pt={8}
                backgroundImage={BGImage}
                backgroundColor='#024B62'
                backgroundRepeat="no-repeat"
                backgroundSize="cover"
                backgroundPosition="center"
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
            />
            {/* section 1  */}
            <Box className="ref-s1-container" mt='-510px'>
                <Box className="s1-logo-section">
                    <Image src={REFlogo} className="s1-logo-image" />
                    <Box className="s1-title-section">
                        <Text className="s1-title1-text">Rural <br /> Economic <br /> Forum</Text>
                        <Box
                            width="2px"
                            height="100%"
                            color='white !important'
                            mx="16px"
                        />
                        <Text className="s1-subtitle-text" 
                        style={{borderLeft:'#FFFFFF 1px solid', paddingLeft:'30px'}}>BUILDING <br /> GLOBAL <br /> PATHWAYS <br /> FOR <br />RURAL ECONOMY</Text>
                    </Box>
                </Box>

                <Box className="s1-tabs-section">
                    {["Overview", "SSG", "REF 2025"].map((tab) => (
                        <Box key={tab} as="button"
                            className="s1-tab-button"
                            onClick={() => handleTabClick(tab)}>
                            {tab}
                        </Box>
                    ))}
                </Box>

                <Box className="s1-mission-section">
                    <Box className="s1-mission-image-container">
                        <Image src={missionImg} alt="Green hand and robot hand" className="s1-mission-image-ref" />
                    </Box>

                    <Box className="s1-mission-text-container">
                        <Text className="s1-mission-title">Our Mission</Text>
                        <Text className="s1-mission-description">
                            The Rural Economic Forum is designed as a global business engagement and collaborative platform
                            with an Annual Global Summit for building a robust ecosystem for promoting the rural economy
                            and bringing transformative outcomes in the rural sector and advancing Indian rural business
                            footprints globally.
                        </Text>
                    </Box>
                </Box>
            </Box>

            {/* section 2 */}
            <Box className="ref-S2-container">
                <Box mb={10} px={4}>
                    <Text className="ref-S2-sectionHeader">How We Drive Impact</Text>
                    <Grid
                        className="ref-S2-gridContainer ref-S2-gridContainerDesktop"
                        templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }}
                    >
                        {driveImpact.map((item) => (
                            <GridItem key={item.id} className="ref-S2-gridItem">
                                <Flex className="ref-S2-flexContainer">
                                    <Image src={item.icon} alt={item.title} className="ref-S2-image" />
                                    <Text className="ref-S2-title">{item.title}</Text>
                                </Flex>
                                <Text className="ref-S2-description">{item.description}</Text>
                            </GridItem>
                        ))}
                    </Grid>
                </Box>

                <Box px={4}>
                    <Text className="ref-S2-sectionHeader">Agenda</Text>
                    <Grid
                        className="ref-S2-gridContainer ref-S2-gridContainerDesktop"
                        templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }}
                    >
                        {agenda.map((item) => (
                            <GridItem key={item.id} textAlign="center">
                                <Box className="ref-S2-agendaItem">
                                    <Image src={item.image} alt={item.label} />
                                    <Box className="ref-S2-agendaLabel">
                                        <Text>{item.label}</Text>
                                    </Box>
                                </Box>
                            </GridItem>
                        ))}
                    </Grid>
                </Box>
            </Box>

            {/* section 3 */}
            <RefSector3 />

            <SingleSideSlider
                data={Data}
                title="Our Goals"
                description="India Health Dialogue provides a platform for stakeholders to connect, collaborate, and co-create innovative solutions to address pressing healthcare issues. Whether it’s exploring emerging trends in healthcare delivery."
            />

            {/* our events  */}
            <OurEvents />

            {/* Platform Partners */}
            <PlatformPartners />
        </Box>
    );
};

export default REF;
