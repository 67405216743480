import React from "react";
import { Box, Text, Flex, Image, Button } from "@chakra-ui/react";
import "swiper/css";
import "swiper/css/navigation";
import "./REF.css";
import healthImage from "../../assets/health/intro_health_img.png";
import refImage from "../../assets/REF/intro_REF_img.png";
import sectorImg1 from "../../assets/REF/sector_img1.png";
import sectorImg2 from "../../assets/REF/sector_img2.png";
import sectorImg3 from "../../assets/REF/sector_img3.png";
import sectorImg4 from "../../assets/REF/sector_img4.png";
import sectorImg5 from "../../assets/REF/sector_img5.png";
import sectorImg6 from "../../assets/REF/sector_img6.png";
import { useLocation } from "react-router-dom";
import MultiImageSlider from "../multiSlider";

const RefSector3 = () => {
  const location = useLocation();
  const showFocusedSectors = !location.pathname.includes("/health");

  const sectors = [
    { id: 1, title: "Cottage Industry", image: sectorImg1 },
    { id: 2, title: "Bio and Renewable Energy", image: sectorImg2 },
    { id: 3, title: "Rural Finance", image: sectorImg3 },
    { id: 4, title: "Animal Husbandry", image: sectorImg4 },
    { id: 5, title: "Agriculture and Agri Business", image: sectorImg5 },
    { id: 6, title: "Rural Health", image: sectorImg6 },
  ];

  return (
    <>
      {showFocusedSectors && (
        <Box className="ref-S3-focusedSectorsWrapper">
          <Text className="ref-S3-sectionHeader">Focused Sectors</Text>

          <MultiImageSlider sectors={sectors} />
        </Box>)}

      {showFocusedSectors ? (
        <Box className="ref-S3-introWrapper">
          <Flex className="ref-S3-introFlex">
            <Image src={refImage} alt="Agriculture" className="ref-S3-introImage" />
            <Box className="ref-S3-introTextWrapper">
              <Text className="ref-S3-introHeader">Introduction to <br /> Rural Economic Forum</Text>
              <Text className="ref-S3-introDescription">
                REF is a public-private partnership platform to promote the future of the rural
                economy and a flagship initiative for shaping the agenda of rural development.
              </Text>
              <Button className="ref-S3-learnMoreButton"
                as="a"
                href="/REF.pdf"
                target="_blank"
                rel="noopener noreferrer">Learn More →</Button>
            </Box>
          </Flex>
        </Box>
      ) : (
        <Box className="ref-S3-introWrapper">
          <Flex className="ref-S3-introFlex">
            <Image src={healthImage} alt="Health" className="ref-S3-introImage" />
            <Box className="ref-S3-introTextWrapper">
              <Text className="ref-S3-introHeader">Introduction to <br />
                India Health Dialogue</Text>
              <Text className="ref-S3-introDescription">
                India Health Dialogue provides a platform for stakeholders to connect, collaborate, and co-create innovative solutions to address pressing healthcare issues. Whether it’s exploring emerging trends in healthcare delivery.
              </Text>
              <Button className="ref-S3-learnMoreButton"
               as="a"
               href="/REF SUMMIT_REPORT-1.pdf"
               target="_blank"
               rel="noopener noreferrer">Learn More →</Button>
            </Box>
          </Flex>
        </Box>
      )}
    </>
  );
};

export default RefSector3;
