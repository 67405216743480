import React from "react";
import { Box, Flex, Heading, Image } from "@chakra-ui/react";
import PlatformPartners1 from "../../assets/REF/partner_1.png";
import PlatformPartners2 from "../../assets/REF/partner_2.png";
import "./REF2.css";

const PlatformPartners = () => {
    return (
        <Box className="ref-S3-focusedSectorsWrapper">
            <Heading as="h2" className="ref-S3-sectionHeader">
                Platform Partners
            </Heading>
            <Flex className="PP-container">
                <Box className="PP-box">
                    <Image src={PlatformPartners1} className="PP-image1" />
                </Box>
                <Box className="PP-box">
                    <Image src={PlatformPartners2} className="PP-image2" />
                </Box>
            </Flex>
        </Box>
    );
};

export default PlatformPartners;
