import React, { useState, useRef } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
} from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "./section5.css";

const OurEvents = ({ Data }) => {
  const swiperRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(1);

  const openModal = (event) => {
    setSelectedEvent(event);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelectedEvent(null);
    setCurrentSlide(1);
  };

  return (
    <Box className="S5-container">
      <Flex className="S5-eventsSection">
        <Box className="S5-eventsText">
          <Heading as="h2" className="S5-heading">
            Gallery
          </Heading>
          <Text className="S5-subtext">
            These are some of our events which were organized by Our India
            Chamber.
          </Text>
          <Flex align="center" className="S5-eventArrows">
            <Button
              className="S5-eventButton-pre"
              width="40px"
              height="45px"
              onClick={() => swiperRef.current?.slidePrev()}
            >
              ←
            </Button>
            <Button
              className="S5-eventButton-nxt"
              width="40px"
              height="45px"
              onClick={() => swiperRef.current?.slideNext()}
            >
              →
            </Button>
          </Flex>
        </Box>

        <Swiper
          spaceBetween={20}
          slidesPerView={1}
          breakpoints={{
            640: { slidesPerView: 1 },
            768: { slidesPerView: 2 },
            1024: { slidesPerView: 3 },
          }}
          onSwiper={(swiper) => (swiperRef.current = swiper)}
          navigation={false} // Navigation handled manually
          modules={[Navigation]}
          className="S5-eventsGrid"
          style={{ width: "100%" }}
          loop={true}
        >
          {Data.map((event) => (
            <SwiperSlide
              key={event.id}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Box
                className="S5-eventCard"
                style={{ width: "100%", maxWidth: "300px" }}
                onClick={() => openModal(event)}
              >
                <Image
                  src={event?.images[0]} // Show first image as thumbnail
                  alt={event?.title}
                  width="100%"
                  objectFit="cover"
                  className="S5-eventImage"
                />
                <Box className="S5-eventText">{event?.title}</Box>
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      </Flex>

      {selectedEvent && selectedEvent.images?.length > 0 && (
        <Modal isOpen={isOpen} onClose={closeModal} maxWidth="90vh" isCentered>
          <ModalOverlay />
          <ModalContent
            bg="blackAlpha.900"
            borderRadius="md"
            boxShadow="lg"
            height={{ base: "100vh", md: "90vh", lg: "620px" }}
            maxWidth="90%"
            maxHeight="720px"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            padding="20px"
          >
            <ModalCloseButton color="white" size="lg" />
            <Text
              fontSize={{ base: "lg", md: "xl" }}
              fontWeight="bold"
              color="white"
              textAlign="center"
              mb={4}
              px={6}
              mt={2}
            >
              {selectedEvent.title}
            </Text>
            <Swiper
              spaceBetween={10}
              centeredSlides={true}
              slidesPerView={1.2}
              navigation
              modules={[Navigation]}
              className="S5-modalSlider"
              style={{
                width: "100%",
                maxWidth: "100%",
                maxHeight: "80%",
                textAlign: "center",
              }}
              onSlideChange={(swiper) => setCurrentSlide(swiper.realIndex + 1)}
            >
              {selectedEvent.images.map((img, index) => (
                <SwiperSlide
                  key={index}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Image
                    src={img}
                    alt={`${selectedEvent.title} - Image ${index + 1}`}
                    objectFit="contain"
                    width="80%"
                    maxWidth="100%"
                    maxHeight="600px"
                    borderRadius="md"
                    className="S5-eventModalImage"
                  />
                </SwiperSlide>
              ))}
            </Swiper>

            <Text color="white" mt={2}>
              {currentSlide} / {selectedEvent.images.length}
            </Text>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
};

export default OurEvents;
