import React from "react";
import { Box, Image, Text, Grid, GridItem, Flex } from "@chakra-ui/react";
import { motion } from "framer-motion";
import BGImage from "../../assets/BG-2.png";

const images = [
  {
    src: require("../../assets/partnerships/pic1.png"),
    text: "MOU with Maharashtra Goseva Ayog, Government of Maharashtra",
    link: () =>
      window.open(
        "/doc04248720231108174735.pdf",
        "_blank",
        "noopener,noreferrer"
      ),
  },
  {
    src: require("../../assets/partnerships/pic2.png"),
    text: "MOU with IMA",
    link: () =>
      window.open(
        "/IHD-MESSAGE-BY-DG-ICMR-1.pdf",
        "_blank",
        "noopener,noreferrer"
      ),
  },
  {
    src: require("../../assets/partnerships/pic3.png"),
    text: "Partnership with Ministry of MSME, Government of India for Rural Economic Forum",
    link: () =>
      window.open(
        "/doc04248720231108174735.pdf",
        "_blank",
        "noopener,noreferrer"
      ),
  },
  {
    src: require("../../assets/partnerships/pic4.png"),
    text: "Partnership letter from Ministry of MSME for India Health Dialogue",
    link: () =>
      window.open(
        "/doc02920820230427135417.pdf",
        "_blank",
        "noopener,noreferrer"
      ),
  },
  {
    src: require("../../assets/partnerships/pic5.png"),
    text: "Support Letter from Ministry of Health and Family Welfare, GOI",
    link: () =>
      window.open(
        "/doc04248720231108174735.pdf",
        "_blank",
        "noopener,noreferrer"
      ),
  },
  {
    src: require("../../assets/partnerships/pic6.png"),
    text: "MOU with ICMR",
    // link: () =>
    //   window.open(
    //     "/#",
    //     "_blank",
    //     "noopener,noreferrer"
    //   ),
  },
  {
    src: require("../../assets/partnerships/pic7.png"),
    text: "Letter of Appreciation",
    link: () =>
      window.open(
        "/Adobe-Scan-30-May-2024-2.pdf",
        "_blank",
        "noopener,noreferrer"
      ),
  },
  {
    src: require("../../assets/partnerships/pic8.png"),
    text: "Partnership with Ministry of MSME GOI, for Project one and SSG's",
    link: () =>
      window.open(
        "/Adobe-Scan-30-May-2024-3.pdf",
        "_blank",
        "noopener,noreferrer"
      ),
  },
];

const Governance = () => {
  const MotionBox = motion(Box);

  return (
    <Box pb={10}>
      <MotionBox
        w="100%"
        h="580px"
        bgGradient="linear(to-b, blue.800, blue.500)"
        color="white"
        textAlign="center"
        p={8}
        backgroundImage={BGImage}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        backgroundPosition="center"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        <Box
          mt={{ base: "200px", md: "200px", lg: "200px" }}
          textAlign={{ base: "center", md: "left" }}
          ml={{ base: "0px", md: "400px", lg: "700px" }}
          mr={{ base: "auto", md: "0px" }} // Centers on mobile
        >
          <Text
            fontSize={{ base: "32px", md: "48px", lg: "64px" }}
            fontWeight="400"
            fontFamily="Akkurat-bold"
            mb={{ base: "-20px", md: "-30px", lg: "-50px" }}
          >
            PARTNERSHIP
          </Text>
        </Box>

      </MotionBox>

      {/* Card Grid */}
      <Box
        mt="8"
        mx="auto"
        width="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        p="50px"
      >
        <Grid
          templateColumns={{
            base: "1fr",
            md: "repeat(2, 1fr)",
            lg: "repeat(2, 1fr)",
          }}
          gap={20}
          width="100%"
        // maxWidth="1420px"
        >
          {images.map((item, index) => (
            <GridItem
              key={index}
              boxShadow="md"
              borderRadius="lg"
              overflow="hidden"
              bg="white"
              width="100%"
            >
              <Image
                src={item.src}
                alt={`Partnership Image ${index + 1}`}
                w="100%"
                objectFit="cover"
                cursor='pointer'
                onClick={item.link}
              />
              <Flex alignItems="center" justifyContent="center" textAlign={"center"} p={4} height={"100px"}>
                <Text
                  fontSize={{ base: "18px", md: "25px" }}
                  fontWeight="600"
                  color="gray.700"
                  fontFamily="Akkurat"
                >
                  {item.text}
                </Text>
              </Flex>
            </GridItem>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default Governance;
