import React from "react";
import { Box, Image, Text, Link, Flex } from "@chakra-ui/react";
import BGImage from "../../assets/BG-2.png";
import { motion } from "framer-motion";
import Press1 from "../../assets/press/press1.png";
import Press2 from "../../assets/press/press2.png";
import Press3 from "../../assets/press/press3.png";
import Press4 from "../../assets/press/press4.png";
import Press5 from "../../assets/press/press5.png";

const PressRelease = () => {
  const MotionBox = motion(Box);

  const pressData = [
    {
      image: Press1,
      title: "Shri Narayan Rane holds discussions on India Health Dialogue Initiative and proposed Maharashtra Global Med Tech Zone (MGMTZ)",
      link: "https://pib.gov.in/PressReleasePage.aspx?PRID=1945359",
    },
    {
      image: Press2,
      title: "India Chamber of Business and Commerce and Indian Council of Medical Research signs MOA for Partnership in building Future of Health and Healthcare in India and partnership in India Health Dialogue Platform",
      link: "https://pib.gov.in/PressReleaseIframePage.aspx?PRID=1941111",
    },
    {
      image: Press3,
      title: "Narayan Rane launches new platform to support MSMEs in healthcare with advance manufacturing, exports",
      link: "https://www.financialexpress.com/business/sme-msme-eodb- indian-health-dialogue-to-enhance-msmes-in-healthcare-by- advance-manufacturing-capabilities-narayan-rane-3075333/",
    },
    {
      image: Press4,
      title: "IHD platform aims to promote advanced manufacturing capabilities among MSMEs in healthcare",
      link: "https://etedge-insights.com/industry/manufacturing/ihd-platform- aims-to-promote-advanced-manufacturing-capabilities-among- msmes-in-healthcare/",
    },
    {
      image: Press5,
      title: "Shri Narayan Rane says Health identified as target sector for import substitution and structured interventions to promote advance manufacturing capabilities among MSME’s for achieving AtamNirbhar Bharat and export promotion",
      link: "https://pib.gov.in/PressReleasePage.aspx?PRID=1921985",
    },
  ];

  return (
    <Box bg="#f6f6f6">
      <MotionBox
        w="100%"
        h="580px"
        bgGradient="linear(to-b, blue.800, blue.500)"
        color="white"
        textAlign="center"
        p={8}
        backgroundImage={BGImage}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        backgroundPosition="center"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        <Box mt="200px" textAlign={"left"} ml="700px">
          <Text
            fontSize="64px"
            fontWeight="400"
            fontFamily="Akkurat-bold"
            mb="-50px"
          >
            PRESS RELEASE
          </Text>
        </Box>
      </MotionBox>

      {/* Images Section */}
      <Text p={"30px 200px"} fontFamily={"Akkurat-bold"} fontSize={"30px"}>
        Chamber in the news
      </Text>
      <Flex
        direction={{ base: "column", md: "row" }}
        flexWrap="wrap"
        justifyContent="center"
        alignItems="center"
        gap={{ base: 8, md: 32 }}
        mt={8}
        pb={8}
        px={4}
      >
        {pressData.map((press, index) => (
          <Box
            key={index}
            width={{ base: "100%", md: "48%" }}
            maxW="500px"
            boxShadow="lg"
            borderRadius="md"
            overflow="hidden"
            position="relative"
          >
            <Image
              src={press.image}
              alt={`Press ${index + 1}`}
              w="100%"
              h="auto"
              objectFit="cover"
            />
            {/* Gradient Overlay */}
            <Box
              position="absolute"
              bottom="0"
              w="100%"
              h="100%"
              bgGradient="linear(to-t, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.4), transparent)"
            />
            {/* Text and Link */}
            <Box
              position="absolute"
              bottom="10px"
              left="10px"
              right="10px"
              color="white"
              textAlign="left"
            >
              <Text fontSize="20px" fontWeight={400}>{press.title}</Text>
              <Link
                href={press.link}
                isExternal
                color="#4E98FF"
                fontSize="16px"
                fontWeight={400}
                mt={1}
                display="inline-block"
              >
                {press.link}
              </Link>
            </Box>
          </Box>
        ))}
      </Flex>
    </Box>
  );
};

export default PressRelease;
