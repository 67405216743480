import React, { useState } from "react";
import {
  Box,
  Text,
  Input,
  Button,
  VStack,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Flex,
  ListItem,
  UnorderedList,
  Image,
  SimpleGrid,
  useToast,
} from "@chakra-ui/react";
import BGImage from "../../assets/home/BG.png";
import BG2Image from "../../assets/join/joinBG.png";
import BG2 from "../../assets/join/BG2.png";
import S1 from "../../assets/join/s1.png";
import S2 from "../../assets/join/s2.png";
import S3 from "../../assets/join/s3.png";
import S4 from "../../assets/join/s4.png";
import S5 from "../../assets/join/s5.png";

import Sl1 from "../../assets/join/sl1.png";
import Sl2 from "../../assets/join/sl2.png";
import Sl3 from "../../assets/join/sl3.png";
import Sl4 from "../../assets/join/sl4.png";
import Sl5 from "../../assets/join/sl5.png";
import Sl6 from "../../assets/join/sl6.png";

import img1 from "../../assets/join/last1.png";
import img2 from "../../assets/join/last2.png";
import img3 from "../../assets/join/last3.png";

import Shadow from "../../assets/join/shadow.png";
import Shadow1 from "../../assets/join/shadow1.png";

import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import axios from "axios";

const JoinPage = () => {
  const slidesData = [
    {
      id: 1,
      image: Sl1,
      title:
        "Interface Between Government and Institutions and Business Leaders",
    },
    {
      id: 2,
      image: Sl2,
      title: "Platform for Networking and Impact Leadership",
    },
    {
      id: 3,
      image: Sl3,
      title: "Ability to Advise and Effect Policy Changes",
    },
    {
      id: 4,
      image: Sl4,
      title: "Provide Knowledge Base for Better Governance Practice",
    },
    {
      id: 5,
      image: Sl5,
      title:
        "Large Resources Pool of Industry Veterans with Expertise Across Sectors",
    },
    {
      id: 6,
      image: Sl6,
      title:
        "Global Community Integration Through Physical, Digital & Knowledge Network",
    },
  ];

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    organization_name: "",
    reason: "",
    interest_area: "",
  });

  const [loading, setLoading] = useState(false);
  const toast = useToast();

  // Handle form input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(
        "https://ichamber.org/api/v1/submit-enquiry",
        formData,
        {
          headers: {
            "Api-Key": "XOHOU5GSQ6AJiD997rFIQ4Pz+LLIjqCg1Lrf8Up5",
            "Content-Type": "application/json",
          },
        }
      );

      toast({
        title: "Success!",
        description: "Your enquiry has been submitted successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      setFormData({
        name: "",
        email: "",
        organization_name: "",
        reason: "",
        interest_area: "",
      });
    } catch (error) {
      toast({
        title: "Error!",
        description: error.response?.data?.message || "Something went wrong.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box bg="#f6f6f6">
      <Box
        w="100vw"
        minH="900px"
        backgroundImage={BGImage}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        backgroundPosition="center"
        display="flex"
        alignItems="center"
        justifyContent="center"
        px={{ base: "20px", md: "30px" }}
        py={{ base: "100px", md: "130px" }}
      >
        <Box
          w="100%"
          maxW="1200px"
          borderRadius="2xl"
          p={{ base: "20px", md: "30px" }}
        >
          <Grid
            templateColumns={{ base: "1fr", md: "1fr 1fr" }}
            gap={8}
            alignItems="center"
          >
            {/* Left Side - Centered Text */}
            <GridItem
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box
                textAlign={{ base: "center", md: "left" }}
                px={{ base: 2, md: 8 }}
              >
                <Text
                  fontSize={{ base: "xl", md: "4xl" }}
                  fontWeight="bold"
                  color="white"
                >
                  Join India Chamber for Transformation of the Future.
                </Text>
                <Text
                  mt={4}
                  fontSize={{ base: "sm", md: "lg" }}
                  color="white"
                  maxW={{ base: "100%", md: "500px" }}
                >
                  As businesses adapt to an unprecedented rate of global change,
                  India Chamber membership provides different means to navigate
                  this change and prosper by transforming themselves and the
                  world.
                </Text>
              </Box>
            </GridItem>

            {/* Right Side - Styled Form */}
            <GridItem>
              <Box
                bg="white"
                p={{ base: "15px", md: "20px" }}
                borderRadius="2xl"
              >
                <Box
                  bg="#E8F0FE"
                  p={{ base: "20px", md: "30px" }}
                  borderRadius="2xl"
                  boxShadow="md"
                >
                  <Text
                    fontSize={{ base: "md", md: "lg" }}
                    fontWeight="bold"
                    textAlign="center"
                    mb={4}
                    color="gray.700"
                  >
                    Fill the below form to join India Chamber
                  </Text>
                  <form onSubmit={handleSubmit}>
                    <VStack spacing={4}>
                      <FormControl>
                        <FormLabel fontSize={{ base: "xs", md: "sm" }}>
                          Your Name
                        </FormLabel>
                        <Input
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          placeholder="Enter your name"
                          bg="white"
                          size={{ base: "sm", md: "md" }}
                          borderRadius="64px"
                        />
                      </FormControl>

                      <FormControl>
                        <FormLabel fontSize={{ base: "xs", md: "sm" }}>
                          Your E-Mail
                        </FormLabel>
                        <Input
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          placeholder="Enter your email"
                          bg="white"
                          size={{ base: "sm", md: "md" }}
                          borderRadius="64px"
                        />
                      </FormControl>

                      <FormControl>
                        <FormLabel fontSize={{ base: "xs", md: "sm" }}>
                          Your Organization Name
                        </FormLabel>
                        <Input
                          name="organization_name"
                          value={formData.organization_name}
                          onChange={handleChange}
                          placeholder="Enter your organization"
                          bg="white"
                          size={{ base: "sm", md: "md" }}
                          borderRadius="64px"
                        />
                      </FormControl>

                      <FormControl>
                        <FormLabel fontSize={{ base: "xs", md: "sm" }}>
                          Reason to Join India Chamber
                        </FormLabel>
                        <Input
                          name="reason"
                          value={formData.reason}
                          onChange={handleChange}
                          placeholder="Enter your reason"
                          bg="white"
                          size={{ base: "sm", md: "md" }}
                          borderRadius="64px"
                        />
                      </FormControl>

                      <FormControl>
                        <FormLabel fontSize={{ base: "xs", md: "sm" }}>
                          Interest Area
                        </FormLabel>
                        <Input
                          name="interest_area"
                          value={formData.interest_area}
                          onChange={handleChange}
                          placeholder="Enter your interest area"
                          bg="white"
                          size={{ base: "sm", md: "md" }}
                          borderRadius="64px"
                        />
                      </FormControl>

                      <Flex width="100%" alignItems="flex-start">
                        <Button
                          type="submit"
                          colorScheme="blue"
                          w={{ base: "full", md: "235px" }}
                          mt={4}
                          fontSize={{ base: "sm", md: "md" }}
                          borderRadius="42px"
                          isLoading={loading}
                        >
                          SUBMIT
                        </Button>
                      </Flex>
                    </VStack>
                  </form>
                </Box>
              </Box>
            </GridItem>
          </Grid>
        </Box>
      </Box>

      <Box padding={{base: "30px 10px", md: "80px 50px"}}>
        <Box
          backgroundImage={BG2}
          backgroundSize="cover"
          backgroundPosition="center"
          textAlign="center"
          py="60px"
          px="30px"
        >
          <Text fontSize="40px" fontWeight="500" color="white" mb={8}>
            Simplify Your Workflow: Discover Ease Of Business Tools
          </Text>

          <Grid
            templateColumns={{ base: "1fr", md: "repeat(4, 1fr)" }}
            gap={6}
            maxW="1200px"
            mx="auto"
          >
            {[
              {
                letter: "E",
                title: "Engaging",
                desc: "Engaging The Community",
              },
              {
                letter: "A",
                title: "Access",
                desc: "Providing Access To The Market Human Resource Market Technology",
              },
              {
                letter: "S",
                title: "Solutions",
                desc: "Offering Sustainable Solutions To The Emerging Needs Of Communities",
              },
              {
                letter: "E",
                title: "Energizing",
                desc: "Energizing Global Business Ecosystem",
              },
            ].map((item, index) => (
              <Flex justify="center" width="100%" mt={10}>
                <Box width="250px">
                  <Flex
                    justify="center"
                    align="center"
                    border="4px solid white"
                    // w="60px"
                    // h="60px"
                    width="100%"
                    mx="auto"
                    mb={4}
                    fontSize="50px"
                    fontWeight="bold"
                    color="white"
                  >
                    {item.letter}
                  </Flex>
                  <Box
                    key={index}
                    p={6}
                    width="250px"
                    height="220px"
                    bg="rgba(0, 0, 0, 0.6)"
                    borderRadius="10px"
                    textAlign="center"
                    color="white"
                    backdropFilter="blur(10px)"
                  >
                    <Text fontSize="30px" fontWeight="500">
                      {item.title}
                    </Text>
                    <Text fontSize="22px" mt={2}>
                      {item.desc}
                    </Text>
                  </Box>
                </Box>
              </Flex>
            ))}
          </Grid>
        </Box>
      </Box>

      <Text
        fontSize={{ base: "24px", md: "40px" }}
        fontWeight="bold"
        textAlign="center"
        mb={8}
      >
        What We Offer To Our Partners
      </Text>

      {/* box 1 */}
      <Box p={{ base: 4, md: "50px" }}>
        {/* Section Container */}
        <Flex
          direction={{ base: "column", md: "row" }}
          alignItems="center"
          justifyContent="space-between"
          bg="white"
          flexWrap="wrap"
          pb={{ base: "20px", md: "0px" }}
        >
          {/* Left - Full Width Image */}
          <Box
            flex="1"
            textAlign="center"
            mb={{ base: 4, md: 0 }}
            display="flex"
            justifyContent={{ base: "center", md: "flex-start" }}
          >
            <Image
              src={S1}
              alt="Strategic Networking"
              borderRadius="md"
              w={{ base: "100%", md: "80%" }}
              ml={{ base: "auto", md: "0" }}
            />
          </Box>

          {/* Right - Centered Text */}
          <Flex
            flex="1"
            justify="center"
            align="center"
            textAlign={{ base: "center", md: "left" }}
            px={{ base: 4, md: 8 }}
          >
            <VStack
              align={{ base: "center", md: "start" }}
              spacing={4}
              maxW={{ base: "100%", md: "500px" }}
            >
              <Text fontSize={{ base: "2xl", md: "40px" }} fontWeight="bold">
                Strategic Networking
              </Text>

              <UnorderedList
                fontSize={{ base: "md", md: "22px" }}
                color="gray.600"
                spacing={3}
              >
                <ListItem>
                  Enlisting the support of people who can help achieve strategic
                  business goals.
                </ListItem>
                <ListItem>
                  Major emphasis to be laid on projecting a positive image of
                  business, assisting industry to identify and execute capacity,
                  innovation, etc.
                </ListItem>
              </UnorderedList>
            </VStack>
          </Flex>
        </Flex>
      </Box>

      {/* box 2 */}
      <Box p={{ base: 4, md: "50px" }}>
        {/* Section Container */}
        <Flex
          direction={{ base: "column-reverse", md: "row" }}
          alignItems="center"
          justifyContent="space-between"
          bg="white"
          w="100%"
          pb={{ base: "20px", md: "0px" }}
        >
          {/* Left - Text Content */}
          <Flex
            flex="1"
            justify="center"
            align="center"
            textAlign={{ base: "center", md: "left" }}
            px={{ base: 4, md: 8 }}
          >
            <VStack
              align={{ base: "center", md: "start" }}
              spacing={4}
              maxW="500px"
            >
              <Text fontSize={{ base: "2xl", md: "40px" }} fontWeight="bold">
                Digital Networking
              </Text>

              <UnorderedList
                fontSize={{ base: "md", md: "22px" }}
                color="gray.600"
                spacing={3}
              >
                <ListItem>
                  Enabling the community with state-of-the-art technology and
                  digital platform to engage.
                </ListItem>
                <ListItem>
                  Seamless integration of members and business worldwide for
                  global business expansion.
                </ListItem>
              </UnorderedList>
            </VStack>
          </Flex>

          {/* Right - Image Touching Right End on Desktop and on Top in Mobile */}
          <Box
            flex="1"
            display="flex"
            justifyContent={{ base: "center", md: "flex-end" }}
            mb={{ base: 4, md: 0 }}
          >
            <Image
              src={S2}
              alt="Strategic Networking"
              borderRadius="md"
              w={{ base: "100%", md: "80%" }}
              objectFit="cover"
            />
          </Box>
        </Flex>
      </Box>

      {/* box 3 */}

      <Box p={{ base: 4, md: "50px" }}>
        {/* Section Container */}
        <Flex
          direction={{ base: "column", md: "row" }}
          alignItems="center"
          justifyContent="space-evenly"
          bg="white"
          pb={{ base: "20px", md: "0px" }}
        >
          <Box
            flex="1"
            display="flex"
            justifyContent={{ base: "center", md: "flex-start" }}
          >
            <Image
              src={S3}
              alt="Operational Networking"
              borderRadius="md"
              w={{ base: "100%", md: "80%" }}
              mx={{ base: "auto", md: "0" }}
            />
          </Box>

          {/* Right - Centered Text */}
          <Flex
            flex="1"
            justify="center"
            align="center"
            textAlign={{ base: "center", md: "left" }}
            px={{ base: 4, md: 8 }}
          >
            <VStack
              align={{ base: "center", md: "start" }}
              spacing={4}
              maxW="500px"
            >
              <Text fontSize={{ base: "2xl", md: "40px" }} fontWeight="bold">
                Operational Networking
              </Text>

              <UnorderedList
                fontSize={{ base: "md", md: "22px" }}
                color="gray.600"
                spacing={3}
              >
                <ListItem>
                  Building relationships inside each industry/sector so that
                  everybody is a stakeholder.
                </ListItem>
                <ListItem>
                  Promote co-operation with counterpart organizations.
                </ListItem>
              </UnorderedList>
            </VStack>
          </Flex>
        </Flex>
      </Box>

      {/* box 4 */}

      <Box p={{ base: 4, md: "50px" }}>
        {/* Section Container */}
        <Flex
          direction={{ base: "column-reverse", md: "row" }}
          alignItems="center"
          justifyContent="space-between"
          bg="white"
          w="100%"
          pb={{ base: "20px", md: "0px" }}
        >
          {/* Left - Text Content */}
          <Flex
            flex="1"
            justify="center"
            align="center"
            textAlign={{ base: "center", md: "left" }}
            px={{ base: 4, md: 8 }}
          >
            <VStack
              align={{ base: "center", md: "start" }}
              spacing={4}
              maxW="500px"
            >
              <Text fontSize={{ base: "2xl", md: "40px" }} fontWeight="bold">
              Physical Networking
              </Text>

              <UnorderedList
                fontSize={{ base: "md", md: "22px" }}
                color="gray.600"
                spacing={3}
              >
                <ListItem>
                  Building worldwide physical infrastructure for engaging and
                  enabling the community.
                </ListItem>
                <ListItem>
                  High on business and cultural hubs-work as a global business
                  for local members and trade facilitation centers.
                </ListItem>
              </UnorderedList>
            </VStack>
          </Flex>

          {/* Right - Image Touching Right End on Desktop and on Top in Mobile */}
          <Box
            flex="1"
            display="flex"
            justifyContent={{ base: "center", md: "flex-end" }}
            mb={{ base: 4, md: 0 }}
          >
            <Image
              src={S4}
              alt="Strategic Networking"
              borderRadius="md"
              w={{ base: "100%", md: "80%" }}
              objectFit="cover"
            />
          </Box>
        </Flex>
      </Box>
      

      {/* box 5 */}
      <Box p={{ base: 4, md: "50px" }}>
        {/* Section Container */}
        <Flex
          direction={{ base: "column", md: "row" }}
          alignItems="center"
          justifyContent="space-evenly"
          bg="white"
          pb={{ base: "20px", md: "0px" }}
        >
          <Box
            flex="1"
            display="flex"
            justifyContent={{ base: "center", md: "flex-start" }}
          >
            <Image
              src={S5}
              alt="Operational Networking"
              borderRadius="md"
              w={{ base: "100%", md: "80%" }}
              mx={{ base: "auto", md: "0" }}
            />
          </Box>

          {/* Right - Centered Text */}
          <Flex
            flex="1"
            justify="center"
            align="center"
            textAlign={{ base: "center", md: "left" }}
            px={{ base: 4, md: 8 }}
          >
            <VStack
              align={{ base: "center", md: "start" }}
              spacing={4}
              maxW="500px"
            >
              <Text fontSize={{ base: "2xl", md: "40px" }} fontWeight="bold">
                Personal Networking
              </Text>

              <UnorderedList
                fontSize={{ base: "md", md: "22px" }}
                color="gray.600"
                spacing={3}
              >
                <ListItem>
                  Great avenue to make some initial contacts with professionals
                  who are doing business in the region and foresee the upcoming
                  challenges.
                </ListItem>
                <ListItem>
                  Platform to meet global business and political leader.
                </ListItem>
              </UnorderedList>
            </VStack>
          </Flex>
        </Flex>
      </Box>

      <Text
        fontSize={{ base: "24px", md: "40px" }}
        fontWeight="bold"
        textAlign="center"
        mb={8}
      >
        Our Global Roles
      </Text>
      <Box p={{base: "20px 5px", md:"20px 50px"}}>
        <Box
          position="relative"
          width="100%"
          height="450px"
          overflow="hidden"
          mb={{base: "20px", md:"80px"}}
          borderRadius={"20px"}
        >
          <Swiper
            modules={[Navigation]}
            navigation
            spaceBetween={0}
            slidesPerView={1}
            style={{ width: "100%", height: "100%" }}
          >
            {slidesData.map((slide) => (
              <SwiperSlide key={slide.id}>
                <Box position="relative" width="100%" height="100%">
                  {/* Main Image */}
                  <Image
                    src={slide.image}
                    alt={slide.title}
                    width="100%"
                    height="100%"
                    objectFit="cover"
                  />
                  <Image
                    src={Shadow1}
                    alt="Shadow Overlay"
                    position="absolute"
                    bottom="0"
                    left="0"
                    width="100%"
                    height="100%"
                    objectFit="cover"
                    zIndex="2"
                  />
                  {/* Title on Image */}
                  <Text
                    position="absolute"
                    bottom="40px"
                    left="50%"
                    transform="translateX(-50%)"
                    color="white"
                    fontSize="35px"
                    fontWeight="400"
                    zIndex="3"
                    textAlign="center"
                    width="90%"
                  >
                    {slide.title}
                  </Text>
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Box>

      <Box position="relative">
        <Box
          position="relative"
          py={12}
          // bgImage={`url(${BG3})`}
          bgColor='#242424'
          bgSize="cover"
          bgPosition="center"
          zIndex={0}
          
        >
          {/* Shadow Box */}
          <Box
            position="absolute"
            top={0}
            left={0}
            width="100%"
            height="100%"
            zIndex={1}
          >
            <Image
              src={Shadow}
              alt="Shadow"
              width="100%"
              height="100%"
              objectFit="cover"
              opacity={1}
            />
          </Box>

          {/* Section Title */}
          <Text
            textAlign="center"
            fontSize="40px"
            fontWeight="500"
            color="white"
            mb={8}
            position="relative"
            zIndex={2}
            textShadow="2px 2px 10px rgba(0, 0, 0, 0.7)"
          >
            How Our Actions Are Transforming Business Ecosystem
          </Text>

          {/* First Section - Facilitation */}
          <Flex margin={{base: "10px", md:"10px 50px"}}>
            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems="center"
              justifyContent="center"
              maxW="1450px"
              w="100%"
              mx="auto"
              mb={8}
              p={6}
              bg="white"
              boxShadow="lg"
              borderRadius="40px 0 40px 0"
              zIndex={3}
              position="relative"
            >
              {/* Image Section */}
              <Flex
                flex="1"
                alignItems={{ base: "center", md: "flex-start" }}
                justifyContent={{ base: "center", md: "flex-start" }}
              >
                <Image
                  src={img1}
                  alt="Facilitation"
                  w={{ base: "100%", md: "75%" }}
                  h="100%"
                  objectFit="cover"
                  borderRadius="40px 0 40px 0"
                />
              </Flex>
              {/* Content Section */}
              <Box flex="1" p={6}>
                <VStack align="start" spacing={4}>
                  <Text fontSize="35px" fontWeight="bold">
                    Facilitation
                  </Text>
                  <Text fontSize="22px" color="gray.600">
                    Provide a comprehensive framework for Indian trade and
                    business worldwide by building global linkages, alliances,
                    and partnerships that will help scale their businesses with
                    the following functions:-
                  </Text>
                  <UnorderedList fontSize="22px" color="gray.600" spacing={2}>
                    <ListItem>Global Business Connect</ListItem>
                    <ListItem>Investment promotions</ListItem>
                    <ListItem>Entrepreneurship framework</ListItem>
                    <ListItem>Launch pads and market place</ListItem>
                  </UnorderedList>
                </VStack>
              </Box>
            </Flex>
          </Flex>

          <Flex margin={"10px 50px"}>
            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems="center"
              justifyContent="space-between"
              maxW="1450px"
              w="100%"
              mx="auto"
              mb={8}
              p={6}
              bg="white"
              boxShadow="lg"
              borderRadius="0 40px 0px 40px"
              zIndex={3}
              position="relative"
              overflow="hidden"
            >
              {/* Content Section */}
              <Box
                flex="1"
                p={6}
                maxW={{ base: "100%", md: "600px" }}
                zIndex={2}
              >
                <VStack align="start" spacing={4}>
                  <Text fontSize="35px" fontWeight="bold">
                    Integration
                  </Text>
                  <Text fontSize="22px" color="gray.600">
                    Help to build a robust entrepreneurial ecosystem by
                    integrating all the stakeholders with the help of the
                    following functions:
                  </Text>
                  <UnorderedList fontSize="22px" color="gray.600" spacing={2}>
                    <ListItem>Global Community Connect</ListItem>
                    <ListItem>Global Collaborations</ListItem>
                    <ListItem>Global Engagement forums</ListItem>
                    <ListItem>Business ecosystem builder</ListItem>
                  </UnorderedList>
                </VStack>
              </Box>

              {/* Image Section */}
              <Box
                flex="1"
                position="relative"
                display="flex"
                justifyContent={{ base: "center", md: "flex-end" }}
                alignItems="center"
                w="100%"
                h="100%"
                order={{ base: -1, md: 1 }}
              >
                <Image
                  src={img2}
                  alt="Integration"
                  w={{ base: "100%", md: "100px%" }}
                  nsures
                  image
                  fills
                  the
                  container
                  maxW={{ base: "350px", md: "65%" }}
                  h="auto"
                  objectFit="cover"
                  borderRadius="0 40px 0px 40px"
                  right={{ md: "0" }}
                  top="0"
                />
              </Box>
            </Flex>
          </Flex>
          {/* Third Section - Knowledge Creation */}
          <Flex margin={"10px 50px"}>
            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems="center"
              justifyContent="center"
              maxW="1450px"
              w="100%"
              mx="auto"
              mb={8}
              p={6}
              bg="white"
              boxShadow="lg"
              borderRadius="40px 0 40px 0"
              zIndex={3}
              position="relative"
            >
              {/* Image Section */}
              <Flex
                flex="1"
                alignItems={{ base: "center", md: "flex-start" }}
                justifyContent={{ base: "center", md: "flex-start" }}
              >
                <Image
                  src={img3}
                  alt="Facilitation"
                  w={{ base: "100%", md: "75%" }}
                  h="100%"
                  objectFit="cover"
                  borderRadius="40px 0 40px 0"
                />
              </Flex>

              {/* Content Section */}
              <Box flex="1" p={6}>
                <VStack align="start" spacing={4}>
                  <Text fontSize="35px" fontWeight="bold">
                    Knowledge Creation
                  </Text>
                  <Text fontSize="22px" color="gray.600">
                    A think tank drives strategic knowledge to guide government
                    and industry in tackling complex challenges. Actions to
                    promote this include:
                  </Text>
                  <UnorderedList fontSize="22px" color="gray.600" spacing={2}>
                    <ListItem>
                      Strategic policy advocacy on the foundation of stable
                      policy framework and business regulations
                    </ListItem>
                    <ListItem>
                    Expert advice and information to the industry to address sectoral specific challenges
                    </ListItem>
                    <ListItem>
                      Business policy dialogues, initiatives between policy
                      makers, business leaders, corporate heads, and various
                      government institutions
                    </ListItem>
                  </UnorderedList>
                </VStack>
              </Box>
            </Flex>
          </Flex>
        </Box>
      </Box>

      <Box py={12} px={6} bg="#f6f6f6">
        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          spacing={6}
          maxW="1200px"
          mx="auto"
        >
          {/* Left Card */}
          <Box bg="white" boxShadow="lg" p={6} borderRadius="lg">
            <VStack align="start" spacing={4}>
              <Flex justify="center" width="100%">
                <Text fontSize="30px" fontWeight="bold" textAlign="center">
                  Other Membership Benefits
                </Text>
              </Flex>

              <UnorderedList fontSize="22px" color="gray.600" spacing={3}>
                <ListItem>
                  Cross-sector expertise and access to industry value chains
                </ListItem>
                <ListItem>A global multi-industry solution hub</ListItem>
                <ListItem>
                  A neutral convening and collaboration platform
                </ListItem>
                <ListItem>
                  A pre-competitive space to discuss and work on key issues
                </ListItem>
                <ListItem>
                  Access to global and local platforms for project
                  implementation, financing, events, and advocacy
                </ListItem>
                <ListItem>
                  Regular updates on the latest thinking and trend
                </ListItem>
                <ListItem>
                  Access to business delegations, global market
                </ListItem>
              </UnorderedList>
            </VStack>
          </Box>

          {/* Right Card */}
          <Box bg="white" boxShadow="lg" p={6} borderRadius="lg">
            <VStack align="start" spacing={4}>
              <Text fontSize="30px" fontWeight="bold">
                What we ask of our Strategic Partners
              </Text>
              <UnorderedList fontSize="22px" color="gray.600" spacing={3}>
                <ListItem>
                  Demonstrate leadership – CEOs or executive representation of
                  their company as a Council Member, with active participation
                  at our annual Council / leadership Meetings, at a minimum.
                </ListItem>
                <ListItem>
                  Contribute your expertise and leadership and select the forum
                  most relevant for your business.
                </ListItem>
                <ListItem>
                  Active participation mentoring startups and young
                  entrepreneurs.
                </ListItem>
                <ListItem>
                  Actively support the objectives of India Chamber, approved
                  annually by Forum leaderships and members.
                </ListItem>
              </UnorderedList>
            </VStack>
          </Box>
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default JoinPage;
