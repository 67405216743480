import React from "react";
import { Box, Text, UnorderedList, ListItem } from "@chakra-ui/react";
import BGImage from '../../assets/BG-2.png'
import { motion } from "framer-motion";

const TermsAndConditions = () => {
  const MotionBox = motion(Box);

  return (
    <Box
      backgroundColor=" #F6F6F6"
      fontFamily="Akkurat"
      lineHeight="35.86px"
    >
      <MotionBox
        w="100%"
        h="580px"
        bgGradient="linear(to-b, blue.800, blue.500)"
        color="white"
        textAlign="center"
        p={8}
        backgroundImage={BGImage}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        backgroundPosition="center"
        display="flex"
        flexDirection="column"
        alignItems={{ base: "center", md: "flex-start" }}
        justifyContent="center"
        pl={{ base: "0px", md: "10%" }}
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        <Box textAlign={{ base: "left", md: "left" }} w="100%" ml={{base:"10%", md:"50%"}} >
          <Text
            fontSize={{ base: "32px", md: "60px" }}
            fontWeight="bold"
            fontFamily="Akkurat-bold"
            letterSpacing="wide"
            lineHeight={{ base: "40px", md: "70px" }}
          >
            TERMS
          </Text>

          <Box >
            <Text
              fontSize={{ base: "32px", md: "64px" }}
              fontWeight="600"
              fontFamily="Akkurat-bold"
              lineHeight={{ base: "40px", md: "70px" }}
            >
              <Text
                as="span"
                fontSize={{ base: "22px", md: "40px" }}
                fontWeight="bold"
                color="#001F5C"
                mr="10px"
                display="inline-block"
                fontFamily="Akkurat-bold"
              >
                AND
              </Text>
              CONDITIONS
            </Text>
          </Box>
        </Box>
      </MotionBox>

      <Box p="50px">
        <Text fontSize="22px" fontWeight="400" textAlign="justify" mb={4} >
          Welcome to our website.
        </Text>
        <Text fontSize="22px" fontWeight="400" textAlign="justify" mb={4} color="#515151">
          If you continue to browse and use this website you are agreeing to comply with and be
          bound by the following terms and conditions of use, which together with our privacy
          policy govern India Chamber’s relationship with you in relation to this website.
        </Text>
        <Text fontSize="22px" fontWeight="400" textAlign="justify" mb={4} color="#515151">
          The term ‘India Chamber’, ‘us’ or ‘we’ refers to the owner of the website whose
          registered office is in Delhi, India. The term ‘you’ refers to the user or viewer of our
          website.
        </Text>
        <Text fontSize="22px" fontWeight="400" textAlign="justify" mb={4} color="#515151">
          Specifically, we use Personal Data for the following purposes:
        </Text>

        <Text
          fontSize="30px"
          fontWeight="400"
          fontFamily="Akkurat-Bold"
          lineHeight="39.9px"
          mb={4}
          color="black"
        >
          Terms of Use
        </Text>
        <UnorderedList spacing={3} ml={5} fontSize="22px" fontWeight="400" textAlign="justify" color="#515151">
          <ListItem>
            The content of the pages of this website is for your general information and use
            only. It is subject to change without notice.
          </ListItem>
          <ListItem>
            Neither we nor any third parties provide any warranty or guarantee as to the accuracy,
            timeliness, performance, completeness or suitability of the information and materials
            found or offered on this website for any particular purpose.
          </ListItem>
          <ListItem>
            You acknowledge that such information and materials may contain inaccuracies or errors
            and we expressly exclude liability for any such inaccuracies or errors to the fullest
            extent permitted by law.
          </ListItem>
          <ListItem>To facilitate, operate, and provide our Services;</ListItem>
          <ListItem>
            Your use of any information or materials on this website is entirely at your own risk,
            for which we shall not be liable.
          </ListItem>
          <ListItem>
            It shall be your own responsibility to ensure that any products, services or
            information available through this website meet your specific requirements.
          </ListItem>
          <ListItem>
            This website contains material which is owned by or licensed to us. This material
            includes, but is not limited to, the design, layout, look, appearance, and graphics.
            Reproduction is prohibited other than in accordance with the copyright notice, which
            forms part of these terms and conditions.
          </ListItem>
          <ListItem>
            All trademarks reproduced in this website, which are not the property of, or licensed
            to the operator, are acknowledged on the website.
          </ListItem>
          <ListItem>
            Unauthorized use of this website may give rise to a claim for damages and/or be a
            criminal offence.
          </ListItem>
          <ListItem>
            From time to time this website may also include links to other websites. These links
            are provided for your convenience to provide further information.
          </ListItem>
          <ListItem>
            They do not signify that we endorse the website(s). We have no responsibility for the
            content of the linked website(s).
          </ListItem>
          <ListItem>
            You may not create a link to this website from another website or document without
            India Chamber’s prior written consent.
          </ListItem>
          <ListItem>
            To create aggregated statistical data, inferred non-personal data or anonymized or
            pseudonymized data (rendered non-personal), which we or our business partners may use
            to provide and improve our respective services.
          </ListItem>
        </UnorderedList>

        <Text
          fontSize="30px"
          fontWeight="400"
          fontFamily="Akkurat-Bold"
          lineHeight="39.9px"
          mt={8}
          mb={4}
          color="black"
        >
          Refund Policy
        </Text>
        <Text fontSize="22px" fontWeight="400" textAlign="justify" mb={4} color="#515151">
          Payment once collected are not refundable. However, payments can be adjusted against
          future events in ‘select cases’ subject to Management Approval. ‘Select case’ criteria
          will differ from event to event.
        </Text>
        <Text fontSize="22px" fontWeight="400" textAlign="justify" color="#515151">
          <Text color="#515151">*For membership: – Payment once collected are not refundable.</Text>
        </Text>

        <Text
          fontSize="30px"
          fontWeight="400"
          fontFamily="Akkurat-Bold"
          lineHeight="39.9px"
          mt={8}
          mb={4}
          color="black"
        >
          Disclaimer
        </Text>
        <Text fontSize="22px" fontWeight="400" textAlign="justify" mb={4} color="#515151">
          The Websites may contain features including blog, report and newsletter services, event,
          issue and community pages, member areas and other applications that may be available
          from time to time.
        </Text>
        <Text fontSize="22px" fontWeight="400" textAlign="justify" color="#515151" mb={4}>
          Any person that accesses or uses the Websites agrees to be bound by the terms and
          conditions of these Terms of Use. These Terms of Use contain conditions, disclaimers and
          other provisions, some of which limit the Forum’s liability.
        </Text>
        <UnorderedList>
          <ListItem>
            <Text fontSize="22px" fontWeight="400" textAlign="justify" color="#515151">
              Any person that does not agree or comply with these Terms of Use should not access or
              use the Websites.
            </Text>
          </ListItem>
        </UnorderedList>
      </Box>
    </Box>
  );
};

export default TermsAndConditions;
