import React, { useState } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import "./SSslider.css";

const SingleSideSlider = ({ data, title, description }) => {
    const [activeIndex, setActiveIndex] = useState(2);
    const totalImages = data.length;

    const nextSlide = () => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % totalImages);
    };

    const prevSlide = () => {
        setActiveIndex((prevIndex) => (prevIndex - 1 + totalImages) % totalImages);
    };

    return (
        <div className="SS-carousel-container">
            <button className="SS-nav-button SS-left" onClick={prevSlide}>
                <ChevronLeft size={40} />
            </button>

            <div className="SS-carousel">
                <div className="SS-image-wrapper">
                    {data.map((item, index) => {
                        let relativeIndex = (index - activeIndex + totalImages) % totalImages;

                        // Show only 4 images (active + 3 previous)
                        if (relativeIndex > 3) return null;

                        let zIndex = 1;
                        let scale = 0.8;
                        // let opacity = 0.6;
                        let height = "250px";
                        let translateX = "-275px"; 

                        if (relativeIndex === 0) {
                            zIndex = 4;
                            scale = 1.2;
                            // opacity = 1;
                            height = "280px";
                            translateX = "0"; 
                        } else if (relativeIndex === 1) {
                            zIndex = 3;
                            scale = 1;
                            // opacity = 0.8;
                            height = "270px";
                            translateX = "-100px";
                        } else if (relativeIndex === 2) {
                            zIndex = 2;
                            scale = 0.9;
                            // opacity = 0.7;
                            height = "260px";
                            translateX = "-200px";
                        }

                        return (
                            <div
                                key={index}
                                className="SS-carousel-item"
                                style={{
                                    transform: `translateX(${translateX}) scale(${scale})`,
                                    zIndex,
                                    // opacity,
                                    height,
                                }}
                            >
                                <img src={item.src} alt={item.title} />
                                <div className="SS-overlay">{item.title}</div>
                            </div>
                        );
                    })}
                </div>
            </div>

            <button className="SS-nav-button SS-right" onClick={nextSlide}>
                <ChevronRight size={40} />
            </button>

            <div className="SS-text-content">
                <h2>{title}</h2>
                <p>{description}</p>
            </div>
        </div>
    );
};

export default SingleSideSlider;
