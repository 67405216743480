import React from "react";
import { Box, Image, Text, Flex, useBreakpointValue } from "@chakra-ui/react";
import BGImage from "../../assets/BG-2.png";
import Eco from "../../assets/overview-gaushala/eco.png";
import Idea from "../../assets/overview-gaushala/idea.png";
import Center from "../../assets/overview-gaushala/center.png";
import Brand from "../../assets/overview-gaushala/brand.png";
import Innovation from "../../assets/overview-gaushala/innovation.png";
import Enterpreneur from "../../assets/overview-gaushala/enter.png";
import Laptop from "../../assets/overview-gaushala/laptop.png";
import Globe from "../../assets/overview-gaushala/gloabe.png";
import { motion } from "framer-motion";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import Mission from "../../assets/overview-gaushala/mission.png";

const Overview = () => {
  const MotionBox = motion(Box);
  const slidesPerView = useBreakpointValue({ base: 1, md: 3 });

  const slides = [Brand, Innovation, Enterpreneur, Laptop, Globe];

  return (
    <Box bg="#f6f6f6">
      <MotionBox
        w="100%"
        h={{ base: "400px", md: "500px", lg: "580px" }}
        bgGradient="linear(to-b, blue.800, blue.500)"
        color="white"
        textAlign="center"
        p={{ base: 4, md: 6, lg: 8 }}
        backgroundImage={BGImage}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        backgroundPosition="center"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        <Box mt={{ base: "150px", md: "150px", lg: "200px" }} textAlign={{ base: "center", md: "right" }}>
          <Text
            fontSize={{ base: "32px", md: "48px", lg: "64px" }}
            fontWeight="400"
            fontFamily="Akkurat-bold"
            mb={{ base: "-20px", md: "-30px", lg: "-50px" }}
            pr={{ base: "0", md: "15%", lg: "20%" }}
            textAlign={{ base: "center", md: "right" }}
          >
            OVERVIEW
          </Text>
        </Box>
      </MotionBox>

      {/* Additional Content Section */}
      <Flex
        direction="column"
        textAlign="left"
        p={4}
        bg="white"
        width="100%"
        backgroundColor="#ffffff"
        padding="30px 50px"
        mt='50px'
      >
        <Text fontSize="32px" fontWeight="bold" mb={4}>
          Platform for building a unique Indian Global Partnership
        </Text>
        <Text fontSize="20px" textAlign="left" mb="30px">
          India Chamber is the Chamber of Commerce of India engaged in advancing
          Indian business and economic interest by building enabling business
          ecosystem.
        </Text>
        <Text fontSize="20px" textAlign="left">
          India Chamber is a unique Indian global partnership:
          Various institutional initiatives working seamlessly for building
          global architect for enhancement  of Indian business, trade and
          commerce and extending sustainable solutions for shared prosperity
          among Indian Community worldwide.
        </Text>
      </Flex>

      <Flex
        direction={{ base: "column", md: "row" }}
        textAlign="left"
        p={8}
        width="100%"
        align="center"
        gap={4}
      >
        <Flex
          direction={{ base: "column", md: "row" }}
          textAlign="left"
          p={8}
          width="100%"
          alignItems="flex-start"
          gap={4}
        >
          <Flex
            flexDir={{ base: "column", md: "row" }}
            alignItems={"center"}
            gap="100px"
          >
            <Flex flex="1" justifyContent="center" alignItems="center">
              <Image src={Mission} alt="Our Mission" width="100%" />
            </Flex>
            <Flex direction="column" flex="1">
              <Text
                fontWeight={400}
                fontSize={{ base: "28px", md: "40px" }}
                fontFamily="Akkurat-bold"
                mb={4}
              >
                Our Mission
              </Text>
              <Text fontSize={{ base: "16px", md: "22px" }} color="#515151" fontFamily="Akkurat">
                The Mission ponders upon the most critical issues of present
                generation and provide its platform to multi-stakeholders-
                Business leaders, Cultural leaders, Policymakers, Innovators,
                thought leaders, intellectuals, academia etc. to construct
                developmental agenda and drive and shape the future of India and
                its global community. Mission strives on the idea of
                ‘Peoplecentric’ design and its impact on their businesses and
                growth.
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <Flex
        direction={{ base: "column", md: "row" }}
        gap={{ base: 4, md: 20 }}
        p={{ base: 4, md: 8 }}
        minH='300px'
        justifyContent="center"
        alignItems="center"
      >
        <Box
          width={{ base: "100%", md: "380px" }}
          height="270px"
          bg="white"
          boxShadow="md"
          p={6}
          borderRadius="lg"
        >
          <Flex direction="column" justifyContent="space-around">
            <Flex alignItems="center" mb={4}>
              <Image src={Eco} w='65px' h='70px' mr='10%' />
              <Text fontWeight="bold" fontSize="lg" p='10px' textAlign='center'>
                Global Business Ecosystem
              </Text>
            </Flex>
            <Text color="gray.600" textAlign="justify" fontSize={"15px"}>
              India Chamber is engaged in advancing the business and economic interest of India by building and enabling global business architecture
            </Text>
          </Flex>
        </Box>

        <Box
          width={{ base: "100%", md: "380px" }}
          height="270px"
          bg="white"
          boxShadow="md"
          p={6}
          borderRadius="lg"
        >
          <Flex direction="column" justifyContent="space-around">
            <Flex alignItems="center" mb={4}>
              <Image src={Idea} w='70px' h='80px' mr='25%' />
              <Text fontWeight="bold" fontSize="lg" p='10px' textAlign='center'>
                Act As <br /> A Catalyst
              </Text>
            </Flex>
            <Text color="gray.600" textAlign="justify" fontSize="15px">
              In bringing about growth and development of Indian Industry, while identifying and strengthening industry's role in the economic development of the country
            </Text>
          </Flex>
        </Box>

        <Box
          width={{ base: "100%", md: "380px" }}
          height="270px"
          bg="white"
          boxShadow="md"
          p={6}
          borderRadius="lg"
        >
          <Flex
            direction="column"
            justifyContent={"space-around"}
          >
            <Flex alignItems="center" mb={4}>
              <Image src={Center} w='90px' h='70px' mr='30%' />
              <Text fontWeight="bold" fontSize="lg" p='auto' textAlign='center'>
                Business <br /> Centric
              </Text>
            </Flex>
            <Text fontSize="15px" color="gray.600" textAlign="justify">
              Building sustainable network of Indian community globally and engaging them for growth, development and shared prosperity. A partnership between Government, academia and private industry – that influences the best practices.
            </Text>
          </Flex>
        </Box>
      </Flex>

      {/* Strategy Goals Section */}
      <Text fontSize="32px" fontWeight="bold" mb={4} textAlign="center">
        Our Strategy Goals
      </Text>
      <Box p={4} bg="black" color="white">
        <Box position="relative">
          <Swiper
            modules={[Navigation]}
            navigation={true}
            slidesPerView={slidesPerView}
            spaceBetween={10}
            style={{ padding: "20px" }}
          >
            {slides.map((src, index) => (
              <SwiperSlide key={index}>
                <Image src={src} alt={`Slide ${index}`} w="100%" h="350px" objectFit="contain" />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Box>
    </Box>
  );
};

export default Overview;
