import React from "react";
import "./Footer.css";
import Logo from "../../assets/footer/logo.png";
import FB from "../../assets/footer/Facebook.png";
import Insta from "../../assets/footer/Instagram.png";
import Linkedin from "../../assets/footer/Linkedin.png";
import Twitter from "../../assets/footer/Twitter.png";
import { Button, Image } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-logo">
          <img src={Logo} onClick={() => navigate('/')} alt="India Chamber Logo" style={{ cursor: 'pointer' }}/>
          {/* <p>India Chamber of Business and Commerce</p> */}
        </div>
        <div className="footer-links">
          <ul>
            <li><Link to="/">Home</Link>
            </li>
            <li><Link to="/press-release">Press Release</Link>
            </li>
            <li><Link to="/partnerships">Partnerships</Link>
            </li>
            <li><Link to="/contact-us">Contact us</Link>
            </li>
          </ul>
          <ul>
            <li>
              <Link to="/india-investor-hub">India Investor Hub</Link>
            </li>
            <li>
              <Link to="/health">India Health Dialogue</Link>
            </li>
            <li>
              <Link to="/rural-economic-forum">Rural Economic Forum</Link>
            </li>
            <li>
              <Link to="/governace-and-leadership">Governance and Leadership</Link>
            </li>
            <li><Link to="/join">Membership Benefits</Link>
            </li>
          </ul>
          <ul>
            <li><Link to="/project-one-future">Project One Future</Link>
            </li>
            <li><Link to="/smart-sustainable-gaushala">Smart Sustainable Gaushala (SSG)</Link>
            </li>
            <li>
              <Link to="/collaberation-and-appreciation">Collaborations and Appreciations</Link>
            </li>
            <li>
              <Link to="/terms-and-conditions">Terms and Conditions</Link>
            </li>
            <li><Link to="/privacy-policy">Privacy Policy</Link>
            </li>
          </ul>
        </div>
        <div className="footer-social">
          <div className="social-icons">
            <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer">
              <Image src={FB} alt="Facebook" />
            </a>
            <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer">
              <Image src={Insta} alt="Instagram" />
            </a>
            <a href="https://in.linkedin.com/" target="_blank" rel="noopener noreferrer">
              <Image src={Linkedin} alt="LinkedIn" />
            </a>
            <a href="https://x.com/IChamberOrg" target="_blank" rel="noopener noreferrer" color="blue">
              <Image src={Twitter} alt="Twitter" />
            </a>
          </div>

          <Button
            as="a"
            href="/brochure.pdf"
            target="_blank"
            rel="noopener noreferrer"
            width="100%"
            mt={4}
            className="download-button">
            Download Brochure
          </Button>

        </div>
      </div>
      <div
        className="footer-bottom"
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          flexWrap: "wrap",
          padding: "10px",
          textAlign: "center",
          position: "relative",
        }}>
        <p style={{ margin: 0, flex: "1", textAlign: "center" }}>© 2024 by India Chamber</p>
        <a
          href="https://digitalcrew.co.in/industries"
          target="_blank"
          rel="noopener noreferrer"
          className="footer-bottom-text"
          style={{
            position: "absolute",
            right: "10px",
            whiteSpace: "nowrap",
            textDecoration: "none",
            color: "white",
            cursor: "pointer",
          }}>
          Developed by DigitalCrew
        </a>
      </div>

    </footer>
  );
};

export default Footer;
