import React from "react";
import { Box, Image, Text, Flex } from "@chakra-ui/react";
import BGImage from "../../assets/BG-2.png";
import { motion } from "framer-motion";

const Entrepreneurship = () => {
  const MotionBox = motion(Box);
  return (
    <Box bg="#f6f6f6">
      <MotionBox
        w="100%"
        h="580px"
        bgGradient="linear(to-b, blue.800, blue.500)"
        color="white"
        textAlign="center"
        p={8}
        backgroundImage={BGImage}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        backgroundPosition="center"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        <Box textAlign="left">
  <Box
    mt="200px"
    textAlign={{ base: "center", md: "left" }}
    ml={{ base: "auto", md: "400px", lg: "700px" }} // Ensuring proper centering
    maxWidth="90%" // Optional to prevent it from stretching too much
  >
    <Text
      fontSize={{ base: "32px", md: "48px", lg: "64px" }}
      fontWeight="400"
      fontFamily="Akkurat-bold"
      mb={{ base: "-20px", md: "-30px", lg: "-50px" }}
    >
      BUILDING
    </Text>
    <Box mt={{ base: "10px", md: "15px", lg: "20px" }}>
      <Text
        fontSize={{ base: "32px", md: "48px", lg: "64px" }}
        fontWeight="400"
        fontFamily="Akkurat-bold"
        textAlign={{ base: "center", md: "left" }}
      >
        <Text
          as="span"
          fontSize={{ base: "24px", md: "32px", lg: "40px" }}
          fontWeight="400"
          color="black"
        >
          BRAND
        </Text>
        INDIA
      </Text>
    </Box>
  </Box>
</Box>

      </MotionBox>
      <Box p="50px">
        <Text
          fontSize="30px"
          fontWeight="400"
          fontFamily="Akkurat-Bold"
          lineHeight="39.9px"
          mt={8}
          mb={4}
          color="black"
        >
          ON THE AGENDA
        </Text>
        <Text fontSize="22px" fontWeight="400" textAlign="justify" mb={4}>
          Showcase India’s business & economic prowess globally, positioning
          India as an Economic Gateway to the world.
        </Text>
        <Text fontSize="22px" fontWeight="400" textAlign="justify" mb={4}>
          Positioning India as the upcoming global hub for businesses,
          catapulting our ‘Make in India’ initiative especially in critical and
          strategic areas through extensive global partnerships and
          collaborations.
        </Text>
        <Text fontSize="22px" fontWeight="400" textAlign="justify" mb={4}>
          Building & synergizing global linkages involving Indian Diaspora for
          deep convergence and penetration into global markets.
        </Text>
        <Text fontSize="22px" fontWeight="400" textAlign="justify" mb={4}>
          Empowering Indian industries by bringing a focus on improving
          competitiveness, efficiency & productivity and adapting global
          standards for seamless Integration and profiling.
        </Text>
        <Text fontSize="22px" fontWeight="400" textAlign="justify" mb={4}>
          Extensively engage with uni/multi-lateral agencies for proactive
          investments into creating sustaining infrastructure.
        </Text>
        <Text fontSize="22px" fontWeight="400" textAlign="justify" mb={4}>
          Engage with Government agencies to improve the global Ease of Doing
          Business Rankings.
        </Text>
      </Box>
    </Box>
  );
};

export default Entrepreneurship;
