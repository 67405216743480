import React from "react";
import { Box, Image, Text, Flex } from "@chakra-ui/react";
import BGImage from "../../assets/BG-2.png";
import { motion } from "framer-motion";

const Innovation = () => {
  const MotionBox = motion(Box);
  return (
    <Box bg="#f6f6f6">
      <MotionBox
        w="100%"
        h="580px"
        bgGradient="linear(to-b, blue.800, blue.500)"
        color="white"
        textAlign="center"
        p={8}
        backgroundImage={BGImage}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        backgroundPosition="center"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        <Box mt={{ base: "200px", md: "200px", lg: "200px" }} textAlign={{ base: "center", md: "left" }} ml={{ base: "0px", md: "45%", lg: "50%" }}>
          <Text fontSize={{ base: "23px", md: "48px", lg: "64px" }} fontWeight="400" fontFamily="Akkurat-bold" mb={{ base: "-20px", md: "-30px", lg: "-50px" }}>
          ENTREPRENEURSHIP
          </Text>
          <Box mt={{ base: "10px", md: "15px", lg: "20px" }}>
          </Box>
        </Box>
      </MotionBox>
      <Box p="50px">
        <Text
          fontSize="30px"
          fontWeight="400"
          fontFamily="Akkurat-Bold"
          lineHeight="39.9px"
          mt={8}
          mb={4}
          color="black"
        >
          ON THE AGENDA
        </Text>
        <Text fontSize="22px" fontWeight="400" textAlign="justify" mb={4}>
          Fostering Entrepreneurship and spirit of enterprise by building an effective alliance between Government - Industry - Academia and undertaking initiatives to assist young professionals in setting up new start-ups, promoting next-gen leadership.
        </Text>

        <Text fontSize="22px" fontWeight="400" textAlign="justify" mb={4}>
          Actions oriented towards facilitating entrepreneurs scale their business and unlock the value by building a robust entrepreneurial ecosystem in India. Entrepreneurship development undercuts all major sector of the economy and aids global competitiveness, nurture global thought leadership and accelerate growth through structured interventions at every stage of the life cycle of business.
        </Text>
      </Box>
    </Box>
  );
};

export default Innovation;
