import React from "react";
import { Box, Flex, Heading, Image, Link, SimpleGrid, Spacer, Text } from "@chakra-ui/react";
import BG from "../../assets/home/strategic_agenda_bg.png";
import logoWhite from "../../assets/home/logo.png";
import "./section3.css";
import { useNavigate } from "react-router-dom";

const Section3 = () => {
    const navigate = useNavigate();
    const options = [
        { label: "Building Brand India", path: "/building-brand-india" },
        { label: "Innovation", path: "/innovation" },
        { label: "Entrepreneurship", path: "/entrepreneurship" },
        { label: "Global Trade and Commerce", path: "/global-trade-and-commerce" },
        { label: "Investments", path: "/investment" },
        { label: "Sustainability", path: "/sustainability" },
    ];

    return (
        <Box className="S3-container" backgroundImage={BG}>
            <Flex className="S3-header">
                <Heading className="S3-strategic-agenda">Strategic Agenda</Heading>
                <Image src={logoWhite} alt="India Chamber Logo" className="S3-logo" />
            </Flex>

            <SimpleGrid
                columns={{ base: 1, sm: 3, md: 6 }}
                className="S3-section-grid">
                {options.map((option, index) => (
                    <Box
                        key={index}
                        className="S3-section-card">
                        <Text className="S3-section-title">
                            {option.label}</Text>
                        <Spacer />
                        <Link
                            onClick={() => navigate(option.path)}
                            className="S3-discover-link"
                            fontFamily={"Akkurat"}
                        >Discover →</Link>
                    </Box>
                ))}
            </SimpleGrid>
        </Box>
    );
};

export default Section3;
