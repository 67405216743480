import React, { useEffect, useRef, useState } from "react";
import { Box, Image, Button, Flex, Heading, Text, SimpleGrid } from "@chakra-ui/react";
import buildingImg from "../../assets/home/IC_img.png";
import healthIcon from "../../assets/home/healthCare_icon.png";
import techIcon from "../../assets/home/technology_icon.png";
import ruralIcon from "../../assets/home/rural_icon.png";
import healthBG from "../../assets/home/healthCare_bg.png";
import techBG from "../../assets/home/technology_bg.png";
import ruralBG from "../../assets/home/rural_bg.png";
import "./section4.css";
import { useNavigate } from "react-router-dom";

const Section4 = () => {
    const navigate = useNavigate();
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                    } else {
                        setIsVisible(false);
                    }
                });
            },
            { threshold: 0.3 }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    return (
        <Box className="S4-container" ref={sectionRef}>
            <Flex className={`S4-main-banner ${isVisible ? 'slide-in-left' : ''}`}>
                <Box className="S4-image-container">
                    <Image src={buildingImg} alt="Building" className="S4-banner-image" />
                </Box>
                <Box className="S4-text-container">
                    <Heading size="lg" className="S4-banner-heading">
                        Ease with India Chamber
                    </Heading>
                    <Text className="S4-banner-text">
                        We work on strategies to advance “Brand India,” growth, and opportunity for our members.
                        Our purpose comes to life through our four core values: Service, Excellence, Integrity,
                        and Partnership.
                    </Text>
                    <Button className="S4-join-button" onClick={() => navigate('/join')}>Join India Chamber</Button>
                </Box>
            </Flex>

            <Text
                fontSize="40px"
                fontWeight="600"
                ml="10%"
                mb={{ base: "0px", md: "-8%" }}
            >Discover</Text>
            <SimpleGrid columns={[1, 2, 3]} spacing={6} className="S4-cards-container">
                <Box className={`S4-card ${isVisible ? 'slide-in-left' : ''}`} style={{ backgroundImage: `url(${healthBG})` }}>
                    <Image src={healthIcon} alt="Health Care" className="S4-card-icon" />
                    <Text className="S4-card-title">Health Care</Text>
                </Box>
                <Box className={`S4-card ${isVisible ? 'slide-in-left' : ''}`} style={{ backgroundImage: `url(${techBG})` }}>
                    <Image src={techIcon} alt="Technology" className="S4-card-icon" />
                    <Text className="S4-card-title">Technology</Text>
                </Box>
                <Box className={`S4-card ${isVisible ? 'slide-in-left' : ''}`} style={{ backgroundImage: `url(${ruralBG})` }}>
                    <Image src={ruralIcon} alt="Rural Development" className="S4-card-icon" />
                    <Text className="S4-card-title">Rural Development</Text>
                </Box>
            </SimpleGrid>
        </Box>
    );
};

export default Section4;
