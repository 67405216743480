import React from "react";
import {
  Box,
  Text,
  UnorderedList,
  ListItem,
  Flex,
  VStack,
  Image,
} from "@chakra-ui/react";
import BGImage from "../../assets/BG-2.png";
import { motion } from "framer-motion";
import Watch from "../../assets/hub/watch.png";
import Digital from "../../assets/hub/digital.png";
import Marketing from "../../assets/hub/marketing.png";
import Regulatory from "../../assets/hub/regulatory.png";
import Reform from "../../assets/hub/reform.png";
import Risk from "../../assets/hub/risk.png";
import Network from "../../assets/hub/network.png";
import Eco from "../../assets/hub/ecosystem.png";

const TermsAndConditions = () => {
  const MotionBox = motion(Box);

  return (
    <Box backgroundColor=" #F6F6F6" fontFamily="Akkurat" lineHeight="35.86px">
      <MotionBox
        w="100%"
        h="580px"
        bgGradient="linear(to-b, blue.800, blue.500)"
        color="white"
        textAlign="center"
        p={8}
        backgroundImage={BGImage}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        backgroundPosition="center"
        display="flex"
        flexDirection="column"
        alignItems={{ base: "center", md: "flex-start" }}
        justifyContent="center"
        pl={{ base: "0px", md: "10%" }}
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        <Box
          textAlign={{ base: "left", md: "left" }}
          w="100%"
          ml={{ base: "10%", md: "50%" }}
        >
          <Text
            fontSize={{ base: "40px", md: "60px" }}
            fontWeight="bold"
            fontFamily="Akkurat-bold"
            letterSpacing="wide"
            lineHeight={{ base: "40px", md: "70px" }}
          >
            INDIA INVESTOR
          </Text>

          <Box>
            <Text
              fontSize={{ base: "28px", md: "40px" }}
              fontWeight="600"
              fontFamily="Akkurat-bold"
              lineHeight={{ base: "40px", md: "70px" }}
            >
              <Text
                as="span"
                fontSize={{ base: "28px", md: "40px" }}
                fontWeight="bold"
                color="#001F5C"
                mr="10px"
                display="inline-block"
                fontFamily="Akkurat-bold"
              >
                HUB
              </Text>
              (IIH)
            </Text>
          </Box>
        </Box>
      </MotionBox>

      <Flex
        direction="column"
        textAlign="left"
        p={{ base: "25px", md: "50px 50px" }}
        bg="white"
        width="100%"
        m={{ base: "20px 0", md: "50px 0 20px 0px" }}
        fontFamily={"Akkurat"}
      >
        <Text
          fontSize={{ base: "24px", md: "35px" }}
          fontWeight="bold"
          mb={4}
          fontFamily={"Akkurat-bold"}
        >
          A Global Platform for Facilitating Investments in India
        </Text>
        <Text
          fontSize={{ base: "16px", md: "22px" }}
          textAlign="left"
          color="#515151"
        >
          The India Investment Hub (IIH) is a global platform curated by the
          India Chamber to attract and facilitate investments in India. As a
          collaborative business engagement platform, IIH connects global
          investors, venture capitalists, private equity firms, and financial
          institutions with investment opportunities across diverse sectors of
          the Indian economy. IIH plays a vital role in supporting the India
          Chamber’s initiatives to build sustainable business models and scale
          enterprises by fostering investments and value creation.
        </Text>
      </Flex>

      <Flex
        w="100vw"
        h="auto"
        bg="#F4F4F4"
        flexDir="column"
        position="relative"
      >
        <Text
          fontSize={{ base: "25px", md: "40px" }}
          p={{ base: "20px", md: "50px" }}
          fontFamily="Akkurat-bold"
        >
          Key Objectives & Services
        </Text>

        {/* Box 1 */}
        <Flex
          w="100vw"
          flexDir={{ base: "column", md: "row" }}
          align="center"
          justify="center"
          mb="50px"
          bg="white"
          h={{ base: "auto", md: "365px" }}
          p={{ base: "20px", md: "0px" }}
        >
          <Box display="flex" justifyContent={{ base: "center", md: "flex-start" }}>
            <Image
              src={Watch}
              alt="Investment Image"
              w={{ base: "90%", md: "614px" }}
              h={{ base: "auto", md: "365px" }}
              mr={{ base: "0px", md: "30px" }}
            />
          </Box>
          <Box
            w="2px"
            h="80%"
            bg="#00000033"
            display={{ base: "none", md: "block" }}
            mr="50px"
          />
          <Box
            flex="1"
            ml={{ base: "0px", md: "50px" }}
            mt={{ base: "20px", md: "0px" }}
            display="flex"
            alignItems="center"
          >
            <VStack align="start" spacing={4} maxW="700px">
              <Text
                fontSize={{ base: "24px", md: "30px" }}
                fontFamily="Akkurat-bold"
                fontWeight="bold"
                textAlign={{ base: "center", md: "left" }}
              >
                Promoting India as a Prime Investment Destination
              </Text>
              <Text
                fontSize={{ base: "16px", md: "22px" }}
                color="#515151"
                fontFamily="Akkurat"
                textAlign={{ base: "center", md: "left" }}
              >
                IIH highlights India’s economic strengths, high-growth sectors, and
                investor-friendly policies to position the country as a leading global
                investment hub.
              </Text>
            </VStack>
          </Box>
        </Flex>

        {/* Box 2 */}
        <Flex
          w="100vw"
          flexDir={{ base: "column", md: "row" }}
          align="center"
          justify="center"
          position="relative"
          mb="50px"
          bg="white"
          h={{ base: "auto", md: "365px" }}
          p={{ base: "20px", md: "0px" }}>
          <Box
            flex="1"
            position="relative"
            display="flex"
            justifyContent="center"
            w={{ base: "100%", md: "614px" }}
            h={{ base: "auto", md: "365px" }}
            mb={{ base: "20px", md: "0px" }}>
            <Image
              src={Digital}
              alt="Investment Image"
              w={{ base: "90%", md: "100%" }}
              h="100%"
              objectFit="cover" />
          </Box>
          <Box
            position="absolute"
            left="50%"
            top="10%"
            transform="translateX(-50%)"
            w="2px"
            h="80%"
            bg="#00000033"
            display={{ base: "none", md: "block" }} />
          <Box
            flex="1"
            p={{ base: 4, md: 6 }}
            display="flex"
            alignItems="center"
            justifyContent="center"
            textAlign={{ base: "center", md: "left" }}>
            <VStack align="start" spacing={4} maxW="700px" ml={{ base: "0", md: "15px" }}>
              <Text fontSize={{ base: "24px", md: "30px" }} fontFamily="Akkurat-bold" fontWeight="bold">
                Comprehensive Investment Resources
              </Text>
              <Text fontSize={{ base: "16px", md: "22px" }} color="#515151" fontFamily="Akkurat">
                The platform provides in-depth information on:
              </Text>
              <UnorderedList spacing={3}>
                <ListItem fontSize={{ base: "16px", md: "22px" }} color="#515151" fontFamily="Akkurat">
                  Investment opportunities in key sectors such as technology, manufacturing, healthcare, infrastructure, and more.
                </ListItem>
                <ListItem fontSize={{ base: "16px", md: "22px" }} color="#515151" fontFamily="Akkurat">
                  Regulatory frameworks, incentives, and policy support for investors.
                </ListItem>
              </UnorderedList>
            </VStack>
          </Box>
        </Flex>

        {/* Box 3 */}
        <Flex
          w="100vw"
          flexDir={{ base: "column", md: "row" }}
          align="center"
          justify="center"
          mb="50px"
          bg="white"
          h={{ base: "auto", md: "365px" }}
          p={{ base: "20px", md: "0px" }}>
          <Box
            display="flex"
            justifyContent="center"
            w={{ base: "100%", md: "614px" }}
            h={{ base: "auto", md: "365px" }}
            mb={{ base: "20px", md: "0px" }}>
            <Image
              src={Marketing}
              alt="Investment Image"
              w={{ base: "90%", md: "100%" }}
              h="100%"
              objectFit="cover" />
          </Box>
          <Box
            w="2px"
            h="80%"
            bg="#00000033"
            display={{ base: "none", md: "block" }}
            mr="50px" />
          <Box
            flex="1"
            display="flex"
            alignItems="center"
            textAlign={{ base: "center", md: "left" }}
            ml={{ base: "0px", md: "50px" }}>
            <VStack align="start" spacing={4} maxW="700px">
              <Text fontSize={{ base: "24px", md: "30px" }} fontFamily="Akkurat-bold" fontWeight="bold">
                Investment Facilitation & Matchmaking
              </Text>
              <Text fontSize={{ base: "16px", md: "22px" }} color="#515151" fontFamily="Akkurat">
                IIH actively connects investors with local partners, suppliers, and customers,
                assisting in matchmaking between investors and specific projects or businesses.
              </Text>
            </VStack>
          </Box>
        </Flex>

        {/* Box 4 */}
        <Flex
          w="100vw"
          flexDir={{ base: "column", md: "row" }}
          align="center"
          justify="center"
          position="relative"
          mb="50px"
          bg="white"
          h={{ base: "auto", md: "365px" }}
          p={{ base: "20px", md: "0px" }}>
          <Box
            flex="1"
            display="flex"
            justifyContent="center"
            alignItems="center"
            w={{ base: "100%", md: "614px" }}
            h={{ base: "auto", md: "365px" }}
            mb={{ base: "20px", md: "0px" }}>
            <Image
              src={Regulatory}
              alt="Regulatory Compliance"
              w={{ base: "90%", md: "100%" }}
              h="100%"
              objectFit="cover" />
          </Box>
          <Box
            position="absolute"
            left="50%"
            top="10%"
            transform="translateX(-50%)"
            w="2px"
            h="80%"
            bg="#00000033"
            display={{ base: "none", md: "block" }} />
          <Box
            flex="1"
            display="flex"
            alignItems="center"
            textAlign={{ base: "center", md: "left" }}
            pl={{ base: "0px", md: "50px" }}>
            <VStack align="start" spacing={4} maxW="700px">
              <Text fontSize={{ base: "24px", md: "30px" }} fontFamily="Akkurat-bold" fontWeight="bold">
                Regulatory & Compliance Support
              </Text>
              <Text fontSize={{ base: "16px", md: "22px" }} color="#515151" fontFamily="Akkurat">
                The platform provides guidance on legal and regulatory matters, including:
              </Text>
              <UnorderedList spacing={2}>
                <ListItem fontSize={{ base: "16px", md: "22px" }} color="#515151" fontFamily="Akkurat">
                  Assistance with permits, licenses, and approvals.
                </ListItem>
                <ListItem fontSize={{ base: "16px", md: "22px" }} color="#515151" fontFamily="Akkurat">
                  Compliance advisory for foreign investors.
                </ListItem>
              </UnorderedList>
            </VStack>
          </Box>
        </Flex>

        {/* Box 5 */}
        <Flex
          w="100vw"
          flexDir={{ base: "column", md: "row" }}
          align="center"
          justify="center"
          mb="50px"
          bg="white"
          h={{ base: "auto", md: "365px" }}
          p={{ base: "20px", md: "0px" }}>
          <Box
            flex="1"
            display="flex"
            justifyContent="center"
            alignItems="center"
            w={{ base: "100%", md: "614px" }}
            h={{ base: "auto", md: "365px" }}
            mb={{ base: "20px", md: "0px" }}>
            <Image
              src={Reform}
              alt="Investment Image"
              w={{ base: "90%", md: "100%" }}
              h="100%"
              objectFit="cover" />
          </Box>
          <Box
            w="2px"
            h="80%"
            bg="#00000033"
            display={{ base: "none", md: "block" }}
            mx={{ md: "50px" }} />
          <Box
            flex="1"
            display="flex"
            alignItems="center"
            textAlign={{ base: "center", md: "left" }}
            pl={{ base: "0px", md: "50px" }}>
            <VStack align="start" spacing={4} maxW="700px">
              <Text fontSize={{ base: "24px", md: "30px" }} fontFamily="Akkurat-bold" fontWeight="bold">
                Policy Advocacy & Business Reforms
              </Text>
              <Text fontSize={{ base: "16px", md: "22px" }} color="#515151" fontFamily="Akkurat">
                IIH collaborates with government bodies and regulatory agencies to advocate for policy
                reforms that enhance the ease of doing business and encourage foreign investments.
              </Text>
            </VStack>
          </Box>
        </Flex>

        {/* Box 6 */}
        <Flex
          w="100vw"
          flexDir={{ base: "column", md: "row" }}
          align="center"
          justify="center"
          position="relative"
          mb="50px"
          bg="white"
          h={{ base: "auto", md: "365px" }}
          p={{ base: "20px", md: "0px" }}>
          <Box
            flex="1"
            display="flex"
            alignItems="center"
            textAlign={{ base: "center", md: "left" }}
            pl={{ base: "0px", md: "50px" }}
            pr={{ base: "0px", md: "50px" }}
            py={{ base: "20px", md: "0px" }}>
            <VStack align="start" spacing={4} maxW="700px">
              <Text fontSize={{ base: "24px", md: "30px" }} fontFamily="Akkurat-bold" fontWeight="bold">
                Investor Services & Risk Assessment
              </Text>
              <Text fontSize={{ base: "16px", md: "22px" }} color="#515151" fontFamily="Akkurat">
                To help investors make informed decisions, IIH offers:
              </Text>
              <UnorderedList spacing={2}>
                <ListItem fontSize={{ base: "16px", md: "22px" }} color="#515151" fontFamily="Akkurat">
                  Market research and investment insights.
                </ListItem>
                <ListItem fontSize={{ base: "16px", md: "22px" }} color="#515151" fontFamily="Akkurat">
                  Due diligence and risk assessment services.
                </ListItem>
              </UnorderedList>
            </VStack>
          </Box>
          <Box
            position="absolute"
            left="50%"
            top="10%"
            transform="translateX(-50%)"
            w="2px"
            h="80%"
            bg="#00000033"
            display={{ base: "none", md: "block" }} />
          <Box
            flex="1"
            display="flex"
            justifyContent="center"
            alignItems="center"
            w={{ base: "90%", md: "614px" }}
            h={{ base: "auto", md: "365px" }}
            py={{ base: "20px", md: "0px" }}>
            <Image
              src={Risk}
              alt="Investment Image"
              w="100%"
              h="100%"
              objectFit="cover"
            />
          </Box>
        </Flex>

        {/* Box 7 */}
        <Flex
          w="100vw"
          flexDir={{ base: "column", md: "row" }}
          align="center"
          justify="center"
          mb="50px"
          bg="white"
          h={{ base: "auto", md: "365px" }}
          p={{ base: "20px", md: "0px" }}>
          <Box
            flex="1"
            display="flex"
            justifyContent="center"
            alignItems="center"
            w={{ base: "90%", md: "614px" }}
            h={{ base: "auto", md: "365px" }}
            py={{ base: "20px", md: "0px" }}>
            <Image
              src={Network}
              alt="Investment Image"
              w="100%"
              h="100%"
              objectFit="cover" />
          </Box>
          <Box
            w="2px"
            h="80%"
            bg="#00000033"
            display={{ base: "none", md: "block" }}
            mx="30px" />
          <Box
            flex="1"
            display="flex"
            alignItems="center"
            textAlign={{ base: "center", md: "left" }}
            pl={{ base: "0px", md: "50px" }}
            pr={{ base: "0px", md: "50px" }}
            py={{ base: "20px", md: "0px" }}>
            <VStack align="start" spacing={4} maxW="700px">
              <Text fontSize={{ base: "24px", md: "30px" }} fontFamily="Akkurat-bold" fontWeight="bold">
                Networking & Investment Events
              </Text>
              <Text fontSize={{ base: "16px", md: "22px" }} color="#515151" fontFamily="Akkurat">
                IIH organizes conferences, seminars, and networking events, connecting investors with industry experts and policymakers to explore investment opportunities.
              </Text>
            </VStack>
          </Box>
        </Flex>

        {/* Box 8 */}
        <Flex
          w="100vw"
          flexDir={{ base: "column", md: "row" }}
          align="center"
          justify="center"
          position="relative"
          mb="50px"
          bg="white"
          h={{ base: "auto", md: "365px" }}
          p={{ base: "20px", md: "0px" }}>
          <Box
            flex="1"
            display="flex"
            alignItems="center"
            justifyContent={{ base: "center", md: "flex-end" }}
            px={{ base: "20px", md: "36px" }}
            py={{ base: "20px", md: "0px" }}
            textAlign={{ base: "center", md: "left" }}>
            <VStack align="start" spacing={4} maxW="700px">
              <Text fontSize={{ base: "24px", md: "30px" }} fontFamily="Akkurat-bold" fontWeight="bold">
                Building a Strong Investment Ecosystem
              </Text>
              <Text fontSize={{ base: "18px", md: "22px" }} color="#515151" fontFamily="Akkurat">
                By facilitating seamless investments, IIH aims to support both
                the public and private sectors, ensuring financial solutions for
                India’s emerging needs.
              </Text>
              <Text fontSize={{ base: "18px", md: "22px" }} color="#515151" fontFamily="Akkurat">
                IIH aligns with the vision of making India self-reliant (Atmanirbhar)
                and a globally competitive advanced economy.
              </Text>
            </VStack>
          </Box>
          <Box
            position="absolute"
            left="50%"
            top="10%"
            transform="translateX(-50%)"
            w="2px"
            h="80%"
            bg="#00000033"
            display={{ base: "none", md: "block" }} />
          <Box
            flex="1"
            display="flex"
            justifyContent={{ base: "center", md: "flex-end" }}
            w={{ base: "90%", md: "614px" }}
            h={{ base: "auto", md: "365px" }}
            py={{ base: "20px", md: "0px" }}
          >
            <Image
              src={Eco}
              alt="Investment Image"
              w="100%"
              h="100%"
              objectFit="cover"
            />
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

export default TermsAndConditions;
