import React from 'react';
import { Box, Image, Text, Flex } from '@chakra-ui/react';
import BGImage from '../../assets/home/REF_bg_img.png';
import REFlogo from "../../assets/REF/REF_logo.png";
import { motion } from 'framer-motion';
import OurEvents from './refEvents';
import PlatformPartners from './platformPartners';
import POFimage from "../../assets/REF/projectOneFuture.png"
import Future from "../../assets/REF/future.png"
import Supply from "../../assets/REF/supply.png"
import Innovation from "../../assets/REF/innovation.png"
import Cow from "../../assets/REF/cow.png"
import Growth from "../../assets/REF/growth.png"
import MultiImageSlider from '../multiSlider';
import "./REF2.css"

import img1 from "../../assets/REF-2/Img1.png";
import img2 from "../../assets/REF-2/Img2.png";
import img3 from "../../assets/REF-2/Img3.png";
import img4 from "../../assets/REF-2/Img4.png";
import img5 from "../../assets/REF-2/Img5.png";
import img6 from "../../assets/REF-2/Img6.png";

import eventImage1 from "../../assets/REF-2/event_img_1.png";
import eventImage2 from "../../assets/REF-2/event_img_2.png";
import eventImage3 from "../../assets/REF-2/event_img_3.png";
import eventImage4 from "../../assets/REF-2/event_img_4.png";
import eventImage5 from "../../assets/REF-2/event_img_5.png";
import { Link } from 'react-router-dom';

const REF = () => {
    const MotionBox = motion(Box);

    const sectors = [
        { id: 1, title: "Launchpad", image: img1 },
        { id: 2, title: "Future of Skilling", image: img2 },
        { id: 3, title: "Global Supply chain networks", image: img3 },
        { id: 4, title: "Sustainability and Resilience", image: img4 },
        { id: 5, title: "Policy Advocacy and knowledge creation", image: img5 },
        { id: 6, title: "Global Investments", image: img6 },
    ];

    const events = [
        { title: "EVENT ➜ AGENDA", image: null, span: "single" },
        { title: "New Disruptions: Innovations driving the future of Bharat", image: Innovation, span: "single" },
        { title: "Plenary Session: One Future - Driving Global Change", image: Future, span: "single" },
        { title: "SSG: Transforming Rural Business Ecosystem by leveraging Cow", image: Cow, span: "single" },
        { title: "Building Resilient Global Supply Networks", image: Supply, span: "single" },
        { title: "Financing the growth", image: Growth, span: "single" },
    ];

    const defaultEventsData = [
        {
            id: 1,
            title: "Industry- Academia -  Government Partnership Dialogue.",
            image: eventImage1,
        },
        {
            id: 2,
            title: "Engage with world recognized experts on rural innovations and economy.",
            image: eventImage2,
        },
        {
            id: 3,
            title: "Launchpad and Showcasing solutions that are going to drive the future of rural sector.",
            image: eventImage3,
        },
        {
            id: 4,
            title: "Understanding role of AI in driving the future of Rural Economy.",
            image: eventImage4,
        },
        {
            id: 5,
            title: "Unfolding sustainable business models for transforming rural economy.",
            image: eventImage5,
        },
    ];

    return (
        <Box>
            <MotionBox
                w="100%"
                h="580px"
                bgGradient="linear(to-b, blue.800, blue.500)"
                color="white"
                textAlign="center"
                pt={8}
                backgroundImage={BGImage}
                backgroundColor='#024B62'
                backgroundRepeat="no-repeat"
                backgroundSize="cover"
                backgroundPosition="center"
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
            />
            {/* section 1  */}
            <Box className="ref-s1-container" mt='-510px'>
                <Box className="s1-logo-section">
                    <Image src={REFlogo} className="s1-logo-image" />
                    <Box className="s1-title-section">
                        <Text className="s1-title1-text"
                            pt={{ base: "10%", md:"0" }}
                            >Rural <br /> Economic <br /> Forum</Text>
                        <Box
                            width="2px"
                            height="100%"
                            mx="16px"
                        />
                        <Text className="s1-subtitle-text"
                            style={{ borderLeft: '#FFFFFF 1px solid', paddingLeft: '30px', justifyContent: 'center' }}>ANNUAL <br /> GLOBAL <br /> SUMMIT <br /> 2025</Text>
                    </Box>
                </Box>


                <Box className="s1-mission-section">
                    <Text className="s1-title">
                        HIGHLIGHTS OF THE SUMMIT
                    </Text>
                    <Link to="/project-one-future">
                        <Image src={POFimage} />
                    </Link>
                </Box>
            </Box>

            <Box className="ref-S3-focusedSectorsWrapper">
                <Text className="ref-S3-sectionHeader">Focused Sectors</Text>
                <MultiImageSlider sectors={sectors} />
            </Box>

            <Box bg="black" p={{base:5, md:10}}>
                <Flex
                    gap={4}
                    flexWrap="wrap"
                    direction={{ base: "column", md: "row" }}
                >
                    {/* Left Column */}
                    <Flex direction="column" gap={4} flex={{ base: "unset", md: "1" }} width={{ base: "100%", md: "auto" }}>
                        {events.slice(0, 1).map((event, index) => (
                            <Box key={index} bg="gray.800" p={6} height="200px" display="flex" alignItems="center" justifyContent="center">
                                <Text color="white" fontFamily="Akkurat-bold" fontSize="22px">{event.title}</Text>
                            </Box>
                        ))}
                        {events.slice(1, 2).map((event, index) => (
                            <Box key={index} position="relative">
                                {event.image && (
                                    <>
                                        <Image
                                            src={event.image}
                                            alt={event.title}
                                            borderRadius="md"
                                            objectFit="cover"
                                            width="100%"
                                            height="100%"
                                            boxShadow="0px 4px 12px rgba(0, 0, 0, 0.6)"
                                        />
                                        <Text
                                            position="absolute"
                                            fontFamily="Akkurat"
                                            bottom="0"
                                            left="50%"
                                            transform="translateX(-50%)"
                                            color="white"
                                            fontWeight="400"
                                            fontSize="22px"
                                            bg="rgba(0, 0, 0, 0.6)"
                                            width="100%"
                                            textAlign="center"
                                            p={2}
                                        >
                                            {event.title}
                                        </Text>
                                    </>
                                )}
                            </Box>
                        ))}
                    </Flex>

                    {/* Middle Column */}
                    <Flex direction="column" gap={4} flex={{ base: "unset", md: "1" }} width={{ base: "100%", md: "auto" }}>
                        {events.slice(2, 3).map((event, index) => (
                            <Box key={index} position="relative">
                                {event.image && (
                                    <>
                                        <Image
                                            src={event.image}
                                            alt={event.title}
                                            borderRadius="md"
                                            objectFit="cover"
                                            width="100%"
                                            boxShadow="0px 4px 12px rgba(0, 0, 0, 0.6)"
                                        />
                                        <Text
                                            position="absolute"
                                            fontFamily="Akkurat"
                                            bottom="0"
                                            left="50%"
                                            transform="translateX(-50%)"
                                            color="white"
                                            fontSize="22px"
                                            bg="rgba(0, 0, 0, 0.6)"
                                            width="100%"
                                            textAlign="center"
                                            p={2}
                                        >
                                            {event.title}
                                        </Text>
                                    </>
                                )}
                            </Box>
                        ))}
                        {events.slice(3, 4).map((event, index) => (
                            <Box key={index} position="relative">
                                {event.image && (
                                    <>
                                        <Image
                                            src={event.image}
                                            alt={event.title}
                                            borderRadius="md"
                                            objectFit="cover"
                                            width="100%"
                                            boxShadow="0px 4px 12px rgba(0, 0, 0, 0.6)"
                                        />
                                        <Text
                                            fontFamily="Akkurat"
                                            position="absolute"
                                            bottom="0"
                                            left="50%"
                                            transform="translateX(-50%)"
                                            color="white"
                                            fontWeight="400"
                                            fontSize="22px"
                                            bg="rgba(0, 0, 0, 0.6)"
                                            width="100%"
                                            textAlign="center"
                                            p={2}
                                        >
                                            {event.title}
                                        </Text>
                                    </>
                                )}
                            </Box>
                        ))}
                    </Flex>

                    {/* Right Column */}
                    <Flex direction="column" gap={4} flex={{ base: "unset", md: "1" }} width={{ base: "100%", md: "auto" }}>
                        {events.slice(4, 5).map((event, index) => (
                            <Box key={index} position="relative">
                                {event.image && (
                                    <>
                                        <Image
                                            src={event.image}
                                            alt={event.title}
                                            borderRadius="md"
                                            objectFit="cover"
                                            width="100%"
                                            height="100%"
                                            boxShadow="0px 4px 12px rgba(0, 0, 0, 0.6)"
                                        />
                                        <Text
                                            position="absolute"
                                            bottom="0"
                                            fontFamily="Akkurat"
                                            left="50%"
                                            transform="translateX(-50%)"
                                            color="white"
                                            fontWeight="500"
                                            fontSize="22px"
                                            bg="rgba(0, 0, 0, 0.6)"
                                            width="100%"
                                            textAlign="center"
                                            p={2}
                                        >
                                            {event.title}
                                        </Text>
                                    </>
                                )}
                            </Box>
                        ))}
                        {events.slice(5, 6).map((event, index) => (
                            <Box key={index} position="relative" borderRadius="md">
                                {event.image && (
                                    <>
                                        <Image
                                            src={event.image}
                                            alt={event.title}
                                            borderRadius="md"
                                            objectFit="cover"
                                            width="100%"
                                            boxShadow="0px 4px 12px rgba(0, 0, 0, 0.6)"
                                        />
                                        <Text
                                            position="absolute"
                                            bottom="0"
                                            left="50%"
                                            transform="translateX(-50%)"
                                            color="white"
                                            fontWeight="400"
                                            fontSize="22px"
                                            fontFamily="Akkurat"
                                            bg="rgba(0, 0, 0, 0.6)"
                                            width="100%"
                                            textAlign="center"
                                            p={2}
                                        >
                                            {event.title}
                                        </Text>
                                    </>
                                )}
                            </Box>
                        ))}
                    </Flex>
                </Flex>
            </Box>


            {/* our events  */}
            <OurEvents data={defaultEventsData} />

            {/* Platform Partners */}
            <PlatformPartners />
        </Box>
    );
};

export default REF;
