import React from "react";
import { Box, Flex, Heading, SimpleGrid, Image, Spacer } from "@chakra-ui/react";


import StackHolder1 from "../../assets/eventImages/stackholder/img1.jpg"
import StackHolder2 from "../../assets/eventImages/stackholder/img2.jpg"
import StackHolder3 from "../../assets/eventImages/stackholder/img3.jpg"
import StackHolder4 from "../../assets/eventImages/stackholder/img4.jpg"
import StackHolder5 from "../../assets/eventImages/stackholder/img5.jpg"
import StackHolder6 from "../../assets/eventImages/stackholder/img6.jpg"

import Rural from "../../assets/eventImages/REF/img.jpg"
import Rural1 from "../../assets/eventImages/REF/img1.jpg"
import Rural2 from "../../assets/eventImages/REF/img2.jpg"
import Rural3 from "../../assets/eventImages/REF/img3.jpg"
import Rural4 from "../../assets/eventImages/REF/img4.jpg"
import Rural5 from "../../assets/eventImages/REF/img5.jpg"
import Rural6 from "../../assets/eventImages/REF/img6.jpg"
import Rural7 from "../../assets/eventImages/REF/img7.jpg"
import Rural8 from "../../assets/eventImages/REF/img8.jpg"
import Rural9 from "../../assets/eventImages/REF/img9.jpg"
import Rural10 from "../../assets/eventImages/REF/img10.jpg"
import Rural11 from "../../assets/eventImages/REF/img11.jpg"
import Rural12 from "../../assets/eventImages/REF/img12.jpg"
import Rural13 from "../../assets/eventImages/REF/img13.jpg"
import Rural14 from "../../assets/eventImages/REF/img14.jpg"

import CEO1 from "../../assets/eventImages/ceo-roundtable/img1.jpg"
import CEO2 from "../../assets/eventImages/ceo-roundtable/img2.jpg"
import CEO3 from "../../assets/eventImages/ceo-roundtable/img3.jpg"
import CEO4 from "../../assets/eventImages/ceo-roundtable/img4.jpg"
import CEO5 from "../../assets/eventImages/ceo-roundtable/img5.jpg"

import moaICMR1 from "../../assets/eventImages/moa-icmr/img.jpg"

import ihdSummit1 from "../../assets/eventImages/IHD/img.jpg"

import moaIMA1 from "../../assets/eventImages/mou-IMA/img.jpg"

import Dialogue1 from "../../assets/eventImages/manufactruing-dialogue/img1.jpg"
import Dialogue2 from "../../assets/eventImages/manufactruing-dialogue/img2.jpg"

import roundtable1 from "../../assets/eventImages/Gov-MH/img.jpg"

import foodProcessing1 from "../../assets/eventImages/food-processing/img.jpg"

import OurEvents from "./ourEvents";
import LArrow from "../../assets/home/left-arrow.png";
import "./section5.css";

const Section5 = () => {
  const defaultEventsData = [
    {
      id: 1,
      title: "Stakeholder Meeting on Smart and Sustainable Gaushalas",
      images: [StackHolder5, StackHolder2, StackHolder3, StackHolder4, StackHolder1, StackHolder6],
    },
    {
      id: 2,
      title: "Rural Economic Forum",
      images: [Rural,Rural1, Rural2, Rural3, Rural4, Rural5, Rural6, Rural7, Rural8, Rural9, Rural10, Rural11, Rural12, Rural13, Rural14],
    },
    {
      id: 3,
      title: "CEO Roundtable",
      images: [CEO4, CEO2, CEO3, CEO1, CEO5],
    },
    {
      id: 4,
      title: "MOA Signing between India Chamber and ICMR",
      images: [moaICMR1],
    },
    {
      id: 5,
      title: "IHD Summit",
      images: [ihdSummit1],
    },
    {
      id: 6,
      title: "MOU with IMA",
      images: [moaIMA1],
    },
    {
      id: 7,
      title: "Launch of India Manufacturing Dialogue",
      images: [Dialogue1, Dialogue2],
    },
    {
      id: 8,
      title: "Roundtable with Maharashtra Government",
      images: [roundtable1],
    },
    {
      id: 9,
      title: "Dialogue with Sh. Paras Paswan UM for Food Processing, GOI",
      images: [foodProcessing1]
    },
  ];
  

  const defaultMediaData = [
    { id: 1, url: "https://www.youtube.com/embed/QkY33OypD6g" },
    { id: 2, url: "https://www.youtube.com/embed/QldAjWa80f8" },
    { id: 3, url: "https://www.youtube.com/embed/zB1m9c2P5KA" },
  ];

  return (
    <Box className="S5-container">
      <OurEvents Data={defaultEventsData} />
      {/* Media Room Section */}
      <Box className="S5-mediaSection">
        <Flex align="center" justify="space-between" className="S5-mediaHeader">
          <Heading as="h2" className="heading">
            Media Room
          </Heading>
        </Flex>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={6} className="S5-mediaGrid">
          {defaultMediaData.map((item) => (
            <Box key={item.id} className="S5-mediaCard">
              <iframe
                width="100%"
                height="200px"
                src={item.url}
                title={`Media ${item.id}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </Box>
          ))}
        </SimpleGrid>

        <Flex align="center" className="S5-mediaArrows">
          <button className="S5-arrowButtond">
            <Image src={LArrow} />
          </button>
          <Spacer />
          <button className="S5-arrowButtond">
            <Image src={LArrow} transform="rotateY(-180deg)" />
          </button>
        </Flex>
      </Box>
    </Box>
  );
};

export default Section5;
