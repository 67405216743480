import React from "react";
import { Box, Text } from "@chakra-ui/react";
import BGImage from "../../assets/BG-2.png";
import { motion } from "framer-motion";

const Trade = () => {
  const MotionBox = motion(Box);
  return (
    <Box bg="#f6f6f6">
      <MotionBox
        w="100%"
        h="580px"
        bgGradient="linear(to-b, blue.800, blue.500)"
        color="white"
        textAlign="center"
        p={8}
        backgroundImage={BGImage}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        backgroundPosition="center"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
          <Box
                   mt={{ base: "200px", md: "200px", lg: "200px" }}
                   textAlign={{ base: "center", md: "left" }}
                   ml={{ base: "0px", md: "400px", lg: "700px" }}
                 >
                   <Text
                     fontSize={{ base: "1.5rem", md: "3rem", lg: "4rem" }}
                     fontWeight="400"
                     fontFamily="Akkurat-bold"
                     mb={{ base: "-20px", md: "-30px", lg: "-50px" }}
                   >
                     GLOBAL TRADE
                   </Text>
                   <Box mt={{ base: "10px", md: "15px", lg: "20px" }}>
                     <Text
                       fontSize={{ base: "25px", md: "48px", lg: "64px" }}
                       fontWeight="400"
                       fontFamily="Akkurat-bold"
                     >
                       <Text
                         as="span"
                         fontSize={{ base: "18px", md: "32px", lg: "40px" }}
                         fontWeight="400"
                         color="black"
                         mr="10px"
                       >
                         AND
                       </Text>
                       COMMERCE
                     </Text>
                   </Box>
                 </Box>
      </MotionBox>
      <Box p="50px">
        <Text
          fontSize="30px"
          fontWeight="400"
          fontFamily="Akkurat-Bold"
          lineHeight="39.9px"
          mt={8}
          mb={4}
          color="black"
        >
          ON THE AGENDA
        </Text>
        <Text fontSize="22px" fontWeight="400" textAlign="justify" mb={4}>
          To drive growth and development through building deep economic convergence between India, its global Indian Community, global stakeholders and engaging governments, industry, business associations, and civil society for forging economic partnership and extending sustainable solutions to the emerging business and economic needs of the community.
        </Text>
        <Text fontSize="22px" fontWeight="400" textAlign="justify" mb={4}>
          To work towards building an extensive business ecosystem to facilitate growth by improving the general conditions in which businesses operate, to facilitate access to markets within and beyond local boundaries, and to ensure the availability of human, financial and natural resources.
        </Text>
        <Text fontSize="22px" fontWeight="400" textAlign="justify" mb={4}>
          To build physical business and cultural hubs globally which work as global business, economic and cultural hubs for the local members and trade facilitation centers, for global members and the Indian Community. To create a digital platform for seamless integrations of members and businesses worldwide for global business engagements.
        </Text>
      </Box>
    </Box>
  );
};

export default Trade;
