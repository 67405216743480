import React from "react";
import { Box, Text } from "@chakra-ui/react";
import BGImage from "../../assets/BG-2.png";
import { motion } from "framer-motion";

const Innovation = () => {
  const MotionBox = motion(Box);
  return (
    <Box bg="#f6f6f6">
      <MotionBox
        w="100%"
        h={{ base: "400px", md: "500px", lg: "580px" }}
        bgGradient="linear(to-b, blue.800, blue.500)"
        color="white"
        textAlign="center"
        p={{ base: 4, md: 6, lg: 8 }}
        backgroundImage={BGImage}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        backgroundPosition="center"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        <Box mt={{ base: "150px", md: "150px", lg: "200px" }} textAlign={{ base: "center", md: "right" }}>
          <Text
            fontSize={{ base: "32px", md: "48px", lg: "64px" }}
            fontWeight="400"
            fontFamily="Akkurat-bold"
            mb={{ base: "-20px", md: "-30px", lg: "-50px" }}
            pr={{ base: "0", md: "15%", lg: "20%" }}
            textAlign={{ base: "center", md: "right" }}
          >
            INVESTMENTS
          </Text>
        </Box>
      </MotionBox>
      <Box p="50px">
        <Text
          fontSize="30px"
          fontWeight="400"
          fontFamily="Akkurat-Bold"
          lineHeight="39.9px"
          mt={8}
          mb={4}
          color="black"
        >
          ON THE AGENDA
        </Text>
        <Text fontSize="22px" fontWeight="400" textAlign="justify" mb={4}>
          To facilitate investment and developmental needs and to promote India as a new global destination for Investments and global leader among global economies and work closely with government- Centre, and States, to create a robust business ecosystem for attracting global investments as well as technology transfer in strategic areas and to enhance India’s and Indians business footprints globally.
        </Text>
        <Text fontSize="22px" fontWeight="400" textAlign="justify" mb={4}>
          To transform India’s investment climate by advocating on the simplification of the business environment for investors and to handhold investors through their investment lifecycle ⎯ from pre-investment to after-care.
        </Text>
        <Text fontSize="22px" fontWeight="400" textAlign="justify" mb={4}>
          To Develop Opportunity Reports across the select sectors thereby promoting Inbound Investments.
        </Text>
      </Box>
    </Box>
  );
};

export default Innovation;
